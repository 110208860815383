import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';

import { firebaseError, uploadImages, deleteImages } from 'utils';
import firebase from 'firebase.js';
import {
  fetchDocument,
  documentRef,
  fetchCollection,
  normalizeDate,
} from '../api';

export const AUTHORITIES_FETCH_DATA_INIT = createAction(
  'AUTHORITIES_FETCH_DATA_INIT'
);
export const AUTHORITIES_FETCH_DATA_SUCCESS = createAction(
  'AUTHORITIES_FETCH_DATA_SUCCESS'
);
export const AUTHORITIES_FETCH_DATA_FAIL = createAction(
  'AUTHORITIES_FETCH_DATA_FAIL'
);

export const AUTHORITIES_DELETE_AUTHORITY_INIT = createAction(
  'AUTHORITIES_DELETE_AUTHORITY_INIT'
);
export const AUTHORITIES_DELETE_AUTHORITY_SUCCESS = createAction(
  'AUTHORITIES_DELETE_AUTHORITY_SUCCESS'
);
export const AUTHORITIES_DELETE_AUTHORITY_FAIL = createAction(
  'AUTHORITIES_DELETE_AUTHORITY_FAIL'
);

export const AUTHORITIES_CREATE_AUTHORITY_INIT = createAction(
  'AUTHORITIES_CREATE_AUTHORITY_INIT'
);
export const AUTHORITIES_CREATE_AUTHORITY_SUCCESS = createAction(
  'AUTHORITIES_CREATE_AUTHORITY_SUCCESS'
);
export const AUTHORITIES_CREATE_AUTHORITY_FAIL = createAction(
  'AUTHORITIES_CREATE_AUTHORITY_FAIL'
);

export const AUTHORITIES_MODIFY_AUTHORITY_INIT = createAction(
  'AUTHORITIES_MODIFY_AUTHORITY_INIT'
);
export const AUTHORITIES_MODIFY_AUTHORITY_SUCCESS = createAction(
  'AUTHORITIES_MODIFY_AUTHORITY_SUCCESS'
);
export const AUTHORITIES_MODIFY_AUTHORITY_FAIL = createAction(
  'AUTHORITIES_MODIFY_AUTHORITY_FAIL'
);
export const AUTHORITIES_CLEAN_UP = createAction('AUTHORITIES_CLEAN_UP');

export const fetchAuthorities = (authorityId) => async (dispatch, getState) => {
  dispatch(AUTHORITIES_FETCH_DATA_INIT());

  // edit authority
  if (authorityId) {
    let authority;
    try {
      authority = normalizeDate(await fetchDocument('authorities', authorityId), [
        'createdAt',
        'updatedAt',
        'validFrom',
        'validTo',
      ]);
    } catch (error) {
      toastr.error('', error);
      dispatch(AUTHORITIES_FETCH_DATA_FAIL({ error }));
    }
    const authorities = getState().authorities.data.map((doc) =>
      normalizeDate(doc, ['createdAt', 'updatedAt', 'validFrom', 'validTo'])
    );
    const authorityIndex = authorities.findIndex(autho => autho.id === authorityId);
    if (authorities.findIndex(autho => autho.id === authorityId) < 0) {
      authorities.push(authority);
    } else {
      authorities[authorityIndex] = authority;
    }
    dispatch(AUTHORITIES_FETCH_DATA_SUCCESS, { data: authorities });
  }

  // fetch all authorities
  try {
    const authorities = await fetchCollection('authorities');
    dispatch(
    AUTHORITIES_FETCH_DATA_SUCCESS({
      data: authorities.map((doc) =>
        normalizeDate(doc, ['createdAt', 'updatedAt', 'validFrom', 'validTo'])
      ),
    })
  );
  } catch (error) {
    toastr.error('', error);
    dispatch(AUTHORITIES_FETCH_DATA_FAIL({ error }));
  }
};

export const deleteAuthority = (id) => async (dispatch, getState) => {
  dispatch(AUTHORITIES_DELETE_AUTHORITY_INIT());
  const { locale } = getState().preferences;

  try {
    const authority = getState().authorities.data.find((_auth) => _auth.id === id);
    if (!authority) {
      throw new Error('Authority not found');
    }
    await documentRef('authorities', id).delete();
    toastr.success('', 'The authority was deleted.');
    dispatch(AUTHORITIES_DELETE_AUTHORITY_SUCCESS({ id }));
  } catch (error) {
    const errorMessage = firebaseError(error.code, locale);
    toastr.error('', errorMessage);
    dispatch(
      AUTHORITIES_DELETE_AUTHORITY_FAIL({
        error: errorMessage,
      })
    );
  }
};

export const createAuthority =
  ({
    name,
    address,
    logoFile,
    coverFile,
    members,
    link1,
    link2,
    link3,
    workingHours,
    phone,
  }) =>
  async (dispatch, getState) => {
    dispatch(AUTHORITIES_CREATE_AUTHORITY_INIT());

    const { userData } = getState().auth;
    const { locale } = getState().preferences;

    const authorityId = firebase.firestore().collection('authorities').doc().id;

    const batch = firebase.firestore().batch();
    const now = new Date();

    let authorityData = {
      name,
      address,
      phone,
      link1,
      link2,
      link3,
      workingHours,
      members,
      createdAt: now,
      createdBy: userData.id,
    };

    try {
      if (!logoFile) {
        throw Error('Logo is required field.');
      }
      // upload logo
        const [logo] = await uploadImages({
          collection: 'authorities',
          documentId: authorityId,
          files: [logoFile],
        });
        authorityData = { ...authorityData, logo };
      if (!coverFile) {
        throw Error('Cover is required field.');
      }
      // upload logo
      const [cover] = await uploadImages({
        collection: 'authorities',
        documentId: authorityId,
        files: [coverFile],
      });
      authorityData = { ...authorityData, cover };
      batch.set(documentRef('authorities', authorityId), authorityData);
      members.forEach(uid => {
        // assign authority to these users
        batch.set(documentRef('users', uid), { authorityId }, {merge: true});
        // unassign authority from these user
        batch.set(documentRef('users', uid), {authorityId: firebase.firestore.FieldValue.delete()}, {merge: true});
      });
      await batch.commit();
      toastr.success('', 'Authority created successfully');
      dispatch(
        AUTHORITIES_CREATE_AUTHORITY_SUCCESS({
          authority: { ...authorityData, id: authorityId },
        })
      );
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      dispatch(
        AUTHORITIES_CREATE_AUTHORITY_FAIL({
          error: errorMessage,
        })
      );
    }
  };

export const modifyAuthority =
  ({
    name,
    address,
    logoFile,
    coverFile,
    link1,
    link2,
    link3,
    workingHours,
    phone,
    members,
    id,
  }) =>
  async (dispatch, getState) => {
    dispatch(AUTHORITIES_MODIFY_AUTHORITY_INIT());
    const { userData } = getState().auth;
    const { locale } = getState().preferences;
    const authority = getState().authorities.data.find(_auth => _auth.id === id);
    if (!authority) {
      return;
    }

    const batch = firebase.firestore().batch();
    let authorityData = {
      name,
      address,
      phone,
      link1,
      link2,
      link3,
      workingHours,
      members,
      updatedAt: new Date(),
      updatedBy: userData.id,
    };
    try {
      if (logoFile) {
        // delete prev logo
        if (typeof authority.logo === 'string') {
          await deleteImages({
            collection: 'authorities',
            URLs: [authority.logo],
          });
        }
        // upload new logo
        const [logo] = await uploadImages({
          collection: 'authorities',
          documentId: id,
          files: [logoFile],
        });
        authorityData = { ...authorityData, logo };
      }
      if (coverFile) {
        // delete prev cover
        if (typeof authority.cover === 'string') {
          await deleteImages({
            collection: 'authorities',
            URLs: [authority.cover],
          });
        }
        // upload new logo
        const [cover] = await uploadImages({
          collection: 'authorities',
          documentId: id,
          files: [coverFile],
        });
        authorityData = { ...authorityData, cover };
      }
      const prevMembers = authority.members || [];
      // unassign from all the members
      prevMembers.forEach(uid => {
        batch.set(documentRef('users', uid), {authorityId: firebase.firestore.FieldValue.delete()}, {merge: true});
      });
      members.forEach(uid => {
        batch.set(documentRef('users', uid), {authorityId: id}, {merge: true});
      });
      // modify authority
      batch.update(documentRef('authorities', id), authorityData);
      await batch.commit();

      toastr.success('', 'Authority updated successfully');
      const data = await fetchDocument('authorities', id);
      const _authority = normalizeDate(data, [
        'createdAt',
        'updatedAt',
        'validFrom',
        'validTo',
      ]);

      dispatch(AUTHORITIES_MODIFY_AUTHORITY_SUCCESS({ authority: _authority, id }));
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      dispatch(
        AUTHORITIES_MODIFY_AUTHORITY_FAIL({
          error: errorMessage,
        })
      );
    }
  };
