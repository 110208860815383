import { createReducer } from 'redux-act';

import {
  SHOP_POSTS_FETCH_DATA_INIT,
  SHOP_POSTS_FETCH_DATA_SUCCESS,
  SHOP_POSTS_FETCH_DATA_FAIL,
  SHOP_POSTS_DELETE_POST_INIT,
  SHOP_POSTS_DELETE_POST_SUCCESS,
  SHOP_POSTS_DELETE_POST_FAIL,
  SHOP_POSTS_CREATE_POST_INIT,
  SHOP_POSTS_CREATE_POST_SUCCESS,
  SHOP_POSTS_CREATE_POST_FAIL,
  SHOP_POSTS_MODIFY_POST_INIT,
  SHOP_POSTS_MODIFY_POST_SUCCESS,
  SHOP_POSTS_MODIFY_POST_FAIL,
  SHOP_POSTS_CLEAN_UP,
  SHOP_SEARCHING_KEYWORD,
  SHOP_SEARCHING_DATE_PERIOD,
  SHOP_POSTS_DELETE_IMAGE_INIT,
  SHOP_POSTS_DELETE_IMAGE_SUCCESS,
  SHOP_POSTS_DELETE_IMAGE_FAIL,
} from 'state/actions/shoppingPosts';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
  deletingImage: false,
  deletedImage: false,
  keyword: '',
  startDate: null,
  endDate: null,
};

export const shopPostsReducer = createReducer(
  {
    [SHOP_POSTS_CLEAN_UP]: () => initialState,
    [SHOP_POSTS_FETCH_DATA_INIT]: (state) => ({
      ...initialState,
      ...state
    }),
    [SHOP_POSTS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.data,
      loading: false,
      error: null,
    }),
    [SHOP_POSTS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [SHOP_POSTS_DELETE_POST_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [SHOP_POSTS_DELETE_IMAGE_INIT]: (state) => ({
      ...state,
      deletingImage: true,
    }),
    [SHOP_POSTS_DELETE_IMAGE_SUCCESS]: (state) => ({
      ...state,
      deletingImage: false,
    }),
    [SHOP_POSTS_DELETE_IMAGE_FAIL]: (state, payload) => ({
      ...state,
      deletingImage: false,
      error: payload.error,
    }),
    [SHOP_POSTS_DELETE_POST_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.filter((elem) => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [SHOP_POSTS_DELETE_POST_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [SHOP_POSTS_CREATE_POST_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [SHOP_POSTS_CREATE_POST_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.concat(payload.post),
      loading: false,
      error: null,
      success: true,
    }),
    [SHOP_POSTS_CREATE_POST_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [SHOP_POSTS_MODIFY_POST_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [SHOP_POSTS_MODIFY_POST_SUCCESS]: (state, payload) => ({
      ...state,
      data: !state.data
        ? []
        : state.data.map((elem) => {
            if (elem.id === payload.id) {
              return {
                id: payload.id,
                title: payload.post.title,
                description: payload.post.description,
                coverPhoto: payload.post.coverPhoto,
                images: payload.post?.images || [],
                startDate: payload.post.startDate,
                updatedAt: payload.post.updatedAt,
                updatedBy: payload.post.updatedBy,
                period: payload.post.period,
                authorId: payload.post.authorId,
              };
            }
            return elem;
          }),
      deletingImage: false,
      deletedImage: false,
      error: null,
    }),
    [SHOP_POSTS_MODIFY_POST_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [SHOP_SEARCHING_KEYWORD]: (state, payload = '') => ({
      ...state,
      keyword: payload,
    }),
    [SHOP_SEARCHING_DATE_PERIOD]: (state, payload) => ({
      ...state,
      startDate: payload.startDate,
      endDate: payload.endDate,
    }),
  },
  initialState
);
