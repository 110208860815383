import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';
// import 'firebase/app-check'; // try app-check later

const config = {
  apiKey: process.env.REACT_APP_FIRE_BASE_KEY,
  authDomain: process.env.REACT_APP_FIRE_BASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIRE_BASE_DB_URL,
  projectId: process.env.REACT_APP_FIRE_BASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIRE_BASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIRE_BASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIRE_BASE_APP_ID,
  measurementId: process.env.REACT_APP_FIRE_BASE_MEASURMENT_ID,
};

firebase.initializeApp(config);
firebase.firestore();
firebase.storage();
// const appCheck = firebase.appCheck();
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
// appCheck.activate('6LdPS9saAAAAAKj0FKRZhGLHow1pQEMMu7g7Qjvc');

// Use Firebase Emulators
// if (window.location.hostname === 'localhost') {
//   const auth = firebase.auth();
//   auth.useEmulator('http://localhost:9099');
//   const db = firebase.firestore();
//   db.useEmulator('localhost', 8080);
//   const storage = firebase.storage();
//   storage.useEmulator('http://localhost:9199');
//   firebase.functions().useEmulator('localhost', 5001);
// }
export default firebase;
