import { createReducer } from 'redux-act';

import {
  PRODUCTS_FETCH_DATA_INIT,
  PRODUCTS_FETCH_DATA_SUCCESS,
  PRODUCTS_FETCH_DATA_FAIL,
  PRODUCTS_DELETE_PRODUCT_INIT,
  PRODUCTS_DELETE_PRODUCT_SUCCESS,
  PRODUCTS_DELETE_PRODUCT_FAIL,
  PRODUCTS_CREATE_PRODUCT_INIT,
  PRODUCTS_CREATE_PRODUCT_SUCCESS,
  PRODUCTS_CREATE_PRODUCT_FAIL,
  PRODUCTS_MODIFY_PRODUCT_INIT,
  PRODUCTS_MODIFY_PRODUCT_SUCCESS,
  PRODUCTS_MODIFY_PRODUCT_FAIL,
  PRODUCTS_CLEAN_UP
} from 'state/actions/products';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const productsReducer = createReducer(
  {
    [PRODUCTS_CLEAN_UP]: () => initialState,
    [PRODUCTS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [PRODUCTS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.data.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }),
      loading: false,
      error: null,
    }),
    [PRODUCTS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [PRODUCTS_DELETE_PRODUCT_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [PRODUCTS_DELETE_PRODUCT_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.filter((elem) => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [PRODUCTS_DELETE_PRODUCT_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [PRODUCTS_CREATE_PRODUCT_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [PRODUCTS_CREATE_PRODUCT_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.concat(payload.product),
      loading: false,
      error: null,
      success: true,
    }),
    [PRODUCTS_CREATE_PRODUCT_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [PRODUCTS_MODIFY_PRODUCT_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [PRODUCTS_MODIFY_PRODUCT_SUCCESS]: (state, payload) => ({
      ...state,
      data: !state.data
        ? []
        : state.data.map((elem) => {
            if (elem.id === payload.id) {
              return {
                id: payload.id,
                name: payload.product.name,
                address: payload.product.address,
                description: payload.product.description,
                productLogoUrl: payload.product.productLogoUrl,
                shoppingLogoUrl: payload.product.shoppingLogoUrl,
                validFrom: payload.product.validFrom,
                validTo: payload.product.validTo,
                createdAt: payload.product.createdAt,
                createdBy: payload.product.createdBy,
                updatedAt: payload.product.updatedAt,
                updatedBy: payload.product.updatedBy,
                activated: payload.product.activated,
              };
            }
            return elem;
          }),
      loading: false,
      error: null,
      success: true,
    }),
    [PRODUCTS_MODIFY_PRODUCT_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
  },
  initialState
);
