/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import ClipLoader from 'react-spinners/ClipLoader';
import classNames from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup';
import bulmaCalendar from 'bulma-calendar';
import paths from 'pages/Router/paths';
import { useFormatMessage } from 'hooks';
import './CompanyForm.scss';

const CompanyForm = ({
  company,
  loadingProduct,
  products = [],
  isEditing,
  onSubmitHandler,
  schema,
}) => {
  const { loading, locale } = useSelector(
    (state) => ({
      loading: state.companies.loading,
      success: state.companies.success,
      locale: state.preferences.locale,
    }),
    shallowEqual
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: { ...company },
    resolver: yupResolver(schema),
  });

  const goBackMessage = useFormatMessage('Companies.goBack');
  const pickAnotherFileMessage = useFormatMessage('UserForm.pickAnotherFile');
  const companyId = watch('id');
  const logoUrl =
    watch('logoFile') && watch('logoFile')[0]
      ? URL.createObjectURL(watch('logoFile')[0])
      : company.logo;
  const strValidFrom = watch('contractRanges').validFrom
    ? moment(watch('contractRanges').validFrom).format('ll')
    : '';
  const strValidTo = watch('contractRanges').validTo
    ? moment(watch('contractRanges').validTo).format('ll')
    : '';
  const contractRanges = `${strValidFrom} - ${strValidTo}`;

  useEffect(() => {
    bulmaCalendar.attach('[type="date"]', {
      type: 'date',
      lang: locale,
      isRange: true,
      allowSameDayRange: false,
      displayMode: 'default',
      showTodayButton: true,
      labelFrom: 'From',
      labelTo: 'To',
      startDate: company.contractRanges.validFrom,
      endDate: company.contractRanges.validTo,
    });
  }, [locale, company.contractRanges]);

  const element = document.querySelector('#contractRanges');
  if (element) {
    element.bulmaCalendar.on('select', (datepicker) => {
      const [validFrom, validTo] = datepicker.data
        .value()
        .split('-')
        .map((date) => new Date(date.trim()));
      setValue('contractRanges', { validFrom, validTo });
    });
    element.bulmaCalendar.on('clear', () => {
      setValue('contractRanges', { validFrom: undefined, validTo: undefined });
    });
  }

  /* eslint-disable */
  return (
    <>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <div className="tile is-ancestor">
          <div className="tile is-parent">
            <div className="card tile is-child">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-badge-account-horizontal default" />
                  </span>
                  {useFormatMessage('Companies.info')}
                </p>
              </header>
              <div className="card-content">
                {/* name */}
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('Companies.name')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          {...register('name')}
                          id="name"
                          className={classNames('input', {
                            'is-danger': errors.name,
                          })}
                          type="text"
                          placeholder={useFormatMessage('Companies.name')}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* displayName */}
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('Companies.displayName')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          {...register('displayName')}
                          id="displayName"
                          className={classNames('input', {
                            'is-danger': errors.contact,
                          })}
                          type="text"
                          placeholder={useFormatMessage(
                            'Companies.displayName'
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* logo */}
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ProductForm.logo')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <div className="is-horizontal">
                          <input
                            className="input"
                            type="url"
                            readOnly="readOnly"
                            value={
                              (watch('logoFile') &&
                                watch('logoFile')[0]?.name) ||
                              company.logo
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="file">
                      <label className="file-label">
                        <input
                          className="file-input"
                          type="file"
                          {...register('logoFile')}
                        />
                        <span className="file-cta">
                          <span className="file-icon">
                            <i className="mdi mdi-upload" />
                          </span>
                          <span className="file-label">
                            {watch('logoFile') && watch('logoFile')[0]
                              ? pickAnotherFileMessage
                              : useFormatMessage('UserForm.pickFile')}
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>

                {/* <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('Companies.contract')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          id="contractRanges"
                          type="date"
                          {...register('contractRanges')}
                        />
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* contact */}
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('Companies.contact')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          {...register('contact')}
                          id="contact"
                          className={classNames('input', {
                            'is-danger': errors.contact,
                          })}
                          type="text"
                          placeholder={useFormatMessage('Companies.contact')}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* email */}
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('Companies.email')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          {...register('email')}
                          id="email"
                          className={classNames('input', {
                            'is-danger': errors.email,
                          })}
                          type="email"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* address */}
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('Companies.address')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          {...register('address')}
                          id="address"
                          className={classNames('input', {
                            'is-danger': errors.address,
                          })}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('Companies.products')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="select is-multiple is-expaned">
                      <Select
                        name="products"
                        label={useFormatMessage('Companies.products')}
                        multiple
                        loading={loadingProduct}
                        register={register}
                        options={products}
                      />
                    </div>
                  </div>
                </div> */}

                <hr />

                <div className="field is-horizontal">
                  <div className="field-label" />
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        <div className="control">
                          <button
                            type="submit"
                            className={`button is-primary ${
                              loading && 'is-loading'
                            }`}
                          >
                            <span>{useFormatMessage('Companies.submit')}</span>
                          </button>
                        </div>
                        <Link to={paths.COMPANIES} className="button">
                          {goBackMessage}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Preview */}
          <div className="tile is-parent preview">
            <div className="card tile is-child">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-badge-account-horizontal default" />
                  </span>
                  {useFormatMessage('Companies.preview')}
                </p>
              </header>
              <div className="card-content">
                {isEditing && (
                  <div className="field">
                    <label className="label">
                      {useFormatMessage('Companies.id')}
                    </label>
                    <div className="control">
                      <input
                        readOnly="readOnly"
                        className="input is-static"
                        data-testid="id"
                        value={watch('id')}
                      />
                    </div>
                  </div>
                )}
                <div className="field">
                  <label className="label">
                    {useFormatMessage('Companies.name')}
                  </label>
                  <div className="control">
                    <input
                      readOnly="readOnly"
                      className="input is-static"
                      data-testid="name"
                      value={watch('name')}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label">
                    {useFormatMessage('Companies.displayName')}
                  </label>
                  <div className="control">
                    <input
                      readOnly="readOnly"
                      className="input is-static"
                      data-testid="displayName"
                      value={watch('displayName')}
                    />
                  </div>
                </div>
                {logoUrl && (
                  <>
                    <div className="field">
                      <label className="label">
                        {useFormatMessage('ProductForm.logo')}
                      </label>
                      <div className="image is-64x64 has-max-width is-aligned-center">
                        <img src={logoUrl} alt="company logo" />
                      </div>
                    </div>
                  </>
                )}
                <div className="field">
                  <label className="label">
                    {useFormatMessage('Companies.contract')}
                  </label>
                  <div className="control">
                    <input
                      readOnly="readOnly"
                      className="input is-static"
                      data-testid="contract"
                      value={contractRanges}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label">
                    {useFormatMessage('Companies.contact')}
                  </label>
                  <div className="control">
                    <input
                      readOnly="readOnly"
                      className="input is-static"
                      data-testid="contact"
                      value={watch('contact')}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label">
                    {useFormatMessage('Companies.email')}
                  </label>
                  <div className="control">
                    <input
                      readOnly="readOnly"
                      className="input is-static"
                      data-testid="email"
                      value={watch('email')}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label">
                    {useFormatMessage('Companies.address')}
                  </label>
                  <div className="control">
                    <input
                      readOnly="readOnly"
                      className="input is-static"
                      data-testid="address"
                      value={watch('address')}
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label">
                    {useFormatMessage('Companies.products')}
                  </label>
                  <div className="field">
                    <div className="tags">
                      {products
                        .filter(
                          (product) =>
                            product.companyId === companyId &&
                            typeof companyId === 'string'
                        )
                        .map((product) => (
                          <span key={product.id} className="tag">
                            <Link
                              target="_blank"
                              rel="noreferrer noopener"
                              to={`/companies/${companyId}/products/${product.id}`}
                            >
                              {product.name}
                            </Link>
                          </span>
                        ))}
                      {loadingProduct && <ClipLoader />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

CompanyForm.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    contact: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    products: PropTypes.arrayOf(PropTypes.string.isRequired),
  }).isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  schema: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
};

CompanyForm.defaultProps = {
  isEditing: false,
};

export default CompanyForm;
