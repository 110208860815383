import React, { useMemo } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';

import { useFormatMessage } from 'hooks';
import PostForm from 'components/PostForm';

const ShoppingWindowPost = () => {
  const { id, companyId } = useParams();

  const isEditing = useMemo(() => !!id, [id]);

  const { success, selectedPost, deleted, loading } = useSelector(
    (state) => ({
      success: state.shopPosts.success,
      loading: state.shopPosts.loading,
      deleted: state.shopPosts.deleted,
      selectedPost: state.shopPosts.data.find((post) => post.id === id),
    }),
    shallowEqual
  );
  const redirect = (success || !selectedPost || deleted) && (
    <Redirect to={`/shoppingwindow/${companyId}`} />
  );

  return (
    <>
      {redirect}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">
            {useFormatMessage('ShoppingWindow.postDetails')}
          </h1>
        </div>
      </section>
      <section className="section is-main-section">
        {isEditing && !selectedPost ? (
          <ClipLoader />
        ) : (
          <PostForm post={selectedPost} loading={loading} />
        )}
      </section>
    </>
  );
};

export default ShoppingWindowPost;
