import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';

import { actionMessage, firebaseError, FIREBASE_RESPONSE } from 'utils';
import firebase from 'firebase.js';
import CustomError from 'utils/CustomError';
import { clearUsersDataLogout } from './users';
import { createDocument, fetchDocument } from '../api';
import { PRODUCTS_CLEAN_UP } from './products';
import { CATEGORIES_CLEAN_UP } from './categories';
import { COMPANIES_CLEAN_UP } from './companies';

export const AUTH_SIGN_IN_INIT = createAction('AUTH_SIGN_IN_INIT');
export const AUTH_SIGN_IN_FAIL = createAction('AUTH_SIGN_IN_FAIL');

export const AUTH_RESTORE_SESSION_INIT = createAction(
  'AUTH_RESTORE_SESSION_INIT'
);
export const AUTH_RESTORE_SESSION_SUCCESS = createAction(
  'AUTH_RESTORE_SESSION_SUCCESS'
);
export const AUTH_RESTORE_SESSION_FAIL = createAction(
  'AUTH_RESTORE_SESSION_FAIL'
);

export const AUTH_LOGOUT_INIT = createAction('AUTH_LOGOUT_INIT');
export const AUTH_LOGOUT_SUCCESS = createAction('AUTH_LOGOUT_SUCCESS');

export const AUTH_SET_PASSWORD_INIT = createAction('AUTH_SET_PASSWORD_INIT');
export const AUTH_SET_PASSWORD_SUCCESS = createAction(
  'AUTH_SET_PASSWORD_SUCCESS'
);
export const AUTH_SET_PASSWORD_FAIL = createAction('AUTH_SET_PASSWORD_FAIL');

export const AUTH_RESET_PASSWORD_INIT = createAction(
  'AUTH_RESET_PASSWORD_INIT'
);
export const AUTH_RESET_PASSWORD_SUCCESS = createAction(
  'AUTH_RESET_PASSWORD_SUCCESS'
);
export const AUTH_RESET_PASSWORD_FAIL = createAction(
  'AUTH_RESET_PASSWORD_FAIL'
);

export const AUTH_CLEAN_UP = createAction('AUTH_CLEAN_UP');

export const AUTH_FETCH_USER_DATA_INIT = createAction(
  'AUTH_FETCH_USER_DATA_INIT'
);
export const AUTH_FETCH_USER_DATA_SUCCESS = createAction(
  'AUTH_FETCH_USER_DATA_SUCCESS'
);
export const AUTH_FETCH_USER_DATA_FAIL = createAction(
  'AUTH_FETCH_USER_DATA_FAIL'
);

export const AUTH_CHANGE_PASSWORD_INIT = createAction(
  'AUTH_CHANGE_PASSWORD_INIT'
);
export const AUTH_CHANGE_PASSWORD_SUCCESS = createAction(
  'AUTH_CHANGE_PASSWORD_SUCCESS'
);
export const AUTH_CHANGE_PASSWORD_FAIL = createAction(
  'AUTH_CHANGE_PASSWORD_FAIL'
);

export const AUTH_UPDATE_USER_DATA = createAction('AUTH_UPDATE_USER_DATA');

export const AUTH_PROVIDER_INIT = createAction('AUTH_PROVIDER_INIT');

export const AUTH_PROVIDER_SUCCESS = createAction('AUTH_PROVIDER_SUCCESS');

export const AUTH_PROVIDER_FAIL = createAction('AUTH_PROVIDER_FAIL');

export const logout = () => async (dispatch) => {
  dispatch(COMPANIES_CLEAN_UP());
  dispatch(CATEGORIES_CLEAN_UP());
  dispatch(PRODUCTS_CLEAN_UP());
  dispatch(AUTH_LOGOUT_INIT());
  dispatch(clearUsersDataLogout());
  await firebase.auth().signOut();
  dispatch(AUTH_LOGOUT_SUCCESS());
};

export const verifyAuth = () => (dispatch) => {
  firebase.auth().onAuthStateChanged((user) => {
    dispatch(AUTH_RESTORE_SESSION_INIT());

    if (user !== null) {
      return dispatch(AUTH_RESTORE_SESSION_SUCCESS());
    }

    dispatch(AUTH_RESTORE_SESSION_FAIL());
    return dispatch(logout());
  });
};

export const fetchUserData = () => async (dispatch, getState) => {
  dispatch(AUTH_FETCH_USER_DATA_INIT());

  const { uid } = firebase.auth().currentUser;
  const { locale } = getState().preferences;

  let user;

  try {
    user = await fetchDocument('users', uid);
  } catch (error) {
    await firebase.auth().signOut();
    return dispatch(AUTH_FETCH_USER_DATA_FAIL({ error }));
  }

  if (!user) {
    const errorMessage = firebaseError(
      FIREBASE_RESPONSE.EMAIL_NOT_FOUND,
      locale
    );
    await firebase.auth().signOut();
    return dispatch(AUTH_SIGN_IN_FAIL({ error: errorMessage }));
  }
  // if (!user.role === 'admin') {
  //   const errorMessage = firebaseError(FIREBASE_RESPONSE.INVALID_ADMIN, locale);
  //   await firebase.auth().signOut();
  //   return dispatch(AUTH_SIGN_IN_FAIL({ error: errorMessage }));
  // }

  return dispatch(
    AUTH_FETCH_USER_DATA_SUCCESS({
      id: uid,
      ...user,
    })
  );
};

export const checkUserData = () => (dispatch, getState) => {
  const { id } = getState().auth.userData;

  if (!id) {
    dispatch(fetchUserData());
  }
};

export const auth = (email, password) => async (dispatch, getState) => {
  dispatch(AUTH_SIGN_IN_INIT());
  const { locale } = getState().preferences;
  try {
    const result = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password);
    const {claims} = await result.user.getIdTokenResult();
    if (claims.role !== 'admin') {
      throw new CustomError('auth/invalid-admin');
    }
    if (
      !result.user.emailVerified &&
      window.location.hostname !== 'localhost'
    ) {
      throw new Error('auth/user-disabled');
    }
    dispatch(fetchUserData());
  } catch (error) {
    const errorMessage = firebaseError(error.code, locale);
    dispatch(AUTH_SIGN_IN_FAIL({ error: errorMessage }));
  }
};

export const setPassword =
  (email, password, url) => async (dispatch, getState) => {
    dispatch(AUTH_SET_PASSWORD_INIT());
    const { locale } = getState().preferences;

    try {
      await firebase.auth().signInWithEmailLink(email, url);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      return dispatch(AUTH_SET_PASSWORD_FAIL({ error: errorMessage }));
    }

    const user = firebase.auth().currentUser;

    try {
      await user.updatePassword(password);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      return dispatch(AUTH_SET_PASSWORD_FAIL({ error: errorMessage }));
    }

    const successMessage = actionMessage('Login.passwordSetOk', locale);
    toastr.success('', successMessage);
    return dispatch(AUTH_SET_PASSWORD_SUCCESS());
  };

export const resetPassword = (email) => async (dispatch, getState) => {
  dispatch(AUTH_RESET_PASSWORD_INIT());
  const { locale } = getState().preferences;

  try {
    await firebase.auth().sendPasswordResetEmail(email);
  } catch (error) {
    const errorMessage = firebaseError(error.code, locale);
    return dispatch(AUTH_RESET_PASSWORD_FAIL({ error: errorMessage }));
  }

  return dispatch(AUTH_RESET_PASSWORD_SUCCESS());
};

export const authCleanUp = () => (dispatch) => dispatch(AUTH_CLEAN_UP());

export const changeUserPassword =
  (currentPassword, newPassword) => async (dispatch, getState) => {
    dispatch(AUTH_CHANGE_PASSWORD_INIT());
    const { locale } = getState().preferences;

    const user = firebase.auth().currentUser;

    const { email } = user;

    const credential = firebase.auth.EmailAuthProvider.credential(
      email,
      currentPassword
    );

    try {
      await user.reauthenticateWithCredential(credential);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(AUTH_CHANGE_PASSWORD_FAIL({ error: errorMessage }));
    }

    try {
      await user.updatePassword(newPassword);
    } catch (error) {
      const errorMessage = firebaseError(error, locale);
      toastr.error('', errorMessage);
      return dispatch(AUTH_CHANGE_PASSWORD_FAIL({ error: errorMessage }));
    }

    toastr.success('', 'Password changed successfully');
    return dispatch(AUTH_CHANGE_PASSWORD_SUCCESS());
  };

export const authWithSocialMedia =
  (authResult) => async (dispatch, getState) => {
    dispatch(AUTH_PROVIDER_INIT());
    const { locale } = getState().preferences;
    const { user, additionalUserInfo } = authResult;
    const { isNewUser, profile } = additionalUserInfo;
    const { uid, photoURL, email, displayName } = user;

    const { location } = profile;

    const userData = {
      isAdmin: false,
      email,
      name: displayName,
      createdAt: new Date().toString(),
      logoUrl: photoURL,
      location: location?.name || null,
    };

    let userFromDb = {};
    if (isNewUser) {
      try {
        await createDocument('users', uid, userData);
      } catch (e) {
        const errorMessage = firebaseError(e.code, locale);
        return dispatch(AUTH_PROVIDER_FAIL({ e: errorMessage }));
      }
    } else {
      try {
        userFromDb = await fetchDocument('users', uid);
      } catch (error) {
        const errorMessage = firebaseError(error.code, locale);
        return dispatch(AUTH_PROVIDER_FAIL({ error: errorMessage }));
      }
    }

    return dispatch(
      AUTH_PROVIDER_SUCCESS({ id: uid, ...userData, ...userFromDb })
    );
  };
