import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';

import { firebaseError } from 'utils';
import firebase from 'firebase.js';
import { fetchCollection, fetchDocument, normalizeDate } from '../api';

export const AUTHORITY_POSTS_FETCH_DATA_INIT = createAction(
  'AUTHORITY_POSTS_FETCH_DATA_INIT'
);
export const AUTHORITY_POSTS_FETCH_DATA_SUCCESS = createAction(
  'AUTHORITY_POSTS_FETCH_DATA_SUCCESS'
);
export const AUTHORITY_POSTS_FETCH_DATA_FAIL = createAction(
  'AUTHORITY_POSTS_FETCH_DATA_FAIL'
);

export const AUTHORITY_POSTS_DELETE_POST_INIT = createAction(
  'AUTHORITY_POSTS_DELETE_POST_INIT'
);
export const AUTHORITY_POSTS_DELETE_IMAGE_INIT = createAction(
  'AUTHORITY_POSTS_DELETE_IMAGE_INIT'
);
export const AUTHORITY_POSTS_DELETE_IMAGE_SUCCESS = createAction(
  'AUTHORITY_POSTS_DELETE_IMAGE_SUCCESS'
);
export const AUTHORITY_POSTS_DELETE_IMAGE_FAIL = createAction(
  'AUTHORITY_POSTS_DELETE_IMAGE_FAIL'
);
export const AUTHORITY_POSTS_DELETE_POST_SUCCESS = createAction(
  'AUTHORITY_POSTS_DELETE_POST_SUCCESS'
);
export const AUTHORITY_POSTS_DELETE_POST_FAIL = createAction(
  'AUTHORITY_POSTS_DELETE_POST_FAIL'
);

export const AUTHORITY_POSTS_CREATE_POST_INIT = createAction(
  'AUTHORITY_POSTS_CREATE_POST_INIT'
);
export const AUTHORITY_POSTS_CREATE_POST_SUCCESS = createAction(
  'AUTHORITY_POSTS_CREATE_POST_SUCCESS'
);
export const AUTHORITY_POSTS_CREATE_POST_FAIL = createAction(
  'AUTHORITY_POSTS_CREATE_POST_FAIL'
);

export const AUTHORITY_POSTS_MODIFY_POST_INIT = createAction(
  'AUTHORITY_POSTS_MODIFY_POST_INIT'
);
export const AUTHORITY_POSTS_MODIFY_POST_SUCCESS = createAction(
  'AUTHORITY_POSTS_MODIFY_POST_SUCCESS'
);
export const AUTHORITY_POSTS_MODIFY_POST_FAIL = createAction(
  'AUTHORITY_POSTS_MODIFY_POST_FAIL'
);
export const AUTHORITY_POSTS_DELETE_POST_IMAGES = createAction(
  'AUTHORITY_POSTS_DELETE_POST_IMAGES'
);
export const AUTHORITY_SEARCHING_KEYWORD = createAction('AUTHORITY_SEARCHING_KEYWORD');
export const AUTHORITY_SEARCHING_DATE_PERIOD = createAction(
  'AUTHORITY_SEARCHING_DATE_PERIOD'
);
export const AUTHORITY_POSTS_CLEAN_UP = createAction('AUTHORITY_POSTS_CLEAN_UP');
export const cancelDeleteImage = () => (dispatch) =>
  dispatch(AUTHORITY_POSTS_DELETE_IMAGE_SUCCESS());
export const deleteImage =
  (postId, image, elementIndex = -1) =>
  async (dispatch, getState) => {
    dispatch(AUTHORITY_POSTS_DELETE_IMAGE_INIT());
    try {
      const tasks = [
        firebase
          .storage()
          .ref(
            `authority_posts/${postId}/${image}`
          )
          .delete()
          .catch(() => {}),
      ];
      if (elementIndex < 0) {
        tasks.push(
          firebase
            .firestore()
            .doc(`authorityPosts/${postId}`)
            .set({ coverPhoto: '' }, { merge: true })
        );
      } else {
        const images =
          getState()
            .authorityPosts.data.filter((mPost) => mPost.id === postId)
            .pop()?.images || [];
        images[elementIndex] = '';
        tasks.push(
          firebase
            .firestore()
            .doc(`authorityPosts/${postId}`)
            .set({ images }, { merge: true })
        );
      }
      await Promise.all(tasks);
      const data = await fetchDocument('authorityPosts', postId);
      const post = normalizeDate(data, ['createdAt', 'updatedAt', 'push_notification_at', 'publish_at']);
      dispatch(AUTHORITY_POSTS_MODIFY_POST_SUCCESS({ post, id: postId }));
    } catch (error) {
      const { locale } = getState().preferences;
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      dispatch(AUTHORITY_POSTS_DELETE_IMAGE_FAIL());
    }
  };
export const fetchPostsByAuthorityId =
  (authorityId) => async (dispatch, getState) => {
    dispatch(AUTHORITY_POSTS_FETCH_DATA_INIT());
    // fetch all posts
    let posts;
    try {
      posts = await fetchCollection('authorityPosts', {
        queries: [
          {
            attribute: 'authorityId',
            operator: '==',
            value: authorityId,
          },
        ],
        sort: {
          attribute: 'createdAt',
          order: 'desc',
        },
      });
    } catch (error) {
      const { locale } = getState().preferences;
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(AUTHORITY_POSTS_FETCH_DATA_FAIL({ error }));
    }

    return dispatch(
      AUTHORITY_POSTS_FETCH_DATA_SUCCESS({
        data: posts.map((doc) =>
          normalizeDate(doc, ['createdAt', 'updatedAt', 'push_notification_at', 'publish_at'])
        ),
      })
    );
  };

export const deletePost = (id) => async (dispatch, getState) => {
  dispatch(AUTHORITY_POSTS_DELETE_POST_INIT());
  try {
    await firebase
      .storage()
      .ref(`authority_posts/${id}`)
      .delete()
      .catch(() => {});
    await firebase.firestore().doc(`authorityPosts/${id}`).delete();
  } catch (error) {
    const { locale } = getState().preferences;
    const errorMessage = firebaseError(error.code, locale);
    toastr.error('', errorMessage);
    return dispatch(
      AUTHORITY_POSTS_DELETE_POST_FAIL({
        error: errorMessage,
      })
    );
  }

  toastr.success('', 'The post was deleted.');
  return dispatch(AUTHORITY_POSTS_DELETE_POST_SUCCESS({ id }));
};

export const deletePostImages =
  ({ id }) =>
  async (dispatch, getState) => {
    dispatch(AUTHORITY_POSTS_MODIFY_POST_INIT());
    const { userData } = getState().auth;
    const { locale } = getState().preferences;

    const postData = {
      updatedAt: new Date(),
      updatedBy: userData.id,
    };
    try {
      // modify post
      // should delete images file too
      firebase.firestore().doc(`authorityPosts/${id}`).set({}, { merge: true });
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        AUTHORITY_POSTS_MODIFY_POST_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Authority post updated successfully');

    return dispatch(AUTHORITY_POSTS_MODIFY_POST_SUCCESS({ post: postData, id }));
  };

export const searchKeyword = (keyword) => (dispatch) =>
  dispatch(AUTHORITY_SEARCHING_KEYWORD(keyword));
export const searchPeriod = (startDate, endDate) => (dispatch) =>
  dispatch(AUTHORITY_SEARCHING_DATE_PERIOD({ startDate, endDate }));
