import firebase from 'firebase.js';

const getFirestoreRef = (path) => firebase.firestore().collection(path);

export const fetchDocument = async (collection, id) => {
  const document = await getFirestoreRef(collection).doc(id).get();
  if (!document.exists) {
    return null;
  }

  const data = { id: document.id, ...document.data() };
  return data;
};

export const fetchCollection = async (collection, options = {}) => {
  const data = [];
  let baseQuery = getFirestoreRef(collection);

  if (options.queries) {
    const { queries } = options;
    queries.forEach(({ attribute, operator, value }) => {
      baseQuery = baseQuery.where(attribute, operator, value);
    });
  }

  if (options.sort) {
    const { attribute, order } = options.sort;
    baseQuery = baseQuery.orderBy(attribute, order);
  }
  (await baseQuery.get()).forEach((doc) =>
    data.push({ id: doc.id, ...doc.data() })
  );

  return data;
};

export const documentRef = (collection, id) =>
  getFirestoreRef(collection).doc(id);

export const deleteDocument = (collection, id) =>
  documentRef(collection, id).delete();

export const createDocument = (collection, id, values) =>
  documentRef(collection, id).set(values);

export const updateDocument = (collection, id, values) =>
  documentRef(collection, id).update(values);

export const batch = () => firebase.firestore().batch();

// convert Timestamp to Date
export const normalizeDate = (doc, fields = []) => {
  const obj = { ...doc };
  fields.forEach((field) => {
    if (typeof obj[field] !== 'undefined' && obj[field]) {
      obj[field] = doc[field].toDate();
    }
  });
  return obj;
};
