import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';

import { firebaseError } from 'utils';
import firebase from 'firebase.js';
import { fetchCollection, fetchDocument, normalizeDate } from '../api';

export const SHOP_POSTS_FETCH_DATA_INIT = createAction(
  'SHOP_POSTS_FETCH_DATA_INIT'
);
export const SHOP_POSTS_FETCH_DATA_SUCCESS = createAction(
  'SHOP_POSTS_FETCH_DATA_SUCCESS'
);
export const SHOP_POSTS_FETCH_DATA_FAIL = createAction(
  'SHOP_POSTS_FETCH_DATA_FAIL'
);

export const SHOP_POSTS_DELETE_POST_INIT = createAction(
  'SHOP_POSTS_DELETE_POST_INIT'
);
export const SHOP_POSTS_DELETE_IMAGE_INIT = createAction(
  'SHOP_POSTS_DELETE_IMAGE_INIT'
);
export const SHOP_POSTS_DELETE_IMAGE_SUCCESS = createAction(
  'SHOP_POSTS_DELETE_IMAGE_SUCCESS'
);
export const SHOP_POSTS_DELETE_IMAGE_FAIL = createAction(
  'SHOP_POSTS_DELETE_IMAGE_FAIL'
);
export const SHOP_POSTS_DELETE_POST_SUCCESS = createAction(
  'SHOP_POSTS_DELETE_POST_SUCCESS'
);
export const SHOP_POSTS_DELETE_POST_FAIL = createAction(
  'SHOP_POSTS_DELETE_POST_FAIL'
);

export const SHOP_POSTS_CREATE_POST_INIT = createAction(
  'SHOP_POSTS_CREATE_POST_INIT'
);
export const SHOP_POSTS_CREATE_POST_SUCCESS = createAction(
  'SHOP_POSTS_CREATE_POST_SUCCESS'
);
export const SHOP_POSTS_CREATE_POST_FAIL = createAction(
  'SHOP_POSTS_CREATE_POST_FAIL'
);

export const SHOP_POSTS_MODIFY_POST_INIT = createAction(
  'SHOP_POSTS_MODIFY_POST_INIT'
);
export const SHOP_POSTS_MODIFY_POST_SUCCESS = createAction(
  'SHOP_POSTS_MODIFY_POST_SUCCESS'
);
export const SHOP_POSTS_MODIFY_POST_FAIL = createAction(
  'SHOP_POSTS_MODIFY_POST_FAIL'
);
export const SHOP_POSTS_DELETE_POST_IMAGES = createAction(
  'SHOP_POSTS_DELETE_POST_IMAGES'
);
export const SHOP_SEARCHING_KEYWORD = createAction('SHOP_SEARCHING_KEYWORD');
export const SHOP_SEARCHING_DATE_PERIOD = createAction(
  'SHOP_SEARCHING_DATE_PERIOD'
);
export const SHOP_POSTS_CLEAN_UP = createAction('SHOP_POSTS_CLEAN_UP');
export const cancelDeleteImage = () => (dispatch) =>
  dispatch(SHOP_POSTS_DELETE_IMAGE_SUCCESS());
export const deleteImage =
  (postId, image, elementIndex = -1) =>
  async (dispatch, getState) => {
    dispatch(SHOP_POSTS_DELETE_IMAGE_INIT());
    try {
      const tasks = [
        firebase
          .storage()
          .ref(
            `shop_posts/${postId}/${
              elementIndex < 0 ? 'cover' : 'images'
            }/${image}`
          )
          .delete()
          .catch(() => {}),
      ];
      if (elementIndex < 0) {
        tasks.push(
          firebase
            .firestore()
            .doc(`shopPosts/${postId}`)
            .set({ coverPhoto: '' }, { merge: true })
        );
      } else {
        const images =
          getState()
            .shopPosts.data.filter((mPost) => mPost.id === postId)
            .pop()?.images || [];
        images[elementIndex] = '';
        tasks.push(
          firebase
            .firestore()
            .doc(`shopPosts/${postId}`)
            .set({ images }, { merge: true })
        );
      }
      await Promise.all(tasks);
      const data = await fetchDocument('shopPosts', postId);
      const post = normalizeDate(data, ['createdAt', 'updatedAt', 'startDate']);
      dispatch(SHOP_POSTS_MODIFY_POST_SUCCESS({ post, id: postId }));
    } catch (error) {
      const { locale } = getState().preferences;
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      dispatch(SHOP_POSTS_DELETE_IMAGE_FAIL());
    }
  };
export const fetchPostsByCompanyId =
  (companyId) => async (dispatch, getState) => {
    dispatch(SHOP_POSTS_FETCH_DATA_INIT());
    // fetch all posts
    let posts;
    try {
      posts = await fetchCollection('shopPosts', {
        queries: [
          {
            attribute: 'companyId',
            operator: '==',
            value: companyId,
          },
        ],
        sort: {
          attribute: 'createdAt',
          order: 'desc',
        },
      });
    } catch (error) {
      const { locale } = getState().preferences;
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(SHOP_POSTS_FETCH_DATA_FAIL({ error }));
    }

    return dispatch(
      SHOP_POSTS_FETCH_DATA_SUCCESS({
        data: posts.map((doc) =>
          normalizeDate(doc, ['createdAt', 'updatedAt', 'startDate'])
        ),
      })
    );
  };

export const deletePost = (id) => async (dispatch, getState) => {
  dispatch(SHOP_POSTS_DELETE_POST_INIT());
  try {
    await firebase
      .storage()
      .ref(`shop_posts/${id}`)
      .delete()
      .catch(() => {});
    await firebase.firestore().doc(`shopPosts/${id}`).delete();
  } catch (error) {
    const { locale } = getState().preferences;
    const errorMessage = firebaseError(error.code, locale);
    toastr.error('', errorMessage);
    return dispatch(
      SHOP_POSTS_DELETE_POST_FAIL({
        error: errorMessage,
      })
    );
  }

  toastr.success('', 'The post was deleted.');
  return dispatch(SHOP_POSTS_DELETE_POST_SUCCESS({ id }));
};

export const deletePostImages =
  ({ id }) =>
  async (dispatch, getState) => {
    dispatch(SHOP_POSTS_MODIFY_POST_INIT());
    const { userData } = getState().auth;
    const { locale } = getState().preferences;

    const postData = {
      updatedAt: new Date(),
      updatedBy: userData.id,
    };
    try {
      // modify post
      // should delete images file too
      firebase.firestore().doc(`shopPosts/${id}`).set({}, { merge: true });
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        SHOP_POSTS_MODIFY_POST_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Company updated successfully');

    return dispatch(SHOP_POSTS_MODIFY_POST_SUCCESS({ post: postData, id }));
  };

export const searchKeyword = (keyword) => (dispatch) =>
  dispatch(SHOP_SEARCHING_KEYWORD(keyword));
export const searchPeriod = (startDate, endDate) => (dispatch) =>
  dispatch(SHOP_SEARCHING_DATE_PERIOD({ startDate, endDate }));
