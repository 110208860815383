import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

import Login from 'pages/Login';
import Home from 'pages/Home';
import Users from 'pages/Users';

import Companies from 'pages/Companies';
import Company from 'pages/Company';
import ShoppingWindow from 'pages/ShoppingWindow';
import ShoppingWindowPost from 'pages/ShoppingWindowPost';

import Products from 'pages/Products';
import Product from 'pages/Product';

import Categories from 'pages/Categories';
import Category from 'pages/Category';

import Campaigns from 'pages/Campaigns';

import Profile from 'pages/Profile';
import ResetPassword from 'pages/ResetPassword';
import NotFound from 'pages/NotFound';
import User from 'pages/User';
import UserResetPassword from 'pages/UserResetPassword';
import Campaign from 'pages/Campaign';
import Authorities from 'pages/Authorities';
import Authority from 'pages/Authority';
import AuthorityPosts from 'pages/AuthorityPosts';
import Messaging from 'pages/Messaging';
import paths from './paths';
import PrivateRoute from './PrivateRoute';

const RouterComponent = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path={paths.LOGIN} component={Login} />
      <Route exact path={paths.RESET_PASSWORD} component={ResetPassword} />

      <PrivateRoute path={paths.RESET_PASSWORD_USER} component={UserResetPassword} />
      <PrivateRoute path={paths.ADD_USER} component={User} />
      <PrivateRoute path={paths.MODIFY_USER} component={User} />
      <PrivateRoute path={paths.USERS} component={Users} />

      <PrivateRoute path={paths.ADD_PRODUCT} component={Product} />
      <PrivateRoute path={paths.MODIFY_PRODUCT} component={Product} />
      <PrivateRoute path={paths.COMPANIES_PRODUCTS} component={Products} />

      <PrivateRoute path={paths.ADD_COMPANY} component={Company} />
      <PrivateRoute path={paths.MODIFY_COMPANY} component={Company} />
      <PrivateRoute path={paths.COMPANIES} component={Companies} />

      <PrivateRoute path={paths.ADD_AUTHORITY} component={Authority} />
      <PrivateRoute path={paths.MODIFY_AUTHORITY} component={Authority} />
      <PrivateRoute path={paths.AUTHORITIES} component={Authorities} />

      <PrivateRoute path={paths.AUTHORITY_POSTS} component={AuthorityPosts} />

      <PrivateRoute
        path={paths.SHOPPING_WINDOW_DETAILS}
        component={ShoppingWindowPost}
      />
      <PrivateRoute path={paths.SHOPPING_WINDOW} component={ShoppingWindow} />

      <PrivateRoute path={paths.ADD_CATEGORY} component={Category} />
      <PrivateRoute path={paths.MODIFY_CATEGORY} component={Category} />
      <PrivateRoute path={paths.CATEGORIES} component={Categories} />

      <PrivateRoute path={paths.ADD_CAMPAIGN} component={Campaign} />
      <PrivateRoute path={paths.MODIFY_CAMPAIGN} component={Campaign} />
      <PrivateRoute path={paths.CAMPAIGNS} component={Campaigns} />

      <PrivateRoute path={paths.PROFILE} component={Profile} />

      <PrivateRoute path={paths.MESSAGING} component={Messaging} />

      <PrivateRoute path={paths.ROOT} component={Home} />
      <Route component={NotFound} />
    </Switch>
  </BrowserRouter>
);

export default RouterComponent;
