import { createReducer } from 'redux-act';

import {
  COMPANIES_FETCH_DATA_INIT,
  COMPANIES_FETCH_DATA_SUCCESS,
  COMPANIES_FETCH_DATA_FAIL,
  COMPANIES_DELETE_COMPANY_INIT,
  COMPANIES_DELETE_COMPANY_SUCCESS,
  COMPANIES_DELETE_COMPANY_FAIL,
  COMPANIES_CREATE_COMPANY_INIT,
  COMPANIES_CREATE_COMPANY_SUCCESS,
  COMPANIES_CREATE_COMPANY_FAIL,
  COMPANIES_MODIFY_COMPANY_INIT,
  COMPANIES_MODIFY_COMPANY_SUCCESS,
  COMPANIES_MODIFY_COMPANY_FAIL,
  COMPANIES_CLEAN_UP,
} from 'state/actions/companies';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const companiesReducer = createReducer(
  {
    [COMPANIES_CLEAN_UP]: () => initialState,
    [COMPANIES_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [COMPANIES_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.data.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }),
      loading: false,
      error: null,
    }),
    [COMPANIES_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [COMPANIES_DELETE_COMPANY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [COMPANIES_DELETE_COMPANY_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.filter((elem) => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [COMPANIES_DELETE_COMPANY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [COMPANIES_CREATE_COMPANY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [COMPANIES_CREATE_COMPANY_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.concat(payload.company),
      loading: false,
      error: null,
      success: true,
    }),
    [COMPANIES_CREATE_COMPANY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [COMPANIES_MODIFY_COMPANY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [COMPANIES_MODIFY_COMPANY_SUCCESS]: (state, payload) => ({
      ...state,
      data: !state.data
        ? []
        : state.data.map((elem) => {
            if (elem.id === payload.id) {
              return {
                id: payload.id,
                name: payload.company.name,
                logo: payload.company.logo,
                displayName: payload.company.displayName,
                validFrom: payload.company.validFrom,
                validTo: payload.company.validTo,
                email: payload.company.email,
                address: payload.company.address,
                products: payload.company.products,
              };
            }
            return elem;
          }),
      loading: false,
      error: null,
      success: true,
    }),
    [COMPANIES_MODIFY_COMPANY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
  },
  initialState
);
