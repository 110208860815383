import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { reducer as toastrReducer } from 'react-redux-toastr';

import { authReducer } from './auth';
import { preferencesReducer } from './preferences';
import { usersReducer } from './users';
import { productsReducer } from './products';
import { categoriesReducer } from './categories';
import { companiesReducer } from './companies';
import { shopPostsReducer } from './shoppingPosts';
import { campaignsReducer } from './campaigns';
import { authoritiesReducer } from './authorities';
import { authorityPostsReducer } from './authorityPosts';

export default combineReducers({
  auth: persistReducer(
    {
      key: 'auth',
      storage,
      blacklist: ['error', 'loading'],
    },
    authReducer
  ),
  preferences: persistReducer(
    { key: 'preferences', storage },
    preferencesReducer
  ),
  users: persistReducer(
    {
      key: 'users',
      storage,
      blacklist: ['error', 'loading'],
    },
    usersReducer
  ),
  products: persistReducer(
    {
      key: 'products',
      storage,
      blacklist: ['error', 'loading'],
    },
    productsReducer
  ),
  companies: persistReducer(
    {
      key: 'companies',
      storage,
      blacklist: ['error', 'loading'],
    },
    companiesReducer
  ),
  campaigns: persistReducer(
    {
      key: 'campaigns',
      storage,
      blacklist: ['error', 'loading'],
    },
    campaignsReducer
  ),
  shopPosts: persistReducer(
    {
      key: 'shopPosts',
      storage,
      blacklist: ['error', 'loading'],
    },
    shopPostsReducer
  ),
  categories: persistReducer(
    {
      key: 'categories',
      storage,
      blacklist: ['error', 'loading'],
    },
    categoriesReducer
  ),
  authorities: persistReducer(
    {
      key: 'authorities',
      storage,
      blacklist: ['error', 'loading'],
    },
    authoritiesReducer
  ),
  authorityPosts: persistReducer(
    {
      key: 'authorityPosts',
      storage,
      blacklist: ['error', 'loading'],
    },
    authorityPostsReducer
  ),
  toastr: toastrReducer,
});
