/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import moment from 'moment';
import { useParams, Redirect } from 'react-router-dom';
import classNames from 'classnames';
import ClipLoader from 'react-spinners/ClipLoader';
import { useFormatMessage } from 'hooks';
import Table from 'components/Table';
import {
  fetchPostsByAuthorityId,
  searchKeyword,
  searchPeriod,
} from 'state/actions/authorityPosts';
import paths from 'pages/Router/paths';
import CalendarPicker from 'components/CalendarPicker';
import classes from './AuthorityPosts.module.scss';

const AuthorityPosts = () => {
  const { authorityId } = useParams();
  const {
    posts,
    error,
    loading,
    selectedAuthority,
    keyword,
    startDate,
    endDate,
  } = useSelector(
    (state) => ({
      selectedAuthority: state.authorities.data.filter(
        (authority) => authority.id === authorityId
      ).pop(),
      posts: state.authorityPosts.data,
      error: state.authorityPosts.error,
      loading: state.authorityPosts.loading,
      keyword: state.authorityPosts.keyword,
      startDate: state.authorityPosts.startDate,
      endDate: state.authorityPosts.endDate ? moment(state.authorityPosts.endDate).set('hours', 23).set('minutes', 59).set('seconds', 59).toDate() : null,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const redirect = !authorityId || !selectedAuthority && <Redirect to={paths.COMPANIES} />;

  useEffect(() => {
    dispatch(fetchPostsByAuthorityId(authorityId));
  }, [dispatch, authorityId]);

  const columns = [
    {
      Header: useFormatMessage('Companies.no'),
      accessor: 'index',
      Cell: ({ row }) => row.index + 1,
    },
    {
      Header: useFormatMessage('AuthorityPosts.createdAt'),
      accessor: 'createdAt',
      Cell: ({ row }) => moment(row.original.createdAt).format('DD/MM/YYYY HH:mm:ss'),
    },
    {
      Header: useFormatMessage('AuthorityPosts.status'),
      accessor: 'status',
      Cell: ({ row }) => {
        // eslint-disable-next-line no-nested-ternary
        const status = row.original.published ? 'Online' : row.original.publish_mode === 'schedule' && row.original.publish_at ? 'Bereit' : 'Entwurf';
        return status;
      }
    },
    {
      Header: useFormatMessage('AuthorityPosts.title'),
      accessor: 'title',
    },
  ];

  let data = posts;
  if (keyword || (startDate && endDate)) {
    data = posts.filter((el) => {
      let isKeywordMaching = true;
      if (keyword) {
        isKeywordMaching = el.title
          .toLowerCase()
          .includes(keyword?.toLowerCase());
      }
      let isValidPeriodMatching = true;
      if (startDate && endDate) {
        const createdAt = moment(el.createdAt);
        isValidPeriodMatching =
          createdAt.isSameOrAfter(startDate) && createdAt.isSameOrBefore(endDate);
      }
      return isKeywordMaching && isValidPeriodMatching;
    });
  }

  return (
    <>
      {redirect}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="title">
                  {selectedAuthority.name}
                </h1>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <h3 className="mx-2">
                  {useFormatMessage('AuthorityPosts.period')}
                </h3>
                <CalendarPicker
                  startDate={startDate ? new Date(startDate) : null}
                  endDate={endDate ? new Date(endDate) : null}
                  isRange
                  onChange={(result) =>
                    dispatch(searchPeriod(result.startDate, result.endDate))
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section is-main-section">
        <div className="card has-table has-mobile-sort-spaced">
          <header className="card-header">
            <p className={classNames('card-header-title', classes.tableHeader)}>
              <span>{useFormatMessage('Categories.search')}</span>
              <input
                type="text"
                className="input"
                value={keyword}
                onChange={(e) => dispatch(searchKeyword(e.target.value))}
              />
            </p>
          </header>
          <div className="b-table">
            {loading ? (
              <ClipLoader />
            ) : (
              <Table keyword={keyword} columns={columns} data={data} />
            )}
            {error && 'Show error'}
          </div>
        </div>
      </section>
    </>
  );
};

export default AuthorityPosts;
