import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Link, useParams, Redirect } from 'react-router-dom';
import classNames from 'classnames';
import ClipLoader from 'react-spinners/ClipLoader';
import moment from 'moment';

import { useFormatMessage } from 'hooks';
import Table from 'components/Table';
import { fetchProducts, deleteProduct } from 'state/actions/products';
import paths from 'pages/Router/paths';
import ConfirmationModal from 'components/ConfirmationModal';
import classes from './Products.module.scss';

const Products = () => {
  const { companyId } = useParams();
  const [selectedCompany, setSelectedCompany] = useState(companyId);
  const { productList, error, loading, deleted, companies } = useSelector(
    (state) => ({
      productList: state.products.data,
      error: state.products.error,
      loading: state.products.loading,
      deleted: state.products.deleted,
      companies: [
        { id: 'NO_FILTER', name: useFormatMessage('Products.allCompany') },
        ...state.companies.data,
      ],
    }),
    shallowEqual
  );

  const [deleteModal, setDeleteModal] = useState({
    productId: null,
    isOpen: false,
  });

  const dispatch = useDispatch();

  const [search, setSearch] = useState('');

  const handleCompanyChanged = useCallback((event) => {
    setSelectedCompany(event.target.value);
  }, []);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal({
        productId: null,
        isOpen: false,
      });
    }
  }, [deleted, loading]);

  const onRemoveButtonClickHandler = (productId) => {
    setDeleteModal((prevState) => ({
      productId,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ productId: null, isOpen: false });
  };

  const onDeleteCompanyHandler = () => {
    dispatch(deleteProduct(deleteModal.productId));
  };

  const columns = [
    {
      Header: useFormatMessage('Products.name'),
      accessor: 'name',
    },
    {
      Header: useFormatMessage('ProductForm.activated'),
      accessor: 'activated',
      Cell: ({ row }) => {
        const today = moment().format('MM/DD/YYYY 00:00:00');
        const validFrom = moment(row.original.validFrom).format(
          'MM/DD/YYYY 00:00:00'
        );
        const validTo = moment(row.original.validTo).format(
          'MM/DD/YYYY 23:59:59'
        );
        const isFullyActivated =
          moment(validFrom).isSameOrBefore(today) &&
          moment(validTo).isSameOrAfter(today) &&
          row.original.activated;
        const isPartlyActivated = isFullyActivated
          ? false
          : moment(validFrom).isSameOrBefore(today) ||
            moment(validTo).isSameOrAfter(today) ||
            row.original.activated;
        const isExpired = moment(validTo).isBefore(today);
        let textColor = 'has-text-danger';
        let text = 'ProductForm.expired';
        if (isFullyActivated) {
          textColor = 'has-text-success';
          text = 'ProductForm.activated';
        } else if (isPartlyActivated && !isExpired) {
          textColor = 'has-text-warning';
          text = 'ProductForm.activated';
        }
        return (
          <small className={`${textColor} is-abbr-like`}>
            <input
              type="checkbox"
              checked={row.original.activated}
              disabled
              className="mr-1"
            />
            {useFormatMessage(text)}
          </small>
        );
      },
    },
    {
      Header: '',
      id: 'actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <div className="buttons is-right">
          <Link
            to={`/companies/${companyId}/products/${row.original.id}`}
            className="button is-small is-primary"
          >
            <span className="icon is-small">
              <i className="mdi mdi-square-edit-outline" />
            </span>
          </Link>

          <button
            type="button"
            className="button is-small is-danger"
            onClick={() => onRemoveButtonClickHandler(row.original.id)}
          >
            <span className="icon is-small">
              <i className="mdi mdi-trash-can" />
            </span>
          </button>
        </div>
      ),
      disableSortBy: true,
    },
  ];

  const filterBySelectedCompany = (product) => {
    if (selectedCompany === 'NO_FILTER') {
      return true;
    }
    return product.companyId === selectedCompany;
  };

  const data = search
    ? productList.filter(filterBySelectedCompany).filter((el) => {
        const clonedElem = { ...el };
        delete clonedElem.id;
        delete clonedElem.logo;
        delete clonedElem.preview;
        delete clonedElem.companyUrl;
        delete clonedElem.shoppingUrl;
        delete clonedElem.images;
        delete clonedElem.categories;
        delete clonedElem.createdAt;
        delete clonedElem.updatedAt;
        delete clonedElem.createdBy;
        delete clonedElem.activated;
        delete clonedElem.updatedBy;
        return Object.values(clonedElem).some((field) => {
          if (typeof field === 'string') {
            return field.toLowerCase().includes(search.toLowerCase());
          }
          return field === search;
        });
      })
    : productList.filter(filterBySelectedCompany);

  const deleteMessage = useFormatMessage('Products.delete');

  const confirmMessage = useFormatMessage('Products.confirm');

  const permDeleteMessage = useFormatMessage('Products.permDelete');

  const cancelMessage = useFormatMessage('Products.cancel');
  const redirect = !companyId && <Redirect to={paths.PRODUCTS} />;

  return (
    <>
      {redirect}
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteCompanyHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="title">
                  {useFormatMessage('Products.products')}
                </h1>
                <div className="mx-2 control has-icons-left">
                  <div className="select">
                    <select
                      value={selectedCompany}
                      onChange={handleCompanyChanged}
                    >
                      {companies.map((comp) => (
                        <option key={comp.id} value={comp.id}>
                          {comp.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <span className="icon is-left">
                    <i className="mdi mdi-badge-account-horizontal" />
                  </span>
                </div>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <Link
                  to={`/companies/${selectedCompany}/products/new`}
                  className="button"
                >
                  {useFormatMessage('Product.newProduct')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section is-main-section">
        <div className="card has-table has-mobile-sort-spaced">
          <header className="card-header">
            <p className={classNames('card-header-title', classes.tableHeader)}>
              <span>{useFormatMessage('Products.search')}</span>
              <input
                type="text"
                className="input"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </p>
          </header>
          <div className="b-table">
            {loading ? <ClipLoader /> : <Table columns={columns} data={data} />}
            {error && 'Show error'}
          </div>
        </div>
      </section>
    </>
  );
};

export default Products;
