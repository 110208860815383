import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';

import { deleteImages, firebaseError, uploadImages } from 'utils';
import firebase from 'firebase.js';
import {
  fetchDocument,
  documentRef,
  fetchCollection,
  normalizeDate,
} from '../api';

export const PRODUCTS_FETCH_DATA_INIT = createAction(
  'PRODUCTS_FETCH_DATA_INIT'
);
export const PRODUCTS_FETCH_DATA_SUCCESS = createAction(
  'PRODUCTS_FETCH_DATA_SUCCESS'
);
export const PRODUCTS_FETCH_DATA_FAIL = createAction(
  'PRODUCTS_FETCH_DATA_FAIL'
);

export const PRODUCTS_DELETE_PRODUCT_INIT = createAction(
  'PRODUCTS_DELETE_PRODUCT_INIT'
);
export const PRODUCTS_DELETE_PRODUCT_SUCCESS = createAction(
  'PRODUCTS_DELETE_PRODUCT_SUCCESS'
);
export const PRODUCTS_DELETE_PRODUCT_FAIL = createAction(
  'PRODUCTS_DELETE_PRODUCT_FAIL'
);

export const PRODUCTS_CREATE_PRODUCT_INIT = createAction(
  'PRODUCTS_CREATE_PRODUCT_INIT'
);
export const PRODUCTS_CREATE_PRODUCT_SUCCESS = createAction(
  'PRODUCTS_CREATE_PRODUCT_SUCCESS'
);
export const PRODUCTS_CREATE_PRODUCT_FAIL = createAction(
  'PRODUCTS_CREATE_PRODUCT_FAIL'
);

export const PRODUCTS_MODIFY_PRODUCT_INIT = createAction(
  'PRODUCTS_MODIFY_PRODUCT_INIT'
);
export const PRODUCTS_MODIFY_PRODUCT_SUCCESS = createAction(
  'PRODUCTS_MODIFY_PRODUCT_SUCCESS'
);
export const PRODUCTS_MODIFY_PRODUCT_FAIL = createAction(
  'PRODUCTS_MODIFY_PRODUCT_FAIL'
);
export const PRODUCTS_CLEAN_UP = createAction('PRODUCTS_CLEAN_UP');

export const fetchProducts =
  (companyId, productId) => async (dispatch, getState) => {
    dispatch(PRODUCTS_FETCH_DATA_INIT());

    // edit product
    if (productId) {
      let product;
      try {
        product = normalizeDate(await fetchDocument('products', productId), [
          'createdAt',
          'updatedAt',
          'validFrom',
          'validTo',
        ]);
      } catch (error) {
        toastr.error('', error);
        return dispatch(PRODUCTS_FETCH_DATA_FAIL({ error }));
      }
      const products = getState().products.data.map((doc) =>
        normalizeDate(doc, ['createdAt', 'validFrom', 'validTo', 'updatedAt'])
      );
      products.push(product);
      return dispatch(PRODUCTS_FETCH_DATA_SUCCESS, { data: products });
    }

    // fetch all products
    let products;
    try {
      let options = {};
      if (companyId) {
        options = {
          queries: [
            {
              attribute: 'companyId',
              operator: '==',
              value: companyId,
            },
          ],
        };
      }
      products = await fetchCollection('products', options);
    } catch (error) {
      const { locale } = getState().preferences;
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage || error.message || error);
      return dispatch(PRODUCTS_FETCH_DATA_FAIL({ error }));
    }

    return dispatch(
      PRODUCTS_FETCH_DATA_SUCCESS({
        data: products.map((doc) =>
          normalizeDate(doc, ['createdAt', 'validFrom', 'validTo', 'updatedAt'])
        ),
      })
    );
  };

export const deleteProduct = (id) => async (dispatch, getState) => {
  dispatch(PRODUCTS_DELETE_PRODUCT_INIT());
  const { locale } = getState().preferences;
  const { userData } = getState().auth;
  const { data } = getState().products;

  // const batch = firebase.firestore().batch();
  const jobs = [];
  const now = new Date();
  try {
    const product = data.find((comp) => comp.id === id);
    // unassign this product from it's own categories
    Object.keys(product.categories || {}).forEach((categoryId) => {
      const updateData = {
        [`products.${id}`]: firebase.firestore.FieldValue.delete(),
        updatedAt: now,
        updatedBy: userData.id,
      };
      jobs.push(
        documentRef('categories', categoryId)
          .update(updateData)
          .catch(() => {})
      );
    });

    await deleteImages({
      collection: 'products',
      URLs: [product.logo, product.preview],
    });

    const leaveCompany = {
      [`products.${id}`]: firebase.firestore.FieldValue.delete(),
      updatedAt: now,
      updatedBy: userData.id,
    };
    jobs.push(
      documentRef('companies', product.companyId)
        .update(leaveCompany)
        .catch(() => {})
    );
    jobs.push(documentRef('products', id).delete());
    await Promise.all(jobs);
  } catch (error) {
    const errorMessage = firebaseError(error.code, locale);
    toastr.error('', errorMessage);
    return dispatch(
      PRODUCTS_DELETE_PRODUCT_FAIL({
        error: errorMessage,
      })
    );
  }

  toastr.success('', 'The product was deleted.');
  return dispatch(PRODUCTS_DELETE_PRODUCT_SUCCESS({ id }));
};

export const createProduct =
  ({
    name,
    phone,
    companyId,
    description,
    address,
    companyUrl,
    shoppingUrl,
    logo,
    logoFile,
    previewFile,
    preview,
    images = [],
    categories = [],
    validFrom,
    validTo,
    activated,
  }) =>
  async (dispatch, getState) => {
    dispatch(PRODUCTS_CREATE_PRODUCT_INIT());

    const { userData } = getState().auth;
    const { locale } = getState().preferences;

    const productId = firebase.firestore().collection('products').doc().id;

    const batch = firebase.firestore().batch();
    const now = new Date();
    const _categories = {};

    // assign product to selected categories
    categories.forEach((categoryId) => {
      _categories[categoryId] = true;
      batch.set(
        documentRef('categories', categoryId),
        {
          products: {
            [productId]: true,
          },
          updatedAt: now,
          updatedBy: userData.id,
        },
        { merge: true }
      );
    });

    let productData = {
      companyId,
      name,
      phone,
      description,
      address,
      activated,
      logo,
      preview,
      companyUrl,
      shoppingUrl,
      images,
      categories: _categories,
      validFrom,
      validTo,
      createdAt: now,
      createdBy: userData.id,
    };
    try {
      const paths = await uploadImages({
        collection: 'products',
        documentId: productId,
        files: [logoFile, previewFile],
      });
      paths.forEach((path, index) => {
        if (typeof path === 'string' && index === 0) {
          productData = { ...productData, logo: path };
        }
        if (typeof path === 'string' && index === 1) {
          productData = { ...productData, preview: path };
        }
      });
      const joinCompany = {
        products: {
          [productId]: true,
        },
        updatedAt: now,
        updatedBy: userData.id,
      };
      batch.set(documentRef('companies', companyId), joinCompany, {
        merge: true,
      });
      batch.set(documentRef('products', productId), productData);
      await batch.commit();
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        PRODUCTS_CREATE_PRODUCT_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Product created successfully');
    return dispatch(
      PRODUCTS_CREATE_PRODUCT_SUCCESS({
        product: { ...productData, id: productId },
      })
    );
  };

export const modifyProduct =
  ({
    name,
    phone,
    companyId,
    description,
    address,
    activated,
    companyUrl,
    shoppingUrl,
    logo,
    preview,
    images = [],
    logoFile,
    previewFile,
    categories = [],
    validFrom,
    validTo,
    prevCategories = [],
    prevLogo,
    prevPreview,
    prevCompanyId,
    id,
  }) =>
  async (dispatch, getState) => {
    dispatch(PRODUCTS_MODIFY_PRODUCT_INIT());
    const { userData } = getState().auth;
    const { locale } = getState().preferences;

    const batch = firebase.firestore().batch();
    const now = new Date();
    const _categories = {};

    // assign company to selected categories
    categories.forEach((categoryId) => {
      _categories[categoryId] = true;
      batch.set(
        documentRef('categories', categoryId),
        {
          products: {
            [id]: true,
          },
          updatedAt: now,
          updatedBy: userData.id,
        },
        { merge: true }
      );
    });
    if (prevCompanyId !== companyId) {
      const leaveCompany = {
        [`products.${id}`]: firebase.firestore.FieldValue.delete(),
        updatedAt: now,
        updatedBy: userData.id,
      };
      batch.update(documentRef('companies', prevCompanyId), leaveCompany);
      const joinCompany = {
        products: {
          [id]: true,
        },
        updatedAt: now,
        updatedBy: userData.id,
      };
      batch.set(documentRef('companies', companyId), joinCompany, {
        merge: true,
      });
    }

    const leavedCategories = prevCategories.filter(
      (catId) => !categories.includes(catId)
    );
    // unassign company to deselected categories
    leavedCategories.forEach((categoryId) => {
      const updateData = {
        [`products.${id}`]: firebase.firestore.FieldValue.delete(),
        updatedAt: now,
        updatedBy: userData.id,
      };
      batch.update(documentRef('categories', categoryId), updateData);
    });

    let productData = {
      companyId,
      name,
      phone,
      description,
      address,
      activated,
      companyUrl,
      shoppingUrl,
      images,
      logo,
      preview,
      categories: _categories,
      validFrom,
      validTo,
      updatedAt: new Date(),
      updatedBy: userData.id,
    };
    try {
      const delImages = [];
      if (logoFile) {
        delImages.push(prevLogo);
      }
      if (previewFile) {
        delImages.push(prevPreview);
      }
      await deleteImages({
        collection: 'products',
        URLs: delImages,
      });
      const paths = await uploadImages({
        collection: 'products',
        documentId: id,
        files: [logoFile, previewFile],
      });
      paths.forEach((path, index) => {
        if (typeof path === 'string' && index === 0) {
          productData = { ...productData, logo: path };
        }
        if (typeof path === 'string' && index === 1) {
          productData = { ...productData, preview: path };
        }
      });
      // modify company
      batch.update(documentRef('products', id), productData);
      await batch.commit();
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        PRODUCTS_MODIFY_PRODUCT_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Product updated successfully');

    return dispatch(
      PRODUCTS_MODIFY_PRODUCT_SUCCESS({ product: productData, id })
    );
  };
