/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup';
import paths from 'pages/Router/paths';
import { useFormatMessage } from 'hooks';
import Select from 'components/Select';
import './CategoryForm.scss';
import { getCategoryTreeRecusively } from '../../utils';

const CategoryForm = ({
  id,
  category,
  isEditing,
  onSubmitHandler,
  schema,
}) => {
  const { loading, categories } = useSelector(
    (state) => ({
      loading: state.categories.loading,
      success: state.categories.success,
      categories: [{id: 'NONE', name: '__NONE__'}, ...getCategoryTreeRecusively(state.categories.data, id)]
    }),
    shallowEqual
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: { ...category },
    resolver: yupResolver(schema),
  });

  const goBackMessage = useFormatMessage('Categories.goBack');

  // const selectedCompanies =
  //   (watch('products') &&
  //     Array.isArray(products) &&
  //     products.length > 0 &&
  //     watch('products').map((pID) => {
  //       const compIndex = products.findIndex((comp) => comp.id === pID);
  //       return products[compIndex].name || 'NOT_FOUND';
  //     })) ||
  //   [];

  /* eslint-disable */
  return (
    <>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <div className="tile is-ancestor">
          <div className="tile is-parent">
            <div className="card tile is-child">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-shape-outline default" />
                  </span>
                  {useFormatMessage('Categories.info')}
                </p>
              </header>
              <div className="card-content">
                {isEditing && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        {useFormatMessage('Categories.id')}
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <input
                            type="text"
                            readOnly="readOnly"
                            className="input is-static"
                            {...register('id')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* parentCategory */}
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('Categories.parentCategory')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="select">
                      <Select
                        name="parentCategory"
                        label={useFormatMessage('Categories.parentCategory')}
                        register={register}
                        options={categories}
                      />
                    </div>
                  </div>
                </div>

                {/* name */}
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('Categories.name')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          {...register('name')}
                          id="name"
                          className={classNames('input', {
                            'is-danger': errors.name,
                          })}
                          type="text"
                          placeholder={useFormatMessage(
                            'Categories.placeholderName'
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* code */}
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('Categories.code')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          {...register('code')}
                          id="code"
                          className={classNames('input', {
                            'is-danger': errors.code,
                          })}
                          type="text"
                          placeholder={useFormatMessage(
                            'Categories.placeholderName'
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('Categories.products')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="select is-multiple">
                      <Select
                        name="products"
                        label={useFormatMessage('Categories.products')}
                        multiple
                        loading={loadingProduct}
                        register={register}
                        options={products}
                      />
                    </div>
                  </div>
                </div> */}

                <hr />

                <div className="field is-horizontal">
                  <div className="field-label" />
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        <div className="control">
                          <button
                            type="submit"
                            className={`button is-primary ${
                              loading && 'is-loading'
                            }`}
                          >
                            <span>{useFormatMessage('Categories.submit')}</span>
                          </button>
                        </div>
                        <Link to={paths.CATEGORIES} className="button">
                          {goBackMessage}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Preview */}
          <div className="tile is-parent preview">
            <div className="card tile is-child">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-shape-outline default" />
                  </span>
                  {useFormatMessage('Categories.preview')}
                </p>
              </header>
              <div className="card-content">
                <div className="field">
                  <label className="label">
                    {useFormatMessage('Categories.name')}
                  </label>
                  <div className="control">
                    <input
                      readOnly="readOnly"
                      className="input is-static"
                      data-testid="name"
                      value={watch('name')}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label">
                    {useFormatMessage('Categories.code')}
                  </label>
                  <div className="control">
                    <input
                      readOnly="readOnly"
                      className="input is-static"
                      data-testid="code"
                      value={watch('code')}
                    />
                  </div>
                </div>

                {/* <div className="field">
                  <label className="label">
                    {useFormatMessage('Categories.products')}
                  </label>
                  <div className="field">
                    <div className="tags">
                      {selectedCompanies.map((compName) => (
                        <span key={compName} className="tag">
                          {compName}
                        </span>
                      ))}
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

CategoryForm.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    products: PropTypes.arrayOf(PropTypes.string.isRequired),
    code: PropTypes.string.isRequired,
  }).isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  schema: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
};

CategoryForm.defaultProps = {
  isEditing: false,
};

export default CategoryForm;
