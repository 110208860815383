import { createReducer } from 'redux-act';

import {
  AUTHORITIES_FETCH_DATA_INIT,
  AUTHORITIES_FETCH_DATA_SUCCESS,
  AUTHORITIES_FETCH_DATA_FAIL,
  AUTHORITIES_DELETE_AUTHORITY_INIT,
  AUTHORITIES_DELETE_AUTHORITY_SUCCESS,
  AUTHORITIES_DELETE_AUTHORITY_FAIL,
  AUTHORITIES_CREATE_AUTHORITY_INIT,
  AUTHORITIES_CREATE_AUTHORITY_SUCCESS,
  AUTHORITIES_CREATE_AUTHORITY_FAIL,
  AUTHORITIES_MODIFY_AUTHORITY_INIT,
  AUTHORITIES_MODIFY_AUTHORITY_SUCCESS,
  AUTHORITIES_MODIFY_AUTHORITY_FAIL,
  AUTHORITIES_CLEAN_UP,
} from 'state/actions/authorities';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const authoritiesReducer = createReducer(
  {
    [AUTHORITIES_CLEAN_UP]: () => initialState,
    [AUTHORITIES_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [AUTHORITIES_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.data.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }),
      loading: false,
      error: null,
    }),
    [AUTHORITIES_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [AUTHORITIES_DELETE_AUTHORITY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [AUTHORITIES_DELETE_AUTHORITY_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.filter((elem) => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [AUTHORITIES_DELETE_AUTHORITY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [AUTHORITIES_CREATE_AUTHORITY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [AUTHORITIES_CREATE_AUTHORITY_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.concat(payload.authority),
      loading: false,
      error: null,
      success: true,
    }),
    [AUTHORITIES_CREATE_AUTHORITY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [AUTHORITIES_MODIFY_AUTHORITY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [AUTHORITIES_MODIFY_AUTHORITY_SUCCESS]: (state, payload) => ({
      ...state,
      data: !state.data
        ? []
        : state.data.map((elem) => {
            if (elem.id === payload.id) {
              return {
                id: payload.id,
                name: payload.authority.name,
                address: payload.authority.address,
                phone: payload.authority.phone,
                members: payload.authority.members,
                logo: payload.authority.logo,
                cover: payload.authority.cover,
                link1: payload.authority.link1,
                link2: payload.authority.link2,
                link3: payload.authority.link3,
              };
            }
            return elem;
          }),
      loading: false,
      error: null,
      success: true,
    }),
    [AUTHORITIES_MODIFY_AUTHORITY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
  },
  initialState
);
