import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';

import { firebaseError } from 'utils';
import firebase from 'firebase.js';
import {
  documentRef,
  fetchDocument,
  fetchCollection,
  normalizeDate,
} from '../api';

export const CATEGORIES_FETCH_DATA_INIT = createAction(
  'CATEGORIES_FETCH_DATA_INIT'
);
export const CATEGORIES_FETCH_DATA_SUCCESS = createAction(
  'CATEGORIES_FETCH_DATA_SUCCESS'
);
export const CATEGORIES_FETCH_DATA_FAIL = createAction(
  'CATEGORIES_FETCH_DATA_FAIL'
);

export const CATEGORIES_DELETE_CATEGORY_INIT = createAction(
  'CATEGORIES_DELETE_CATEGORY_INIT'
);
export const CATEGORIES_DELETE_CATEGORY_SUCCESS = createAction(
  'CATEGORIES_DELETE_CATEGORY_SUCCESS'
);
export const CATEGORIES_DELETE_CATEGORY_FAIL = createAction(
  'CATEGORIES_DELETE_CATEGORY_FAIL'
);

export const CATEGORIES_CREATE_CATEGORY_INIT = createAction(
  'CATEGORIES_CREATE_CATEGORY_INIT'
);
export const CATEGORIES_CREATE_CATEGORY_SUCCESS = createAction(
  'CATEGORIES_CREATE_CATEGORY_SUCCESS'
);
export const CATEGORIES_CREATE_CATEGORY_FAIL = createAction(
  'CATEGORIES_CREATE_CATEGORY_FAIL'
);

export const CATEGORIES_MODIFY_CATEGORY_INIT = createAction(
  'CATEGORIES_MODIFY_CATEGORY_INIT'
);
export const CATEGORIES_MODIFY_CATEGORY_SUCCESS = createAction(
  'CATEGORIES_MODIFY_CATEGORY_SUCCESS'
);
export const CATEGORIES_MODIFY_CATEGORY_FAIL = createAction(
  'CATEGORIES_MODIFY_CATEGORY_FAIL'
);
export const CATEGORIES_CLEAN_UP = createAction(
  'CATEGORIES_CLEAN_UP'
);

export const fetchCategories = (categoryId) => async (dispatch, getState) => {
  dispatch(CATEGORIES_FETCH_DATA_INIT());

  // edit category
  if (categoryId) {
    let category;
    try {
      category = normalizeDate(await fetchDocument('categories', categoryId), [
        'createdAt',
        'updatedAt',
      ]);
    } catch (error) {
      toastr.error('', error);
      return dispatch(CATEGORIES_FETCH_DATA_FAIL({ error }));
    }
    const categories = getState().categories.data.map((doc) =>
      normalizeDate(doc, ['createdAt', 'updatedAt'])
    );
    categories.push(category);
    return dispatch(CATEGORIES_FETCH_DATA_SUCCESS, { data: categories });
  }

  // fetch all categories
  let categories;
  try {
    categories = await fetchCollection('categories');
  } catch (error) {
    toastr.error('', error);
    return dispatch(CATEGORIES_FETCH_DATA_FAIL({ error }));
  }

  return dispatch(
    CATEGORIES_FETCH_DATA_SUCCESS({
      data: categories.map((doc) =>
        normalizeDate(doc, ['createdAt', 'validFrom', 'validTo', 'updatedAt'])
      ),
    })
  );
};

export const deleteCategory = (id) => async (dispatch, getState) => {
  dispatch(CATEGORIES_DELETE_CATEGORY_INIT());
  const { locale } = getState().preferences;
  const { data } = getState().categories;
  const { userData } = getState().auth;
  const batch = firebase.firestore().batch();
  try {
    const category = data.find(cat => cat.id === id);
    const now = new Date();

    // unassign category from selected products
    Object.keys(category.products || {}).forEach((productId) => {
      const updateData = {
        [`categories.${id}`]: firebase.firestore.FieldValue.delete(),
        updatedAt: now,
        updatedBy: userData.id,
      };
      batch.update(documentRef('products', productId), updateData);
    });

    // delete category
    batch.delete(documentRef('categories', id));
    await batch.commit();
  } catch (error) {
    const errorMessage = firebaseError(error.code, locale);
    toastr.error('', errorMessage);
    return dispatch(
      CATEGORIES_DELETE_CATEGORY_FAIL({
        error: errorMessage,
      })
    );
  }

  toastr.success('', 'The category was deleted.');
  return dispatch(CATEGORIES_DELETE_CATEGORY_SUCCESS({ id }));
};

export const createCategory =
  ({ name, code, parentCategory }) =>
  async (dispatch, getState) => {
    dispatch(CATEGORIES_CREATE_CATEGORY_INIT());
    const { userData } = getState().auth;
    const { locale } = getState().preferences;

    const categoryId = firebase.firestore().collection('categories').doc().id;
    const now = new Date();

    let categoryData = {
      name,
      code,
      createdAt: now,
      createdBy: userData.id,
    };
    if (parentCategory !== 'NONE') {
      categoryData = {
        ...categoryData,
        parentCategory,
      };
    }

    try {
      await documentRef('categories', categoryId).set(categoryData);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        CATEGORIES_CREATE_CATEGORY_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Category created successfully');
    return dispatch(
      CATEGORIES_CREATE_CATEGORY_SUCCESS({
        category: { ...categoryData, id: categoryId },
      })
    );
  };

export const modifyCategory =
  ({ name, code, parentCategory, prevParentCategory, id }) =>
  async (dispatch, getState) => {
    dispatch(CATEGORIES_MODIFY_CATEGORY_INIT());
    const { userData } = getState().auth;
    const { locale } = getState().preferences;

    const now = new Date();

    let _parentCategory = parentCategory;
    if (prevParentCategory && parentCategory === 'NONE') {
      _parentCategory = firebase.firestore.FieldValue.delete();
    }
    const categoryData = {
      name,
      code,
      parentCategory: _parentCategory,
      updatedAt: now,
      updatedBy: userData.id,
    };

    try {
      await documentRef('categories', id).set(categoryData, { merge: true });
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        CATEGORIES_MODIFY_CATEGORY_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Category updated successfully');

    return dispatch(
      CATEGORIES_MODIFY_CATEGORY_SUCCESS({ category: categoryData, id })
    );
  };
