import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as yup from 'yup';

import { useFormatMessage } from 'hooks';
import UserForm from 'components/UserForm';
import { modifyUser } from 'state/actions/users';
import { fetchCompanies } from 'state/actions/companies';
import { fetchAuthorities } from 'state/actions/authorities';
import ChangePassword from './ChangePassword';

const schema = yup.object().shape({
  name: yup.string().required(),
  location: yup.string().notRequired(),
  createdAt: yup.string().required(),
});

const Profile = () => {
  const { userData, companies, companyLoading, authorities, authorityLoading } = useSelector(
    (state) => ({
      userData: state.auth.userData,
      companies: [{ id: 'NONE', name: '__NONE__' }, ...state.companies.data],
      authorities: [{ id: 'NONE', name: '__NONE__' }, ...state.authorities.data],
      companyLoading: state.companies.loading,
      authorityLoading: state.authorities.loading,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(fetchCompanies());
    dispatch(fetchAuthorities());
  }, []);

  const onSubmitHandler = (value) => {
    const newUser = {
      ...value,
      isEditing: true,
      isProfile: true,
      id: userData.id,
    };
    dispatch(modifyUser(newUser));
  };

  return (
    <>
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">{useFormatMessage('Profile.profile')}</h1>
        </div>
      </section>
      <section className="section is-main-section">
        <UserForm
          isEditing
          isProfile
          user={{
            ...userData,
            companyId: userData.companyId ?? 'NONE',
            authorityId: userData.authorityId ?? 'NONE',
          }}
          companies={companies}
          companyLoading={companyLoading}
          authorities={authorities}
          authorityLoading={authorityLoading}
          onSubmitHandler={onSubmitHandler}
          schema={schema}
        />
        <ChangePassword />
      </section>
    </>
  );
};

export default Profile;
