import React, { useEffect, useRef } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import bulmaCalendar from 'bulma-calendar';
import { yupResolver } from '@hookform/resolvers/yup';
import paths from 'pages/Router/paths';
import { useFormatMessage } from 'hooks';
import './CampaignForm.scss';

const CampaignForm = ({ campaign, isEditing, onSubmitHandler, schema }) => {
  const validRangeRef = useRef();
  const { loading, locale } = useSelector(
    (state) => ({
      loading: state.campaigns.loading,
      success: state.campaigns.success,
      locale: state.preferences.locale,
    }),
    shallowEqual
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: campaign,
    resolver: yupResolver(schema),
  });

  const goBackMessage = useFormatMessage('Categories.goBack');

  useEffect(() => {
    const _startAt = campaign.validRange.startAt
      ? new Date(campaign.validRange.startAt)
      : null;
    const _endAt = campaign.validRange.endAt
      ? new Date(campaign.validRange.endAt)
      : null;
    if (!validRangeRef.current) {
      const [calendar] = bulmaCalendar.attach(`#validRange`, {
        type: 'date',
        isRange: true,
        showClearButton: false,
        allowSameDayRange: true,
        displayMode: 'default',
        showTodayButton: true,
        labelFrom: 'Aus',
        labelTo: 'Zu',
        startDate: _startAt,
        endDate: _endAt,
        lang: 'de',
      });
      validRangeRef.current = calendar;
    }
    const element = document.querySelector(`#validRange`);
    if (element && element.bulmaCalendar) {
      element.bulmaCalendar.on('select', (datepicker) => {
        const [startAt, endAt] = datepicker.data
          .value()
          .split('-')
          .map((val) => new Date(val.trim()));
        setValue('validRange', { startAt, endAt });
      });
      element.bulmaCalendar.on('clear', () => {
        setValue('validRange', { startAt: _startAt, endAt: _endAt });
      });
    }
    return () => {
      if (element && element.bulmaCalendar) {
        element.bulmaCalendar.removeListeners('select');
        element.bulmaCalendar.removeListeners('clear');
      }
    };
  }, [locale, campaign.validRange]);

  /* eslint-disable */
  return (
    <>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <div className="tile is-ancestor">
          <div className="tile is-parent">
            <div className="card tile is-child">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-shape-outline default" />
                  </span>
                  {useFormatMessage('Categories.info')}
                </p>
              </header>
              <div className="card-content">
                {isEditing && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        {useFormatMessage('Categories.id')}
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <input
                            type="text"
                            readOnly="readOnly"
                            className="input is-static"
                            {...register('id')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* title */}
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('CampaignsForm.title')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          {...register('title')}
                          id="title"
                          required
                          className={classNames('input', {
                            'is-danger': errors.title,
                          })}
                          type="text"
                          placeholder={useFormatMessage('CampaignsForm.title')}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* validRange */}
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('CampaignsForm.range')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input id="validRange" required />
                      </div>
                    </div>
                  </div>
                </div>

                {/* banners */}
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('CampaignsForm.banners')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          {...register('banners')}
                          required
                          className={classNames('input', {
                            'is-danger': errors.banners,
                          })}
                          multiple
                          accept="image/png, image/gif, image/jpeg"
                          type="file"
                          placeholder={useFormatMessage(
                            'CampaignsForm.banners'
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* attachment */}
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('CampaignsForm.attachment')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          {...register('attachmentFile')}
                          required
                          className={classNames('input', {
                            'is-danger': errors.attachment,
                          })}
                          accept="application/pdf,application/vnd.ms-excel"
                          type="file"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* terms */}
                {/* <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('CampaignsForm.terms')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <textarea
                          {...register('terms')}
                          id="terms"
                          rows={20}
                          required
                          className={classNames('textarea', {
                            'is-danger': errors.terms,
                          })}
                          placeholder={useFormatMessage(
                            'CampaignsForm.terms'
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div> */}

                <hr />

                <div className="field is-horizontal">
                  <div className="field-label" />
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        <div className="control">
                          <button
                            type="submit"
                            className={`button is-primary ${
                              loading && 'is-loading'
                            }`}
                          >
                            <span>{useFormatMessage('Categories.submit')}</span>
                          </button>
                        </div>
                        <Link to={paths.CAMPAIGNS} className="button">
                          {goBackMessage}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

CampaignForm.propTypes = {
  campaign: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string.isRequired,
    validRange: PropTypes.shape({
      startAt: PropTypes.instanceOf(Date).isRequired,
      endAt: PropTypes.instanceOf(Date).isRequired,
    }).isRequired,
  }).isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  schema: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
};

CampaignForm.defaultProps = {
  isEditing: false,
};

export default CampaignForm;
