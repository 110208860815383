import { createReducer } from 'redux-act';

import {
  CAMPAIGNS_FETCH_DATA_INIT,
  CAMPAIGNS_FETCH_DATA_SUCCESS,
  CAMPAIGNS_FETCH_DATA_FAIL,
  CAMPAIGNS_DELETE_CAMPAIGN_INIT,
  CAMPAIGNS_DELETE_CAMPAIGN_SUCCESS,
  CAMPAIGNS_DELETE_CAMPAIGN_FAIL,
  CAMPAIGNS_CREATE_CAMPAIGN_INIT,
  CAMPAIGNS_CREATE_CAMPAIGN_SUCCESS,
  CAMPAIGNS_CREATE_CAMPAIGN_FAIL,
  CAMPAIGNS_MODIFY_CAMPAIGN_INIT,
  CAMPAIGNS_MODIFY_CAMPAIGN_SUCCESS,
  CAMPAIGNS_MODIFY_CAMPAIGN_FAIL,
  CAMPAIGNS_CLEAN_UP,
} from 'state/actions/campaigns';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const campaignsReducer = createReducer(
  {
    [CAMPAIGNS_CLEAN_UP]: () => initialState,
    [CAMPAIGNS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [CAMPAIGNS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.data.sort((a, b) => {
        if (a.title < b.title) {
          return -1;
        }
        if (a.title > b.title) {
          return 1;
        }
        return 0;
      }),
      loading: false,
      error: null,
    }),
    [CAMPAIGNS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [CAMPAIGNS_DELETE_CAMPAIGN_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [CAMPAIGNS_DELETE_CAMPAIGN_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.filter((elem) => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [CAMPAIGNS_DELETE_CAMPAIGN_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [CAMPAIGNS_CREATE_CAMPAIGN_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [CAMPAIGNS_CREATE_CAMPAIGN_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.concat(payload.campaign),
      loading: false,
      error: null,
      success: true,
    }),
    [CAMPAIGNS_CREATE_CAMPAIGN_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [CAMPAIGNS_MODIFY_CAMPAIGN_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [CAMPAIGNS_MODIFY_CAMPAIGN_SUCCESS]: (state, payload) => ({
      ...state,
      data: !state.data
        ? []
        : state.data.map((elem) => {
            if (elem.id === payload.id) {
              return {
                id: payload.id,
                title: payload.campaign.title,
                terms: payload.campaign.terms,
                startAt: payload.campaign.startAt,
                endAt: payload.campaign.endAt,
              };
            }
            return elem;
          }),
      loading: false,
      error: null,
      success: true,
    }),
    [CAMPAIGNS_MODIFY_CAMPAIGN_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
  },
  initialState
);
