import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';

import { firebaseError, uploadImages } from 'utils';
import firebase from 'firebase.js';
import {
  fetchDocument,
  documentRef,
  fetchCollection,
  normalizeDate,
} from '../api';

export const CAMPAIGNS_FETCH_DATA_INIT = createAction(
  'CAMPAIGNS_FETCH_DATA_INIT'
);
export const CAMPAIGNS_FETCH_DATA_SUCCESS = createAction(
  'CAMPAIGNS_FETCH_DATA_SUCCESS'
);
export const CAMPAIGNS_FETCH_DATA_FAIL = createAction(
  'CAMPAIGNS_FETCH_DATA_FAIL'
);

export const CAMPAIGNS_DELETE_CAMPAIGN_INIT = createAction(
  'CAMPAIGNS_DELETE_CAMPAIGN_INIT'
);
export const CAMPAIGNS_DELETE_CAMPAIGN_SUCCESS = createAction(
  'CAMPAIGNS_DELETE_CAMPAIGN_SUCCESS'
);
export const CAMPAIGNS_DELETE_CAMPAIGN_FAIL = createAction(
  'CAMPAIGNS_DELETE_CAMPAIGN_FAIL'
);

export const CAMPAIGNS_CREATE_CAMPAIGN_INIT = createAction(
  'CAMPAIGNS_CREATE_CAMPAIGN_INIT'
);
export const CAMPAIGNS_CREATE_CAMPAIGN_SUCCESS = createAction(
  'CAMPAIGNS_CREATE_CAMPAIGN_SUCCESS'
);
export const CAMPAIGNS_CREATE_CAMPAIGN_FAIL = createAction(
  'CAMPAIGNS_CREATE_CAMPAIGN_FAIL'
);

export const CAMPAIGNS_MODIFY_CAMPAIGN_INIT = createAction(
  'CAMPAIGNS_MODIFY_CAMPAIGN_INIT'
);
export const CAMPAIGNS_MODIFY_CAMPAIGN_SUCCESS = createAction(
  'CAMPAIGNS_MODIFY_CAMPAIGN_SUCCESS'
);
export const CAMPAIGNS_MODIFY_CAMPAIGN_FAIL = createAction(
  'CAMPAIGNS_MODIFY_CAMPAIGN_FAIL'
);
export const CAMPAIGNS_CLEAN_UP = createAction('CAMPAIGNS_CLEAN_UP');

export const fetchCampaigns = (campaignId) => async (dispatch, getState) => {
  dispatch(CAMPAIGNS_FETCH_DATA_INIT());

  // edit campaign
  if (campaignId) {
    let campaign;
    try {
      campaign = normalizeDate(await fetchDocument('campaigns', campaignId), [
        'startAt',
        'endAt',
        'createdAt',
        'updatedAt',
      ]);
    } catch (error) {
      toastr.error('', error);
      return dispatch(CAMPAIGNS_FETCH_DATA_FAIL({ error }));
    }
    const campaigns = getState().campaigns.data.map((doc) =>
      normalizeDate(doc, ['startAt', 'endAt', 'createdAt', 'updatedAt'])
    );
    campaigns.push(campaign);
    return dispatch(CAMPAIGNS_FETCH_DATA_SUCCESS, { data: campaigns });
  }

  // fetch all campaigns
  let campaigns;
  try {
    campaigns = await fetchCollection('campaigns');
  } catch (error) {
    toastr.error('', error);
    return dispatch(CAMPAIGNS_FETCH_DATA_FAIL({ error }));
  }

  return dispatch(
    CAMPAIGNS_FETCH_DATA_SUCCESS({
      data: campaigns.map((doc) =>
        normalizeDate(doc, ['startAt', 'endAt', 'createdAt', 'updatedAt'])
      ),
    })
  );
};
export const deleteCampaign = (id) => async (dispatch, getState) => {
  dispatch(CAMPAIGNS_DELETE_CAMPAIGN_INIT());
  const { locale } = getState().preferences;

  try {
    await firebase.functions().httpsCallable('httpsDeleteCampaign')({
      campaignId: id,
    });
    toastr.success('', 'The campaign was deleted.');
    return dispatch(CAMPAIGNS_DELETE_CAMPAIGN_SUCCESS({ id }));
  } catch (error) {
    const errorMessage = firebaseError(error.code, locale);
    toastr.error('', errorMessage);
    return dispatch(
      CAMPAIGNS_DELETE_CAMPAIGN_FAIL({
        error: errorMessage,
      })
    );
  }
};

export const createCampaign =
  ({ title, attachmentFile, validRange, banners }) =>
  async (dispatch, getState) => {
    dispatch(CAMPAIGNS_CREATE_CAMPAIGN_INIT());

    const { userData } = getState().auth;
    const { locale } = getState().preferences;

    const campaignId = firebase.firestore().collection('campaigns').doc().id;
    const now = new Date();

    let campaignData = {
      title,
      ...validRange,
      createdAt: now,
      createdBy: userData.id,
    };

    try {
      const uploadingImages = Array.from(banners);
      if (uploadingImages.length === 0) {
        throw Error('banners_required');
      }
      // upload banners
      const uploadedBanners = await uploadImages({
        collection: 'campaigns',
        documentId: campaignId,
        files: uploadingImages,
      });
      campaignData = { ...campaignData, banners: uploadedBanners };

      if (attachmentFile) {
        const [_attachment] = await uploadImages({
          collection: 'campaigns',
          documentId: campaignId,
          files: [attachmentFile],
        });
        campaignData = { ...campaignData, attachment: _attachment };
      }
      await documentRef('campaigns', campaignId).set(campaignData);
      toastr.success('', 'Campaign created successfully');
      dispatch(
        CAMPAIGNS_CREATE_CAMPAIGN_SUCCESS({
          campaign: { ...campaignData, id: campaignId },
        })
      );
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      dispatch(
        CAMPAIGNS_CREATE_CAMPAIGN_FAIL({
          error: errorMessage,
        })
      );
    }
  };

export const modifyCampaign =
  ({
    id,
    title,
    validRange,
    attachmentFile,
    banners,
    // prevBanners,
    // prevAttachment,
  }) =>
  async (dispatch, getState) => {
    dispatch(CAMPAIGNS_MODIFY_CAMPAIGN_INIT());
    const { userData } = getState().auth;
    const { locale } = getState().preferences;

    let campaignData = {
      title,
      ...validRange,
      updatedAt: new Date(),
      updatedBy: userData.id,
    };
    try {
      const uploadingImages = Array.from(banners);
      if (uploadingImages.length > 0) {
        // upload new logo
        const uploadedBanners = await uploadImages({
          collection: 'campaigns',
          documentId: id,
          files: uploadingImages,
        });
        campaignData = {
          ...campaignData,
          banners: uploadedBanners.filter((banner) => banner),
        };
      }
      if (attachmentFile) {
        const [_attachment] = await uploadImages({
          collection: 'campaigns',
          documentId: id,
          files: [attachmentFile],
        });
        campaignData = { ...campaignData, attachment: _attachment };
      }

      // modify campaign
      await documentRef('campaigns', id).update(campaignData);
      toastr.success('', 'Campaign updated successfully');
      const data = await fetchDocument('campaigns', id);
      const campaign = normalizeDate(data, [
        'startAt',
        'endAt',
        'createdAt',
        'updatedAt',
      ]);
      dispatch(CAMPAIGNS_MODIFY_CAMPAIGN_SUCCESS({ campaign, id }));
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      dispatch(
        CAMPAIGNS_MODIFY_CAMPAIGN_FAIL({
          error: errorMessage,
        })
      );
    }
  };
