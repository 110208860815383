import React, { useMemo } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import * as yup from 'yup';
import paths from 'pages/Router/paths';
import { useFormatMessage } from 'hooks';
import CampaignForm from 'components/CampaignForm';
import moment from 'moment/moment';
import { modifyCampaign, createCampaign } from '../../state/actions/campaigns';

const schema = yup.object().shape({
  title: yup.string().required(),
  validRange: yup.object().shape({
    startAt: yup.date().required(),
    endAt: yup.date().required(),
  }),
});
const Campaign = () => {
  const { id } = useParams();

  const isEditing = useMemo(() => !!id, [id]);

  const { success, selectedCampaign, error } = useSelector(
    (state) => ({
      success: state.campaigns.success,
      selectedCampaign: state.campaigns.data.find(
        (campaign) => campaign.id === id
      ),
      error: state.campaigns.error,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const redirect = ((isEditing && error) || success) && (
    <Redirect to={paths.CAMPAIGNS} />
  );

  const editCampaignMessage = useFormatMessage('Campaigns.editCampaign');
  const newCampaignMessage = useFormatMessage('Campaigns.newCampaign');

  const onSubmitHandler = (value) => {
    const attachmentFile = value?.attachmentFile[0] || null;
    if (isEditing) {
      dispatch(
        modifyCampaign({
          ...value,
          attachmentFile,
          prevBanners: selectedCampaign.banners,
          prevAttachment: selectedCampaign.attachment,
          id,
        })
      );
      return;
    }
    dispatch(createCampaign({ ...value, attachmentFile }));
  };

  return (
    <>
      {redirect}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">
            {isEditing ? editCampaignMessage : newCampaignMessage}
          </h1>
        </div>
      </section>
      <section className="section is-main-section">
        {isEditing && !selectedCampaign ? (
          <ClipLoader />
        ) : (
          <CampaignForm
            isEditing={isEditing}
            id={id}
            campaign={
              isEditing
                ? {
                    banners: [],
                    ...selectedCampaign,
                    prevBanners: selectedCampaign.banners || [],
                    prevAttachment: selectedCampaign.attachment,
                    validRange: {
                      startAt: selectedCampaign.startAt
                        ? moment(selectedCampaign.startAt).toDate()
                        : null,
                      endAt: selectedCampaign.endAt
                        ? moment(selectedCampaign.endAt).toDate()
                        : null,
                    },
                  }
                : {
                    title: '',
                    banners: [],
                    prevBanners: [],
                    prevAttachment: '',
                    validRange: {
                      startAt: moment().add(-1, 'd').toDate(),
                      endAt: moment().add(1, 'd').toDate(),
                    },
                  }
            }
            onSubmitHandler={onSubmitHandler}
            schema={schema}
          />
        )}
      </section>
    </>
  );
};

export default Campaign;
