import React from 'react';
import classNames from 'classnames';
import ctlab from 'assets/ctlab.png';
import classes from './Footer.module.scss';

const Footer = () => (
  <footer className="footer">
    <div className="container-fluid">
      <div className={classNames('level', classes.level)}>
        <div className="level-left">
          <div className="level-item">
            © {new Date().getFullYear()} <span>&nbsp; Ctlab Vietnam</span>
          </div>
        </div>
        <div className={classNames('level-right', classes.levelRight)}>
          <div className="level-item">
            <div className="logo">
              <a href="https://ctlab.vn">
                <img src={ctlab} alt="ctlab.vn" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
