import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';

import { firebaseError } from 'utils';
import firebase from 'firebase.js';
import { checkUserData, AUTH_UPDATE_USER_DATA } from './auth';
import {
  fetchCollection,
  fetchDocument,
  createDocument,
  documentRef,
} from '../api';

export const USERS_FETCH_DATA_INIT = createAction('USERS_FETCH_DATA_INIT');
export const USERS_FETCH_DATA_SUCCESS = createAction(
  'USERS_FETCH_DATA_SUCCESS'
);
export const USERS_FETCH_DATA_FAIL = createAction('USERS_FETCH_DATA_FAIL');

export const USERS_DELETE_USER_INIT = createAction('USERS_DELETE_USER_INIT');
export const USERS_DELETE_USER_SUCCESS = createAction(
  'USERS_DELETE_USER_SUCCESS'
);
export const USERS_DELETE_USER_FAIL = createAction('USERS_DELETE_USER_FAIL');

export const USERS_CREATE_USER_INIT = createAction('USERS_CREATE_USER_INIT');
export const USERS_CREATE_USER_SUCCESS = createAction(
  'USERS_CREATE_USER_SUCCESS'
);
export const USERS_CREATE_USER_FAIL = createAction('USERS_CREATE_USER_FAIL');

export const USERS_MODIFY_USER_INIT = createAction('USERS_MODIFY_USER_INIT');
export const USERS_MODIFY_USER_SUCCESS = createAction(
  'USERS_MODIFY_USER_SUCCESS'
);
export const USERS_MODIFY_USER_FAIL = createAction('USERS_MODIFY_USER_FAIL');

export const USERS_RESET_PASSWORD_USER_INIT = createAction('USERS_RESET_PASSWORD_USER_INIT');
export const USERS_RESET_PASSWORD_USER_SUCCESS = createAction(
  'USERS_RESET_PASSWORD_USER_SUCCESS'
);
export const USERS_RESET_PASSWORD_USER_FAIL = createAction('USERS_RESET_PASSWORD_USER_FAIL');

export const USERS_CLEAN_UP = createAction('USERS_CLEAN_UP');

export const USERS_CLEAR_DATA_LOGOUT = createAction('USERS_CLEAR_DATA_LOGOUT');

export const fetchUsers =
  (userId = '') =>
  async (dispatch, getState) => {
    dispatch(checkUserData());
    dispatch(USERS_FETCH_DATA_INIT());

    if (userId) {
      let user;
      try {
        user = await fetchDocument('users', userId);
      } catch (error) {
        toastr.error('', error);
        return dispatch(USERS_FETCH_DATA_FAIL({ error }));
      }

      if (!user) {
        const errorMessage = 'User not available';
        toastr.error('', errorMessage);
        return dispatch(USERS_FETCH_DATA_FAIL({ error: errorMessage }));
      }

      const users = getState().users.data;
      users.push(user);

      return dispatch(
        USERS_FETCH_DATA_SUCCESS({
          data: users,
        })
      );
    }

    let users;

    try {
      users = await fetchCollection('users');
    } catch (error) {
      toastr.error('', error);
      return dispatch(USERS_FETCH_DATA_FAIL({ error }));
    }

    return dispatch(
      USERS_FETCH_DATA_SUCCESS({
        data: users.filter((user) => user.email),
      })
    );
  };

export const deleteUser = (id) => async (dispatch, getState) => {
  dispatch(USERS_DELETE_USER_INIT());
  const { locale } = getState().preferences;
  const mUser = getState().users.data.find((_user) => _user.id === id);
  try {
    if (!mUser) {
      throw new Error('User does not exist');
    }
    const batch = firebase.firestore().batch();
    batch.delete(documentRef('users', id));
    if (mUser.authorityId) {
      batch.set(documentRef('authorities', mUser.authorityId), {members: firebase.firestore.FieldValue.arrayRemove(id)}, {merge: true});
    }
    // if (mUser && mUser.companyId) {
    // unassign previous company's user
    //   tasks.push(deleteDocument('companies', mUser.companyId));
    // }
    await batch.commit();
  } catch (error) {
    const errorMessage = firebaseError(error.code, locale);
    toastr.error('', errorMessage);
    return dispatch(
      USERS_DELETE_USER_FAIL({
        error: errorMessage,
      })
    );
  }

  toastr.success('', 'The user was deleted.');
  return dispatch(USERS_DELETE_USER_SUCCESS({ id }));
};

export const clearUsersDataLogout = () => (dispatch) => {
  dispatch(USERS_CLEAR_DATA_LOGOUT());
};

export const createUser =
  ({ name, email, location, role, password, companyId, authorityId, createdAt }) =>
  async (dispatch, getState) => {
    dispatch(USERS_CREATE_USER_INIT());
    const { locale } = getState().preferences;

    if (password.length < 8) {
      const errorMessage = 'Die Passwortlänge muss gleich oder größer als 8 Zeichen sein';
      toastr.error('', errorMessage);
      dispatch(
        USERS_CREATE_USER_FAIL({
          error: errorMessage,
        })
      );
      return;
    }
    let response;
    try {
        const httpsCreateUser = firebase
        .app()
        .functions("europe-west3")
        .httpsCallable('httpsCreateUser');

      response = await httpsCreateUser({ email, name, password, role });
    } catch (error) {
      const errorMessage = firebaseError(error.message, locale);
      toastr.error('', errorMessage);
      dispatch(
        USERS_CREATE_USER_FAIL({
          error: errorMessage,
        })
      );
    }
    if (!response) {
      const errorMessage = 'Something went wrong with httpsCreateUser';
      toastr.error('', errorMessage);
      dispatch(
        USERS_CREATE_USER_FAIL({
          error: errorMessage,
        })
      );
      return;
    }

    const { uid } = response.data;
    const tasks = [];

    let userData = { name, email, role, location, createdAt };
    if (companyId !== 'NONE') {
      const companyRef = firebase.firestore().doc(`companies/${companyId}`);
      const companySnap = await companyRef.get();
      if (companySnap.exists && companySnap.data()) {
        if (companySnap.data().userId) {
          // unassign previous company's user
          tasks.push(
            firebase.firestore().doc(`users/${companySnap.data().userId}`).set(
              {
                companyId: firebase.firestore.FieldValue.delete(),
              },
              {
                merge: true,
              }
            )
          );
        }
        // set company userId
        tasks.push(
          companyRef.set({ userId: uid }, { merge: true }).catch(() => {})
        );
        // set user companyId
        userData = { ...userData, companyId };
      }
    }
    if (authorityId !== 'NONE') {
      const authorityRef = firebase.firestore().doc(`authorities/${authorityId}`);
      const authoritySnap = await authorityRef.get();
      if (authoritySnap.exists && authoritySnap.data()) {
        // set authority userId
        tasks.push(
          authorityRef.set({ members: firebase.firestore.FieldValue.arrayUnion(uid) }, { merge: true })
        );
        // set user companyId
        userData = { ...userData, authorityId };
      }
    }

    try {
      const createUserDbTask = createDocument('users', uid, userData);

      // const actionCodeSettings = {
      //   url: process.env.REACT_APP_LOGIN_PAGE_URL,
      //   handleCodeInApp: true,
      //   dynamicLinkDomain: 'unsereregion.page.link',
      // };

      // const sendSignInLinkToEmailTask = firebase
      //   .auth()
      //   .sendSignInLinkToEmail(email, actionCodeSettings);
      await Promise.all([
        createUserDbTask,
        ...tasks
      ]);
      toastr.success('', 'User created successfully');
      dispatch(USERS_CREATE_USER_SUCCESS({ user: response.data }));
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      dispatch(
        USERS_CREATE_USER_FAIL({
          error: errorMessage,
        })
      );
    }
};

export const modifyUser =
  ({
    name,
    location,
    companyId,
    prevCompanyId,
    role,
    authorityId,
    prevAuthorityId,
    createdAt,
    id,
    isEditing,
    isProfile,
  }) =>
  async (dispatch, getState) => {
    dispatch(USERS_MODIFY_USER_INIT());
    const { locale } = getState().preferences;
    const user = isProfile
      ? getState().auth.userData
      : getState().users.data.find((thisUser) => thisUser.id === id);

    let userData = {
      name,
      role,
      companyId: null,
      authorityId: null,
      location,
      createdAt
    };

    try {
      const batch = firebase.firestore().batch();
      if (typeof prevCompanyId === 'string' && prevCompanyId !== companyId) {
        batch.set(documentRef('companies', prevCompanyId), { userId: firebase.firestore.FieldValue.delete() }, { merge: true });
      }
      if (companyId !== 'NONE') {
        batch.set(documentRef('companies', companyId), { userId: id }, { merge: true });

        // set user company
        userData = {
          ...userData,
          companyId,
        };
      }
      if (typeof prevAuthorityId === 'string' && prevAuthorityId !== authorityId) {
        batch.set(documentRef('authorities', prevAuthorityId), { members: firebase.firestore.FieldValue.arrayRemove(user.id) }, { merge: true });
      }
      if (authorityId !== 'NONE') {
        batch.set(
          documentRef('authorities', authorityId),
          { members: firebase.firestore.FieldValue.arrayUnion(user.id) },
          { merge: true }
        );
        userData = {
          ...userData,
          authorityId,
        };
      }
      
      batch.set(documentRef('users', id), userData, {merge: true});
      // already dne claim role in onUserUpdate function
      // const setUserRole = firebase
      //   .functions()
      //   .httpsCallable('httpsSetUserRole');
      // await setUserRole({userId: id, role});
      await batch.commit();
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        USERS_MODIFY_USER_FAIL({
          error: errorMessage,
        })
      );
    }

    const { uid } = firebase.auth().currentUser;

    if (id === uid) {
      dispatch(AUTH_UPDATE_USER_DATA({ ...userData, id }));
    }

    if (isProfile) {
      toastr.success('', 'Profile updated successfully');
    } else if (isEditing) {
      toastr.success('', 'User updated successfully');
    }

    return dispatch(USERS_MODIFY_USER_SUCCESS({ user: userData, id }));
  };

export const resetPasswordUser =
  ({
    id,
    password,
    confirmPassword
  }) =>
  async (dispatch, getState) => {
    dispatch(USERS_RESET_PASSWORD_USER_INIT());
    const { locale } = getState().preferences;
    if (password !== confirmPassword || password.length < 8) {
      const message = 'Das Passwort stimmt nicht überein oder die Passwortlänge beträgt weniger als 8 Zeichen';
      toastr.error('', message);
      dispatch(
        USERS_RESET_PASSWORD_USER_FAIL({
          error: message,
        })
      );
      return;
    }
    try {
      await firebase.app().functions("europe-west3").httpsCallable('httpsResetUser')({
        userId: id,
        password
      });
      dispatch(
        USERS_RESET_PASSWORD_USER_SUCCESS()
      );
      toastr.success('', 'Reset user password successfully');
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      dispatch(
        USERS_RESET_PASSWORD_USER_FAIL({
          error: errorMessage,
        })
      );
    }
  };

export const usersCleanUp = () => (dispatch) => dispatch(USERS_CLEAN_UP());
