import React, { useMemo } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as yup from 'yup';

import { resetPasswordUser } from 'state/actions/users';
import paths from 'pages/Router/paths';
import { useFormatMessage } from 'hooks';
import UserFormResetPassword from 'components/UserFormResetPassword';

const schema = yup.object().shape({
  password: yup.string().required(),
});

const UserResetPassword = () => {
  const { id } = useParams();

  const isEditing = useMemo(() => !!id, [id]);

  const { success } = useSelector(
    (state) => ({
      success: state.users.success,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const redirect = success && (
    <Redirect to={paths.USERS} />
  );

  const onSubmitHandler = (value) => {
    dispatch(resetPasswordUser({...value, id}));
  };

  return (
    <>
      {redirect}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">
            {useFormatMessage('User.resetUserPassword')}
          </h1>
        </div>
      </section>
      <section className="section is-main-section">
        <UserFormResetPassword
          isEditing={isEditing}
          user={{
            id,
            password: '',
            confirmPassword: ''
          }}
          onSubmitHandler={onSubmitHandler}
          schema={schema}
        />
      </section>
    </>
  );
};

export default UserResetPassword;
