/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup';
import paths from 'pages/Router/paths';
import { useFormatMessage } from 'hooks';
import Select from '../Select';
import './AuthorityForm.scss';

const AuthorityForm = ({
  authority,
  users,
  userLoading,
  isEditing,
  onSubmitHandler,
  schema,
}) => {
  const { loading } = useSelector(
    (state) => ({
      loading: state.authorities.loading,
      success: state.authorities.success,
    }),
    shallowEqual
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: authority,
    resolver: yupResolver(schema),
  });

  const goBackMessage = useFormatMessage('Authorities.goBack');
  const pickAnotherFileMessage = useFormatMessage('UserForm.pickAnotherFile');
  const logoUrl =
    watch('logoFile') && watch('logoFile')[0]
      ? URL.createObjectURL(watch('logoFile')[0])
      : authority.logo;
  setValue('logo', logoUrl);
  const coverUrl =
    watch('coverFile') && watch('coverFile')[0]
      ? URL.createObjectURL(watch('coverFile')[0])
      : authority.cover;
  setValue('cover', coverUrl);

  const selectedMembers =
    (watch('members') &&
      Array.isArray(users) &&
      users.length > 0 &&
      watch('members').map((id) => {
        const usIndex = users.findIndex((us) => us.id === id);
        return users[usIndex] || 'NOT_FOUND';
      })) ||
    [];

  /* eslint-disable */
  return (
    <>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <div className="tile is-ancestor">
          <div className="tile is-parent">
            <div className="card tile is-child">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-badge-account-horizontal default" />
                  </span>
                  {useFormatMessage('Authorities.info')}
                </p>
              </header>
              <div className="card-content">
                {/* name */}
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('Authorities.name')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          {...register('name')}
                          id="name"
                          className={classNames('input', {
                            'is-danger': errors.name,
                          })}
                          type="text"
                          placeholder={useFormatMessage('Authorities.name')}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* phone */}
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('Authorities.phone')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          {...register('phone')}
                          id="phone"
                          className={classNames('input', {
                            'is-danger': errors.phone,
                          })}
                          type="text"
                          placeholder={useFormatMessage('Authorities.phone')}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* logo */}
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('AuthorityForm.logo')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <div className="is-horizontal">
                          <input
                            className="input"
                            type="url"
                            readOnly="readOnly"
                            className={classNames('input', {
                              'is-danger': errors.logo,
                            })}
                            value={(watch('logoFile') &&
                                watch('logoFile')[0]?.name) ||
                              authority.cover}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="file">
                      <label className="file-label">
                        <input
                          className="file-input"
                          type="file"
                          {...register('logoFile')}
                        />
                        <span className="file-cta">
                          <span className="file-icon">
                            <i className="mdi mdi-upload" />
                          </span>
                          <span className="file-label">
                            {watch('logoFile') && watch('logoFile')[0]
                              ? pickAnotherFileMessage
                              : useFormatMessage('UserForm.pickFile')}
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>

                {/* cover */}
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('AuthorityForm.cover')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <div className="is-horizontal">
                          <input
                            className="input"
                            type="url"
                            readOnly="readOnly"
                            className={classNames('input', {
                              'is-danger': errors.cover,
                            })}
                            value={
                              (watch('coverFile') &&
                                watch('coverFile')[0]?.name) ||
                              authority.cover
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="file">
                      <label className="file-label">
                        <input
                          className="file-input"
                          type="file"
                          {...register('coverFile')}
                        />
                        <span className="file-cta">
                          <span className="file-icon">
                            <i className="mdi mdi-upload" />
                          </span>
                          <span className="file-label">
                            {watch('coverFile') && watch('coverFile')[0]
                              ? pickAnotherFileMessage
                              : useFormatMessage('UserForm.pickFile')}
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>

                {/* address */}
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('Authorities.address')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          {...register('address')}
                          id="address"
                          className={classNames('input', {
                            'is-danger': errors.address,
                          })}
                          type="text"
                          placeholder={useFormatMessage(
                            'Authorities.address'
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* working hours */}
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('Authorities.workingHours')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          {...register('workingHours')}
                          id="workingHours"
                          className={classNames('input', {
                            'is-danger': errors.workingHours,
                          })}
                          type="text"
                          placeholder={useFormatMessage(
                            'Authorities.workingHours'
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* link1 */}
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('Authorities.link1')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          {...register('link1')}
                          id="link1"
                          className={classNames('input', {
                            'is-danger': errors.link1,
                          })}
                          type="link1"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* link2 */}
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('Authorities.link2')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          {...register('link2')}
                          id="link2"
                          className={classNames('input', {
                            'is-danger': errors.link2,
                          })}
                          type="link2"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* link3 */}
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('Authorities.link3')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          {...register('link3')}
                          id="link3"
                          className={classNames('input', {
                            'is-danger': errors.link3,
                          })}
                          type="link3"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* members */}
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('Authorities.editors')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="select is-multiple">
                      <Select
                        name="members"
                        label={useFormatMessage('Authorities.editors')}
                        multiple
                        loading={userLoading}
                        register={register}
                        options={users}
                      />
                    </div>
                  </div>
                </div>

                <hr />

                <div className="field is-horizontal">
                  <div className="field-label" />
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        <div className="control">
                          <button
                            type="submit"
                            className={`button is-primary ${
                              loading && 'is-loading'
                            }`}
                          >
                            <span>{useFormatMessage('Authorities.submit')}</span>
                          </button>
                        </div>
                        <Link to={paths.AUTHORITIES} className="button">
                          {goBackMessage}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Preview */}
          <div className="tile is-parent preview">
            <div className="card tile is-child">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-badge-account-horizontal default" />
                  </span>
                  {useFormatMessage('Authorities.preview')}
                </p>
              </header>
              <div className="card-content">
                {isEditing && (
                  <div className="field">
                    <label className="label">
                      {useFormatMessage('Authorities.id')}
                    </label>
                    <div className="control">
                      <input
                        readOnly="readOnly"
                        className="input is-static"
                        data-testid="id"
                        value={watch('id')}
                      />
                    </div>
                  </div>
                )}
                <div className="field">
                  <label className="label">
                    {useFormatMessage('Authorities.name')}
                  </label>
                  <div className="control">
                    <input
                      readOnly="readOnly"
                      className="input is-static"
                      data-testid="name"
                      value={watch('name')}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label">
                    {useFormatMessage('Authorities.phone')}
                  </label>
                  <div className="control">
                    <input
                      readOnly="readOnly"
                      className="input is-static"
                      data-testid="phone"
                      value={watch('phone')}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label">
                    {useFormatMessage('Authorities.address')}
                  </label>
                  <div className="control">
                    <input
                      readOnly="readOnly"
                      className="input is-static"
                      data-testid="address"
                      value={watch('address')}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label">
                    {useFormatMessage('Authorities.workingHours')}
                  </label>
                  <div className="control">
                    <input
                      readOnly="readOnly"
                      className="input is-static"
                      data-testid="workingHours"
                      value={watch('workingHours')}
                    />
                  </div>
                </div>
                {logoUrl && (
                  <>
                    <div className="field">
                      <label className="label">
                        {useFormatMessage('AuthorityForm.logo')}
                      </label>
                      <div className="image is-64x64 has-max-width is-aligned-center">
                        <img src={logoUrl} alt="authority logo" />
                      </div>
                    </div>
                  </>
                )}
                {coverUrl && (
                  <>
                    <div className="field">
                      <label className="label">
                        {useFormatMessage('AuthorityForm.cover')}
                      </label>
                      <div className="image is-480x320 has-max-width is-aligned-center">
                        <img src={coverUrl} alt="authority cover" />
                      </div>
                    </div>
                  </>
                )}
                <div className="field">
                  <label className="label">
                    {useFormatMessage('Authorities.link1')}
                  </label>
                  <div className="control">
                    <input
                      readOnly="readOnly"
                      className="input is-static"
                      data-testid="link1"
                      value={watch('link1')}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label">
                    {useFormatMessage('Authorities.link2')}
                  </label>
                  <div className="control">
                    <input
                      readOnly="readOnly"
                      className="input is-static"
                      data-testid="link2"
                      value={watch('link2')}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label">
                    {useFormatMessage('Authorities.link3')}
                  </label>
                  <div className="control">
                    <input
                      readOnly="readOnly"
                      className="input is-static"
                      data-testid="link3"
                      value={watch('link3')}
                    />
                  </div>
                </div>
                <div className="field">
                <label className="label">
                  {useFormatMessage('Authorities.editors')}
                </label>
                <div className="field">
                  <div className="tags">
                    {selectedMembers.map((us) => (
                      <a key={us.id} href={`/users/${us.id}`} target="_blank" className="tag">
                        {us.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

// AuthorityForm.propTypes = {
//   authority: PropTypes.shape({
//     id: PropTypes.string,
//     name: PropTypes.string.isRequired,
//     displayName: PropTypes.string.isRequired,
//     contact: PropTypes.string.isRequired,
//     email: PropTypes.string.isRequired,
//     address: PropTypes.string.isRequired,
//     products: PropTypes.arrayOf(PropTypes.string.isRequired),
//   }).isRequired,
//   onSubmitHandler: PropTypes.func.isRequired,
//   // eslint-disable-next-line react/forbid-prop-types
//   schema: PropTypes.object.isRequired,
//   isEditing: PropTypes.bool,
// };

// AuthorityForm.defaultProps = {
//   isEditing: false,
// };

export default AuthorityForm;
