import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useFormatMessage } from 'hooks';
import Table from 'components/Table';
import { fetchCampaigns, deleteCampaign } from 'state/actions/campaigns';
import paths from 'pages/Router/paths';
import classNames from 'classnames';
import ClipLoader from 'react-spinners/ClipLoader';
import ConfirmationModal from 'components/ConfirmationModal';
import classes from './Campaigns.module.scss';


const Campaigns = () => {
  const { campaignList, error, loading, deleted } = useSelector(
    (state) => ({
      campaignList: state.campaigns.data,
      error: state.campaigns.error,
      loading: state.campaigns.loading,
      deleted: state.campaigns.deleted,
    }),
    shallowEqual
  );

  const [deleteModal, setDeleteModal] = useState({
    campaignId: null,
    isOpen: false,
  });

  const dispatch = useDispatch();

  const [search, setSearch] = useState('');

  useEffect(() => {
    dispatch(fetchCampaigns());
  }, [dispatch]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal({
        campaignId: null,
        isOpen: false,
      });
    }
  }, [deleted, loading]);

  const onRemoveButtonClickHandler = (campaignId) => {
    setDeleteModal((prevState) => ({
      campaignId,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ campaignId: null, isOpen: false });
  };

  const onDeleteCampaignHandler = () => {
    dispatch(deleteCampaign(deleteModal.campaignId));
  };

  const columns = [
    {
      Header: useFormatMessage('CampaignsForm.title'),
      accessor: 'title',
    },
    {
      Header: useFormatMessage('CampaignsForm.range'),
      accessor: 'range',
      Cell: ({ row }) =>
        `${moment(row.original.startAt).format('DD/MM/YYYY')} - ${moment(
          row.original.endAt
        ).format('DD/MM/YYYY')}`,
    },
    {
      Header: useFormatMessage('Campaigns.activated'),
      accessor: 'activated',
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {row.original.activated ? (
            <span className="icon has-text-success">
              <i className="mdi mdi-check" />
            </span>
          ) : (
            <span className="icon has-text-danger">
              <i className="mdi mdi-close" />
            </span>
          )}
        </small>
      ),
    },
    {
      Header: useFormatMessage('CampaignsForm.actions'),
      id: 'actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <div className="buttons is-right">
          <Link
            to={`/campaigns/${row.original.id}`}
            className="button is-small is-primary"
          >
            <span className="icon is-small">
              <i className="mdi mdi-square-edit-outline" />
            </span>
          </Link>

          <button
            type="button"
            className="button is-small is-danger"
            onClick={() => onRemoveButtonClickHandler(row.original.id)}
          >
            <span className="icon is-small">
              <i className="mdi mdi-trash-can" />
            </span>
          </button>
        </div>
      ),
      disableSortBy: true,
    },
  ];

  const data = search
    ? campaignList.filter((el) => {
        const clonedElem = { ...el };
        delete clonedElem.createdAt;
        delete clonedElem.updatedAt;
        delete clonedElem.createdBy;
        delete clonedElem.updatedBy;
        return Object.values(clonedElem).some((field) => {
          const key = field instanceof Date ? field.toDateString() : `${field}`;
          return key.toLowerCase().includes(search.toLowerCase());
        });
      })
    : campaignList;

  const deleteMessage = useFormatMessage('Categories.delete');

  const confirmMessage = useFormatMessage('Categories.confirm');

  const permDeleteMessage = useFormatMessage('Campaigns.confirm');

  const cancelMessage = useFormatMessage('Categories.cancel');

  return (
    <>
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteCampaignHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="title">
                  {useFormatMessage('Campaigns.title')}
                </h1>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <Link to={paths.ADD_CAMPAIGN} className="button">
                  {useFormatMessage('Campaigns.newCampaign')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section is-main-section">
        <div className="card has-table has-mobile-sort-spaced">
          <header className="card-header">
            <p className={classNames('card-header-title', classes.tableHeader)}>
              <span>{useFormatMessage('Categories.search')}</span>
              <input
                type="text"
                className="input"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </p>
          </header>
          <div className="b-table">
            {loading ? <ClipLoader /> : <Table columns={columns} data={data} />}
            {error && 'Show error'}
          </div>
        </div>
      </section>
    </>
  );
};

export default Campaigns;
