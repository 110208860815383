import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import bulmaCalendar from 'bulma-calendar';
import { useSelector } from 'react-redux';

const Calendar = ({ startDate, endDate, isRange = true, onChange, id = 'calendarPicker' }) => {
  const { locale } = useSelector((state) => ({
    locale: state.preferences.locale,
  }));
  const attachRef = useRef();

  useEffect(() => {
    if (!attachRef.current) {
      attachRef.current = bulmaCalendar.attach(`#${id}`, {
        type: 'date',
        lang: locale,
        isRange,
        allowSameDayRange: true,
        displayMode: 'default',
        showTodayButton: true,
        labelFrom: 'From',
        labelTo: 'To',
        startDate,
        endDate
      });
    }
    const element = document.querySelector(`#${id}`);
    if (element && element.bulmaCalendar) {
      element.bulmaCalendar.on('select', (datepicker) => {
        const [_startDate, _endDate] = datepicker.data
          .value()
          .split('-')
          .map((val) => new Date(val.trim()));
        onChange({ startDate: _startDate, endDate: _endDate });
      });
      element.bulmaCalendar.on('clear', () => {
        onChange({ startDate: undefined, endDate: undefined });
      });
    }
    return () => {
      if (element && element.bulmaCalendar) {
        element.bulmaCalendar.removeListeners('select');
        element.bulmaCalendar.removeListeners('clear');
      }
    };
  }, [locale, startDate, endDate, isRange, onChange, id]);

  return <input id={id} />;
};

Calendar.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  isRange: PropTypes.bool,
};
export default Calendar;
