import { createIntl, createIntlCache } from 'react-intl';
import firebase from 'firebase.js';
import english from 'languages/en.json';
import switzerland from 'languages/de.json';
import en from 'assets/en.png';
import de from 'assets/de.png';

export const FIREBASE_RESPONSE = {
  EMAIL_IN_USE: 'auth/email-already-exists',
  EMAIL_INVALID: 'auth/invalid-email',
  EMAIL_NOT_FOUND: 'auth/user-not-found',
  PASSWORD_INVALID: 'auth/wrong-password',
  USER_DISABLED: 'auth/user-disabled',
  INVALID_ADMIN: 'auth/invalid-admin',
  TOO_MANY_REQUESTS: 'auth/too-many-requests',
  EXPIRED_ACTION_CODE: 'auth/expired-action-code',
  INVALID_ACTION_CODE: 'auth/invalid-action-code',
  QUOTA_EXCEEDED_STORAGE: 'storage/quota-exceeded',
  UNAUTHENTICATED_STORAGE: 'storage/unauthenticated',
  UNAUTHORIZED_STORAGE: 'storage/unauthorized',
};

export const messages = {
  en: english,
  de: switzerland,
};

const getIntlContext = (locale) => {
  const cache = createIntlCache();
  return createIntl(
    {
      locale,
      messages: messages[locale],
    },
    cache
  );
};

export const firebaseError = (error, locale) => {
  const intl = getIntlContext(locale);
  return intl.formatMessage({
    id: error,
    defaultMessage: messages[locale]['utils.default'],
  });
};

export const actionMessage = (key, locale) => {
  const intl = getIntlContext(locale);
  return intl.formatMessage({
    id: key,
    defaultMessage: messages[locale]['utils.default'],
  });
};

export const availableLocales = Object.keys(messages);

export const browserLocale = navigator.language.split(/[-_]/)[0];

export const flags = {
  en,
  de,
};

export const uiConfig = (onSignInSuccessHandler, onSignInFailHandler) => ({
  callbacks: {
    signInSuccessWithAuthResult: onSignInSuccessHandler,
    signInFailure: onSignInFailHandler,
  },
  signInFlow: 'popup',
  signInSuccessUrl: '/home',
  signInOptions: [
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      fullLabel: 'Continue with Google',
      scopes: [
        'https://www.googleapis.com/auth/user.addresses.read',
        'https://www.googleapis.com/auth/userinfo.email',
      ],
    },
    {
      provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      fullLabel: 'Continue with Facebook',
      scopes: ['email'],
    },
    { provider: 'microsoft.com', fullLabel: 'Continue with Microsoft' },
  ],
});

export const slugify = (_text, separator) => {
  let text = _text.toString().toLowerCase().trim();

  const sets = [
    { to: 'a', from: '[ÀÁÂÃÅÆĀĂĄẠẢẤẦẨẪẬẮẰẲẴẶ]' },
    { to: 'ae', from: '[Ä]' },
    { to: 'c', from: '[ÇĆĈČ]' },
    { to: 'd', from: '[ÐĎĐÞ]' },
    { to: 'e', from: '[ÈÉÊËĒĔĖĘĚẸẺẼẾỀỂỄỆ]' },
    { to: 'g', from: '[ĜĞĢǴ]' },
    { to: 'h', from: '[ĤḦ]' },
    { to: 'i', from: '[ÌÍÎÏĨĪĮİỈỊ]' },
    { to: 'j', from: '[Ĵ]' },
    { to: 'ij', from: '[Ĳ]' },
    { to: 'k', from: '[Ķ]' },
    { to: 'l', from: '[ĹĻĽŁ]' },
    { to: 'm', from: '[Ḿ]' },
    { to: 'n', from: '[ÑŃŅŇ]' },
    { to: 'o', from: '[ÒÓÔÕØŌŎŐỌỎỐỒỔỖỘỚỜỞỠỢǪǬƠ]' },
    { to: 'oe', from: '[ŒÖ]' },
    { to: 'p', from: '[ṕ]' },
    { to: 'r', from: '[ŔŖŘ]' },
    { to: 's', from: '[ŚŜŞŠ]' },
    { to: 'ss', from: '[ß]' },
    { to: 't', from: '[ŢŤ]' },
    { to: 'u', from: '[ÙÚÛŨŪŬŮŰŲỤỦỨỪỬỮỰƯ]' },
    { to: 'ue', from: '[Ü]' },
    { to: 'w', from: '[ẂŴẀẄ]' },
    { to: 'x', from: '[ẍ]' },
    { to: 'y', from: '[ÝŶŸỲỴỶỸ]' },
    { to: 'z', from: '[ŹŻŽ]' },
    { to: '-', from: "[·/_,:;']" },
  ];

  sets.forEach((set) => {
    text = text.replace(new RegExp(set.from, 'gi'), set.to);
  });

  text = text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w]+/g, '') // Remove all non-word chars
    .replace(/-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text

  if (typeof separator !== 'undefined' && separator !== '-') {
    text = text.replace(/-/g, separator);
  }

  return text;
};

export const uploadImages = async ({ collection, documentId, files = [] }) => {
  const storageRef = firebase.storage().ref();
  const paths = [];
  const tasks = [];

  files.forEach((file) => {
    if (file) {
      const fileExtension = file.name.split('.').pop();
      let fileName = slugify(file.name.split('.').slice(0, 1).pop(), '-');
      fileName = `${documentId}-${fileName}.${fileExtension}`;
      const bucketUrl = `${process.env.REACT_APP_FIRE_BASE_STORAGE_API}`;
      const contentType = fileExtension === 'pdf' ? 'application/pdf' : `image/${fileExtension}`;

      // paths.push(`${bucketUrl}/o/${collection}%2F${documentId}_200x200.${fileExtension}?alt=media`);
      paths.push(`${bucketUrl}/o/${collection}%2F${fileName}?alt=media`);
      tasks.push(storageRef.child(`${collection}/${fileName}`).put(file, { contentType }));
    } else {
      paths.push(false);
    }
  });

  await Promise.all(tasks);
  return paths;
};

export const deleteImages = async ({ collection, URLs = [] }) => {
  const tasks = [];
  URLs.forEach((url) => {
    if (url && url.includes('firebasestorage')) {
      const path = url
        .split(`${collection}%2F`)
        .pop()
        .split('?alt=media')
        .shift();
      tasks.push(
        firebase
          .storage()
          .ref(`${collection}/${path}`)
          .delete()
          .catch(() => {})
      );
    }
  });
  return Promise.all(tasks);
};

const mappingNameWithConnector = (cat, connector = '') => {
  const item = { id: cat.id, name: `${connector}${cat.name}` };
  if (cat.childNodes.length === 0) {
    return item;
  }
  return {
    ...item,
    childNodes: cat.childNodes.map((subCat) =>
      mappingNameWithConnector(subCat, `${connector}_`)
    ),
  };
};

const flatten = (arr, cat) => {
  arr.push({
    id: cat.id,
    name: cat.name,
  });
  if (cat.childNodes && cat.childNodes.length > 0) {
    // eslint-disable-next-line no-param-reassign
    arr.concat = cat.childNodes.reduce(flatten, arr);
  }
  return arr;
};

export const getCategoryTreeRecusively = (categories = [], currentId) => {
  const result = [];
  const filteredCategories = categories.filter((cat) => cat.id !== currentId);
  if (filteredCategories.length === 0) {
    return result;
  }
  const hashTable = {};
  filteredCategories.forEach((cat) => {
    hashTable[cat.id] = { ...cat, childNodes: [] };
  });
  const dataTree = [];
  filteredCategories.forEach((cat) => {
    if (typeof cat.parentCategory === 'string') {
      if (hashTable[cat.parentCategory]) {
        hashTable[cat.parentCategory].childNodes.push(hashTable[cat.id]);
      }
    } else dataTree.push(hashTable[cat.id]);
  });
  const mappedName = dataTree.map((cat) => mappingNameWithConnector(cat, ''));
  const flattened = mappedName.reduce(flatten, []);
  return flattened;
};
