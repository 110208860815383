import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import classNames from 'classnames';
import ClipLoader from 'react-spinners/ClipLoader';

import { useFormatMessage } from 'hooks';
import Table from 'components/Table';
import { fetchCompanies, deleteCompany } from 'state/actions/companies';
import paths from 'pages/Router/paths';
import ConfirmationModal from 'components/ConfirmationModal';
import classes from './Companies.module.scss';

const Companies = () => {
  const { companyList, error, loading, deleted } = useSelector(
    (state) => ({
      companyList: state.companies.data,
      error: state.companies.error,
      loading: state.companies.loading,
      deleted: state.companies.deleted,
    }),
    shallowEqual
  );

  const [deleteModal, setDeleteModal] = useState({
    companyId: null,
    isOpen: false,
  });

  const dispatch = useDispatch();

  const [search, setSearch] = useState('');

  useEffect(() => {
    dispatch(fetchCompanies());
  }, [dispatch]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal({
        companyId: null,
        isOpen: false,
      });
    }
  }, [deleted, loading]);

  const onRemoveButtonClickHandler = (companyId) => {
    setDeleteModal((prevState) => ({
      companyId,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ companyId: null, isOpen: false });
  };

  const onDeleteCompanyHandler = () => {
    dispatch(deleteCompany(deleteModal.companyId));
  };

  const columns = [
    {
      Header: useFormatMessage('Companies.no'),
      accessor: 'index',
      Cell: ({ row }) => row.index + 1,
    },
    {
      Header: useFormatMessage('Companies.name'),
      accessor: 'name',
      Cell: ({ row }) => (
        <Link to={`/companies/${row.original.id}`}>{row.original.name}</Link>
      ),
    },
    {
      Header: useFormatMessage('Companies.products'),
      id: 'productActions',
      accessor: 'productActions',
      Cell: ({ row }) => (
        <div className="buttons is-center">
          <Link
            to={`/companies/${row.original.id}/products`}
            className="button is-small is-primary"
          >
            <span className="icon is-small">
              <i className="mdi mdi-square-edit-outline" />
            </span>
          </Link>
        </div>
      ),
    },
    {
      Header: useFormatMessage('Companies.shoppingWindow'),
      id: 'shoppingWindow',
      accessor: 'shoppingWindow',
      Cell: ({ row }) => (
        <div className="buttons is-center">
          <Link
            to={`/shoppingwindow/${row.original.id}`}
            className="button is-small is-primary"
          >
            <span className="icon is-small">
              <i className="mdi mdi-square-edit-outline" />
            </span>
          </Link>
        </div>
      ),
    },
    {
      Header: '',
      id: 'actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <div className="buttons is-right">
          <Link
            to={`/companies/${row.original.id}`}
            className="button is-small is-primary"
          >
            <span className="icon is-small">
              <i className="mdi mdi-square-edit-outline" />
            </span>
          </Link>

          <button
            type="button"
            className="button is-small is-danger"
            onClick={() => onRemoveButtonClickHandler(row.original.id)}
          >
            <span className="icon is-small">
              <i className="mdi mdi-trash-can" />
            </span>
          </button>
        </div>
      ),
      disableSortBy: true,
    },
    {
      Header: useFormatMessage('Companies.contractStatus'),
      accessor: 'validTo',
      Cell: ({ row }) => {
        const { isShoppingWindowContractValid, validTo } = row.original;
        if (
          typeof isShoppingWindowContractValid === 'boolean' &&
          !isShoppingWindowContractValid &&
          validTo
        ) {
          return (
            <span className="has-text-danger has-text-weight-semibold">
              läuft aus
            </span>
          );
        }
        const isAlmostExires =
          validTo &&
          moment(validTo).add(-30, 'days').isSameOrBefore(new Date());
        if (isAlmostExires) {
          return (
            <span className="has-text-danger has-text-weight-semibold">
              läuft aus
            </span>
          );
        }
        if (
          typeof isShoppingWindowContractValid === 'boolean' &&
          isShoppingWindowContractValid
        ) {
          return (
            <span className="has-text-success has-text-weight-semibold">
              aktiv
            </span>
          );
        }
        return '';
      },
    },
  ];

  const data = search
    ? companyList.filter((el) => {
        const clonedElem = { ...el };
        delete clonedElem.createdAt;
        delete clonedElem.address;
        delete clonedElem.contact;
        delete clonedElem.updatedAt;
        delete clonedElem.createdBy;
        delete clonedElem.updatedBy;
        return Object.values(clonedElem).some((field) => {
          const key = field instanceof Date ? field.toDateString() : `${field}`;
          return key.toLowerCase().includes(search.toLowerCase());
        });
      })
    : companyList;

  const deleteMessage = useFormatMessage('Categories.delete');

  const confirmMessage = useFormatMessage('Categories.confirm');

  const permDeleteMessage = useFormatMessage('Categories.permDelete');

  const cancelMessage = useFormatMessage('Categories.cancel');

  return (
    <>
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteCompanyHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="title">
                  {useFormatMessage('Companies.companies')}
                </h1>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <Link to={paths.ADD_COMPANY} className="button">
                  {useFormatMessage('Companies.newCompany')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section is-main-section">
        <div className="card has-table has-mobile-sort-spaced">
          <header className="card-header">
            <p className={classNames('card-header-title', classes.tableHeader)}>
              <span>{useFormatMessage('Categories.search')}</span>
              <input
                type="text"
                className="input"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </p>
          </header>
          <div className="b-table">
            {loading ? <ClipLoader /> : <Table columns={columns} data={data} />}
            {error && 'Show error'}
          </div>
        </div>
      </section>
    </>
  );
};

export default Companies;
