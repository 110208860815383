/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup';

import paths from 'pages/Router/paths';
import { useFormatMessage } from 'hooks';
import ErrorMessage from 'components/ErrorMessage';

import './UserFormResetPassword.scss';

const UserFormResetPassword = ({
  user,
  onSubmitHandler,
  schema,
}) => {
  const { loading } = useSelector(
    (state) => ({
      loading: state.users.loading,
      success: state.users.success,
    }),
    shallowEqual
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: user,
    resolver: yupResolver(schema),
    shouldFocusError: true
  });

  // const invalidEmailMessage = useFormatMessage('UserForm.invalidEmail');
  const goBackMessage = useFormatMessage('UserForm.goBack');
  // const emailMessage = useFormatMessage('UserForm.email');
  // const adminMessage = useFormatMessage('UserForm.admin');

  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account-edit default" />
                </span>
                {useFormatMessage('UserForm.userInfo')}
              </p>
            </header>
            <div className="card-content">
              <form onSubmit={handleSubmit(onSubmitHandler)}>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('UserForm.password')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          {...register('password')}
                          id="password"
                          className={classNames('input', {
                            'is-danger': errors.password,
                          })}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {errors.password && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('UserForm.confirmPassword')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          {...register('confirmPassword')}
                          id="confirmPassword"
                          className={classNames('input', {
                            'is-danger': errors.confirmPassword,
                          })}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {errors.confirmPassword && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}
                <hr />
                <div className="field is-horizontal">
                  <div className="field-label" />
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        <div className="control">
                          <button
                            type="submit"
                            className={`button is-primary ${loading && 'is-loading'
                              }`}
                          >
                            <span>{useFormatMessage('UserForm.submit')}</span>
                          </button>
                        </div>
                        <Link to={paths.USERS} className="button">
                          {goBackMessage}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

UserFormResetPassword.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    password: PropTypes.string.isRequired,
    confirmPassword: PropTypes.string.isRequired,
  }).isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  schema: PropTypes.object.isRequired,
};

export default UserFormResetPassword;
