import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

/* eslint-disable */
const Select = ({ register, loading, name, options, ...rest }) => {
  if (loading) {
    return <ClipLoader />;
  }

  return (
    <select {...register(name)} {...rest}>
      {options.map((obj, index) => (
        <option key={`${obj.id}${index}`} value={obj.id}>
          {obj.name}
        </option>
      ))}
    </select>
  );
};
export default Select;
