import { createReducer } from 'redux-act';

import {
  AUTHORITY_POSTS_FETCH_DATA_INIT,
  AUTHORITY_POSTS_FETCH_DATA_SUCCESS,
  AUTHORITY_POSTS_FETCH_DATA_FAIL,
  AUTHORITY_POSTS_DELETE_POST_INIT,
  AUTHORITY_POSTS_DELETE_POST_SUCCESS,
  AUTHORITY_POSTS_DELETE_POST_FAIL,
  AUTHORITY_POSTS_CREATE_POST_INIT,
  AUTHORITY_POSTS_CREATE_POST_SUCCESS,
  AUTHORITY_POSTS_CREATE_POST_FAIL,
  AUTHORITY_POSTS_MODIFY_POST_INIT,
  AUTHORITY_POSTS_MODIFY_POST_SUCCESS,
  AUTHORITY_POSTS_MODIFY_POST_FAIL,
  AUTHORITY_POSTS_CLEAN_UP,
  AUTHORITY_SEARCHING_KEYWORD,
  AUTHORITY_SEARCHING_DATE_PERIOD,
  AUTHORITY_POSTS_DELETE_IMAGE_INIT,
  AUTHORITY_POSTS_DELETE_IMAGE_SUCCESS,
  AUTHORITY_POSTS_DELETE_IMAGE_FAIL,
} from 'state/actions/authorityPosts';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
  deletingImage: false,
  deletedImage: false,
  keyword: '',
  startDate: null,
  endDate: null,
};

export const authorityPostsReducer = createReducer(
  {
    [AUTHORITY_POSTS_CLEAN_UP]: () => initialState,
    [AUTHORITY_POSTS_FETCH_DATA_INIT]: (state) => ({
      ...initialState,
      ...state
    }),
    [AUTHORITY_POSTS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.data,
      loading: false,
      error: null,
    }),
    [AUTHORITY_POSTS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [AUTHORITY_POSTS_DELETE_POST_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [AUTHORITY_POSTS_DELETE_IMAGE_INIT]: (state) => ({
      ...state,
      deletingImage: true,
    }),
    [AUTHORITY_POSTS_DELETE_IMAGE_SUCCESS]: (state) => ({
      ...state,
      deletingImage: false,
    }),
    [AUTHORITY_POSTS_DELETE_IMAGE_FAIL]: (state, payload) => ({
      ...state,
      deletingImage: false,
      error: payload.error,
    }),
    [AUTHORITY_POSTS_DELETE_POST_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.filter((elem) => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [AUTHORITY_POSTS_DELETE_POST_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [AUTHORITY_POSTS_CREATE_POST_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [AUTHORITY_POSTS_CREATE_POST_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.concat(payload.post),
      loading: false,
      error: null,
      success: true,
    }),
    [AUTHORITY_POSTS_CREATE_POST_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [AUTHORITY_POSTS_MODIFY_POST_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [AUTHORITY_POSTS_MODIFY_POST_SUCCESS]: (state, payload) => ({
      ...state,
      data: !state.data
        ? []
        : state.data.map((elem) => {
            if (elem.id === payload.id) {
              return payload;
            }
            return elem;
          }),
      deletingImage: false,
      deletedImage: false,
      error: null,
    }),
    [AUTHORITY_POSTS_MODIFY_POST_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [AUTHORITY_SEARCHING_KEYWORD]: (state, payload = '') => ({
      ...state,
      keyword: payload,
    }),
    [AUTHORITY_SEARCHING_DATE_PERIOD]: (state, payload) => ({
      ...state,
      startDate: payload.startDate,
      endDate: payload.endDate,
    }),
  },
  initialState
);
