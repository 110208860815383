/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import classNames from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormatDate, useFormatMessage } from 'hooks';
import DatePicker from 'components/DatePicker';
import Select from 'components/Select';

import './ProductForm.scss';

const ProductForm = ({
  product,
  loadingCategory,
  categories,
  isEditing,
  onSubmitHandler,
  schema,
}) => {
  const { loading, success, companies } = useSelector(
    (state) => ({
      loading: state.products.loading,
      success: state.products.success,
      companies: state.companies.data,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm({
    defaultValues: { ...product },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (success) {
      setValue('preview', null);
      setValue('logo', null);
    }
  }, [dispatch, success, setValue]);

  const goBackMessage = useFormatMessage('ProductForm.goBack');
  const pickAnotherFileMessage = useFormatMessage('UserForm.pickAnotherFile');
  const logoUrl =
    watch('logoFile') && watch('logoFile')[0]
      ? URL.createObjectURL(watch('logoFile')[0])
      : product.logo;
  const previewUrl =
    watch('previewFile') && watch('previewFile')[0]
      ? URL.createObjectURL(watch('previewFile')[0])
      : product.preview;
  const selectedCategories =
    (watch('categories') &&
      Array.isArray(categories) &&
      categories.length > 0 &&
      watch('categories').map((id) => {
        const compIndex = categories.findIndex((comp) => comp.id === id);
        return categories[compIndex].name || 'NOT_FOUND';
      })) ||
    [];

  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-bag-checked default" />
                </span>
                {useFormatMessage('ProductForm.info')}
              </p>
            </header>
            <div className="card-content">
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                {/* company */}
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('Products.company')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="select">
                      <Select
                        name="companyId"
                        register={register}
                        options={companies}
                      />
                    </div>
                  </div>
                </div>
                {/* name */}
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ProductForm.name')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control is-expanded has-icons-left has-icons-right">
                        <input
                          {...register('name')}
                          id="name"
                          className={classNames('input', {
                            'is-danger': errors.name,
                          })}
                          type="text"
                          placeholder={useFormatMessage(
                            'ProductForm.placeholderName'
                          )}
                        />
                        <span className="icon is-small is-left">
                          <i className="mdi mdi-bag-checked" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* contract valid range */}
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ProductForm.validFrom')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <Controller
                          control={control}
                          name="validFrom"
                          render={({ field: { onChange, value, name } }) => (
                            <DatePicker
                              name={name}
                              onChange={(dateString) =>
                                onChange(new Date(dateString))
                              }
                              date={value}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <div className="control">
                        <Controller
                          control={control}
                          name="validTo"
                          render={({ field: { onChange, value, name } }) => (
                            <DatePicker
                              name={name}
                              onChange={(dateString) =>
                                onChange(new Date(dateString))
                              }
                              date={value}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* activated */}
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ProductForm.activated')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          {...register('activated')}
                          id="activated"
                          type="checkbox"
                          placeholder={useFormatMessage(
                            'ProductForm.placeholderAddress'
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* address */}
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ProductForm.address')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control is-expanded has-icons-left has-icons-right">
                        <input
                          {...register('address')}
                          id="address"
                          className={classNames('input', {
                            'is-danger': errors.address,
                          })}
                          type="text"
                          placeholder={useFormatMessage(
                            'ProductForm.placeholderAddress'
                          )}
                        />
                        <span className="icon is-small is-left">
                          <i className="mdi mdi-map-marker" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* phone */}
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ProductForm.phone')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control is-expanded has-icons-left has-icons-right">
                        <input
                          {...register('phone')}
                          id="phone"
                          className={classNames('input', {
                            'is-danger': errors.phone,
                          })}
                          type="text"
                        />
                        <span className="icon is-small is-left">
                          <i className="mdi mdi-phone" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* categories */}
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('Categories.categories')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="select is-multiple">
                      <Select
                        name="categories"
                        label={useFormatMessage('Categories.categories')}
                        multiple
                        loading={loadingCategory}
                        register={register}
                        options={categories}
                      />
                    </div>
                  </div>
                </div>

                {/* company url */}
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ProductForm.companyUrl')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control is-expanded has-icons-left has-icons-right">
                        <input
                          className="input"
                          type="url"
                          {...register('companyUrl')}
                          placeholder="https://company.example"
                        />
                        <span className="icon is-small is-left">
                          <i className="mdi mdi-earth" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* shopping url */}
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ProductForm.shoppingUrl')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control is-expanded has-icons-left has-icons-right">
                        <input
                          className="input"
                          type="url"
                          {...register('shoppingUrl')}
                          placeholder="https://shopping.example"
                        />
                        <span className="icon is-small is-left">
                          <i className="mdi mdi-earth" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* logo */}
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ProductForm.logo')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <div className="is-horizontal">
                          <input
                            className="input"
                            type="url"
                            readOnly="readOnly"
                            value={
                              (watch('logoFile') &&
                                watch('logoFile')[0]?.name) ||
                              product.logo ||
                              ''
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="file">
                      <label className="file-label">
                        <input
                          className="file-input"
                          type="file"
                          {...register('logoFile')}
                        />
                        <span className="file-cta">
                          <span className="file-icon">
                            <i className="mdi mdi-upload" />
                          </span>
                          <span className="file-label">
                            {watch('logoFile') && watch('logoFile')[0]
                              ? pickAnotherFileMessage
                              : useFormatMessage('UserForm.pickFile')}
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>

                {/* preview */}
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ProductForm.preview')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <div className="is-horizontal">
                          <input
                            className="input"
                            readOnly="readOnly"
                            value={
                              (watch('previewFile') &&
                                watch('previewFile')[0]?.name) ||
                              product.preview
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="file">
                      <label className="file-label">
                        <input
                          className="file-input"
                          type="file"
                          {...register('previewFile')}
                        />
                        <span className="file-cta">
                          <span className="file-icon">
                            <i className="mdi mdi-upload" />
                          </span>
                          <span className="file-label">
                            {watch('previewFile') && watch('previewFile')[0]
                              ? pickAnotherFileMessage
                              : useFormatMessage('UserForm.pickFile')}
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>

                {/* description */}
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ProductForm.description')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <textarea
                          {...register('description')}
                          id="description"
                          className={classNames('textarea', {
                            'is-danger': errors.description,
                          })}
                          type="text"
                          placeholder="Description"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <hr />

                <div className="field is-horizontal">
                  <div className="field-label" />
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        <div className="control">
                          <button
                            type="submit"
                            className={`button is-primary ${
                              loading && 'is-loading'
                            }`}
                          >
                            <span>
                              {useFormatMessage('ProductForm.submit')}
                            </span>
                          </button>
                        </div>
                        <Link
                          to={`/companies/${product.companyId}/products`}
                          className="button"
                        >
                          {goBackMessage}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="tile is-parent preview">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account default" />
                </span>
                {useFormatMessage('ProductForm.preview')}
              </p>
            </header>
            <div className="card-content">
              <div className="field">
                <label className="label">
                  {useFormatMessage('Products.company')}
                </label>
                <div className="control is-clearfix">
                  <input
                    data-testid="id"
                    type="text"
                    readOnly="readOnly"
                    className="input is-static"
                    value={
                      companies.find((comp) => comp.id === watch('companyId'))
                        ?.name ?? '--'
                    }
                  />
                </div>
              </div>
              {isEditing && (
                <div className="field">
                  <label className="label">
                    {useFormatMessage('ProductForm.id')}
                  </label>
                  <div className="control is-clearfix">
                    <input
                      data-testid="id"
                      type="text"
                      readOnly="readOnly"
                      className="input is-static"
                      value={watch('id')}
                    />
                  </div>
                </div>
              )}
              <div className="field">
                <label className="label">
                  {useFormatMessage('ProductForm.name')}
                </label>
                <div className="control is-clearfix">
                  <input
                    data-testid="name"
                    type="text"
                    readOnly="readOnly"
                    className="input is-static"
                    value={watch('name')}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">
                  {useFormatMessage('ProductForm.validFrom')}
                </label>
                <div className="control is-clearfix">
                  <p className="date">
                    {`${
                      watch('validFrom')
                        ? useFormatDate(watch('validFrom'), {
                            weekday: 'short',
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                          })
                        : ''
                    } - ${
                      watch('validTo')
                        ? useFormatDate(watch('validTo'), {
                            weekday: 'short',
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                          })
                        : ''
                    }`}
                  </p>
                </div>
              </div>
              <div className="field">
                <label className="label">
                  {useFormatMessage('ProductForm.activated')}
                </label>
                <div className="control is-clearfix" data-testid="admin">
                  {watch('activated') ? (
                    <span className="icon">
                      <i className="mdi mdi-check" />
                    </span>
                  ) : (
                    <span className="icon">
                      <i className="mdi mdi-close" />
                    </span>
                  )}
                </div>
              </div>
              <div className="field">
                <label className="label">
                  {useFormatMessage('ProductForm.address')}
                </label>
                <div className="control is-clearfix">
                  <input
                    data-testid="address"
                    type="text"
                    readOnly="readOnly"
                    className="input is-static"
                    value={watch('address')}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">
                  {useFormatMessage('ProductForm.phone')}
                </label>
                <div className="control is-clearfix">
                  <input
                    data-testid="phone"
                    type="text"
                    readOnly="readOnly"
                    className="input is-static"
                    value={watch('phone')}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">
                  {useFormatMessage('Categories.categories')}
                </label>
                <div className="field">
                  <div className="tags">
                    {selectedCategories.map((cat) => (
                      <span key={cat} className="tag">
                        {cat}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
              <div className="field">
                <label className="label">
                  {useFormatMessage('ProductForm.companyUrl')}
                </label>
                <div className="control">
                  <a
                    rel="noreferrer noopener"
                    target="_blank"
                    href={watch('companyUrl')}
                  >
                    {watch('companyUrl')}
                  </a>
                </div>
              </div>
              <div className="field">
                <label className="label">
                  {useFormatMessage('ProductForm.shoppingUrl')}
                </label>
                <div className="control is-clearfix">
                  <a
                    rel="noreferrer noopener"
                    target="_blank"
                    href={watch('shoppingUrl')}
                  >
                    {watch('shoppingUrl')}
                  </a>
                </div>
              </div>
              {logoUrl && (
                <>
                  <div className="field">
                    <label className="label">
                      {useFormatMessage('ProductForm.logo')}
                    </label>
                    <div className="image is-64x64 has-max-width is-aligned-center">
                      <img src={logoUrl} alt="product logo" />
                    </div>
                  </div>
                </>
              )}
              {previewUrl && (
                <>
                  <div className="field">
                    <label className="label">
                      {useFormatMessage('ProductForm.preview')}
                    </label>
                    <div className="image is-64x64 has-max-width is-aligned-center">
                      <img src={previewUrl} alt="product preview" />
                    </div>
                  </div>
                </>
              )}
              <hr />
              <div className="field">
                <label className="label">
                  {useFormatMessage('ProductForm.description')}
                </label>
                <div className="control is-clearfix">
                  <input
                    data-testid="description"
                    type="text"
                    readOnly="readOnly"
                    className="input is-static"
                    value={watch('description')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ProductForm.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string,
    companyId: PropTypes.string,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    address: PropTypes.string.isRequired,
    companyUrl: PropTypes.string,
    companyLogoUrl: PropTypes.string,
    previewUrl: PropTypes.string,
    shoppingUrl: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.string),
    categories: PropTypes.arrayOf(PropTypes.string),
    validFrom: PropTypes.instanceOf(Date).isRequired,
    validTo: PropTypes.instanceOf(Date).isRequired,
    activated: PropTypes.bool,
  }).isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  schema: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
};

ProductForm.defaultProps = {
  isEditing: false,
};

export default ProductForm;
