import React, { useEffect, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import Table from 'components/Table';
import classNames from 'classnames';
import { useFormatMessage } from 'hooks';
import dayjs from 'dayjs';
import { createDocument, fetchCollection, updateDocument, normalizeDate, documentRef } from 'state/api';
import { useSelector } from 'react-redux';
import firebase from 'firebase.js';
import { toastr } from 'react-redux-toastr';
import ConfirmationModal from 'components/ConfirmationModal';
import classes from './Messaging.module.scss';
import {CampainModal} from './CampainModal';

export default function Messaging() {
  const { userData } = useSelector(state => state.auth);
  const [loading, setLoading] = useState(false);
  const [listTask, setListTask] = useState([]);
  const [keyword, setKeyword] = useState();
  const [error, setError] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [formData, setFormData] = useState();
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    submitting: false,
    id: undefined
  });

  const fetchAllTask = async () => {
    try {
      setLoading(true);
      const data = await fetchCollection('tasks', {
        queries: [
          {
            attribute: 'worker',
            operator: '==',
            value: 'notification',
          },
          {
            attribute: 'options.topic',
            operator: 'in',
            value: ['news', 'agenda'],
          },
        ],
      });
      const mappedData = data.map(task => normalizeDate(task, ['createdAt', 'performAt', 'updatedAt']));
      setListTask(mappedData);
    } catch (e) {
      const msg = e instanceof Error ? e.message : `${e}`;
      setError(msg);
      toastr.error('', msg);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllTask();
  }, []);

  const onRemoveButtonClickHandler = (id: string) => {
    setFormData();
    setDeleteModal({
      open: true,
      id,
      submitting: false
    });
  };

  const handleNewCampainClicked = () => {
    setFormData({
      topic: 'news',
      title: '',
      body: '',
      image: '',
      articleId: '',
      status: 'scheduled',
      performAtDate: dayjs().format('YYYY-MM-DD'),
      performAtTime: dayjs().format('HH:mm'),
    });
  };

  const modifySuccessMessage = useFormatMessage("Messaging.modifySuccess");
  const createSuccessMessage = useFormatMessage("Messaging.createSuccess");
  const modifyDeniedMessage = useFormatMessage("Messaging.modifyDenied");

  const handleTaskCreation = async (form) => {
    if(formData.status === 'completed') {
      setFormData();
      toastr.error('', modifyDeniedMessage);
      return;
    }
    try {
      setSubmitting(true);
      const isUpdate = typeof form.id === 'string';
      const performAt = dayjs(`${form.performAtDate} ${form.performAtTime}`).toDate();
      if (isUpdate) {
        await updateDocument('tasks', form.id, {
          updatedBy: userData.id,
          updatedAt: new Date(),
          performAt,
          options: {
            title: form.title,
            body: form.body,
            image: form.image,
            topic: form.topic,
            route: form.topic,
            articleId: form.articleId
          }
        });
        setFormData();
        setSubmitting(false);
        toastr.success('', modifySuccessMessage);
        fetchAllTask();
        return;
      }
      const taskId = firebase.firestore().collection('tasks').doc().id;
      const task = {
        createdBy: userData.id,
        createdAt: new Date(),
        status: 'scheduled',
        worker: 'notification',
        articleId: form.articleId,
        performAt,
        options: {
          title: form.title,
          body: form.body,
          image: form.image,
          topic: form.topic,
          route: form.topic,
          articleId: form.articleId
        }
      };
      await createDocument('tasks', taskId, task);
      setFormData();
      setSubmitting(false);
      toastr.success('', createSuccessMessage);
      fetchAllTask();
    } catch (e) {
      const msg = e instanceof Error ? e.message : `${e}`;
      toastr.error('', msg);
    }
  };

  const columns = [
    {
      Header: 'Campaign',
      accessor: 'campaign',
      Cell: ({ row }) => {
        const hasImage = typeof row.original.options.image === 'string' && row.original.options.image.length > 5;
        return (
          <>
            <p className="is-size-6">
              {hasImage ? <img src={row.original.options.image} alt="" className="image is-48x48 is-inline mr-2" /> : null}
              <span>{row.original.options.title}</span>
            </p>
            <p className="is-size-7">{row.original.options.body}</p>
          </>
        );
      },
    },
    {
      Header: 'PerformAt',
      accessor: 'performAt',
      Cell: ({ row }) => (
        dayjs(row.original.performAt).format()
      ),
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ row }) => row.original.status,
    },
    {
      Header: 'Created At',
      accessor: 'createdAt',
      Cell: ({ row }) => (
        dayjs(row.original.createdAt).format()
      ),
    },
    {
      Header: 'Actions',
      id: 'actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <div className="buttons is-right">
          <button
            className="button is-small is-primary"
            onClick={() => {
              setFormData({
                id: row.original.id,
                title: row.original.options.title,
                body: row.original.options.body,
                topic: row.original.options.topic,
                image: row.original.options.image,
                articleId: row.original.options.articleId,
                status: row.original.status,
                performAtDate: dayjs(row.original.performAt).format('YYYY-MM-DD'),
                performAtTime: dayjs(row.original.performAt).format('HH:mm'),
              });
            }}
          >
            <span className="icon is-small">
              <i className="mdi mdi-square-edit-outline" />
            </span>
          </button>

          {row.original.status !== 'completed' && (
            <button
              type="button"
              className="button is-small is-danger"
              onClick={() => onRemoveButtonClickHandler(row.original.id)}
            >
              <span className="icon is-small">
                <i className="mdi mdi-trash-can" />
              </span>
            </button>
          )}
        </div>
      ),
      disableSortBy: true,
    },
  ];

  const data = keyword
    ? listTask.filter((el) => {
        const clonedElem = { ...el };
        delete clonedElem.createdAt;
        delete clonedElem.performAt;
        delete clonedElem.createdBy;
        return Object.values(clonedElem.options).some((field) => {
          const key = field instanceof Date ? field.toDateString() : `${field}`;
          return key.toLowerCase().includes(keyword.toLowerCase());
        });
      })
    : listTask;

    const deleteMessage = useFormatMessage('Messaging.delete');

  const confirmMessage = useFormatMessage('Messaging.confirm');

  const permDeleteMessage = useFormatMessage('Messaging.permDelete');

  const cancelMessage = useFormatMessage('Messaging.cancel');

  const deleteSuccessMessage = useFormatMessage("Messaging.deleteSuccess");

  const onDeleteTaskHandler = async () => {
    try {
      setDeleteModal(prev => ({
        ...prev,
        submitting: true
      }));
      await documentRef('tasks', deleteModal.id).delete();
      setDeleteModal({
        open: false,
        id: undefined,
        submitting: false
      });
      toastr.success('', deleteSuccessMessage);
      fetchAllTask();
    } catch (e) {
      const msg = e instanceof Error ? e.message : `${e}`;
      toastr.error('', msg);
    }
  };

  const onCloseModalHandler = () => {
    setDeleteModal({
      open: false,
      id: undefined,
      submitting: false
    });
  };

  return (
    <>
      {formData && (
        <CampainModal
          isLoading={submitting}
          formData={formData}
          onCancel={() => setFormData()}
          onSubmit={handleTaskCreation}
          onDelete={(id) => onRemoveButtonClickHandler(id)}
        />
      )}
      {deleteModal.open && (
        <ConfirmationModal
          isActive={deleteModal.open}
          isLoading={deleteModal.submitting}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteTaskHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="title">
                  {useFormatMessage('Messaging.title')}
                </h1>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <button onClick={handleNewCampainClicked} className="button">
                  {useFormatMessage('Messaging.newCampaign')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section is-main-section">
        <div className="card has-table has-mobile-sort-spaced">
          <header className="card-header">
            <p className={classNames('card-header-title', classes.tableHeader)}>
              <span>{useFormatMessage('Categories.search')}</span>
              <input
                type="text"
                className="input"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
              />
            </p>
          </header>
          <div className="b-table">
            {loading ? <ClipLoader /> : <Table columns={columns} data={data} />}
            {error && 'Show error'}
          </div>
        </div>
      </section>
    </>
  );
}
