import React, { useEffect, useMemo, useRef } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import * as yup from 'yup';

import ProductForm from 'components/ProductForm';
import {
  createProduct,
  modifyProduct,
  fetchProducts,
} from 'state/actions/products';
import { useFormatMessage } from 'hooks';
import { fetchCategories } from 'state/actions/categories';
import { getCategoryTreeRecusively } from 'utils';

const schema = yup.object().shape({
  name: yup.string().required(),
  phone: yup.string().notRequired(),
  description: yup.string().notRequired(),
  address: yup.string().required(),
  logo: yup.string().notRequired().nullable(),
  preview: yup.string().notRequired(),
  companyUrl: yup.string().url().notRequired(),
  shoppingUrl: yup.string().url().notRequired(),
  images: yup.array(yup.string().required()).notRequired(),
  categories: yup.array(yup.string().required()).notRequired(),
  activated: yup.boolean().default(false),
  validFrom: yup.date().required(),
  validTo: yup.date().required(),
  createdAt: yup.date().required().default(new Date()),
});

const Product = () => {
  const { id, companyId } = useParams();

  const isEditing = useMemo(() => !!id, [id]);
  const companyRef = useRef(companyId);

  const {
    success,
    selectedProduct,
    error,
    loadingCategory,
    categories,
    company,
  } = useSelector(
    (state) => ({
      success: state.products.success,
      selectedProduct: state.products.data.find((product) => product.id === id),
      loadingCategory: state.categories.loading,
      company: state.companies.data.find((comp) => comp.id === companyId),
      categories: state.categories.data,
      error: state.products.error,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditing && !selectedProduct) {
      dispatch(fetchProducts(companyId, id));
    }
    dispatch(fetchCategories());
  }, [isEditing, id, selectedProduct, dispatch]);

  const redirect = ((isEditing && error) || success || !company) && (
    <Redirect to={`/companies/${companyRef.current}/products`} />
  );

  const editProductMessage = useFormatMessage('Product.editProduct');

  const newProductMessage = useFormatMessage('Product.newProduct');

  const onSubmitHandler = (value) => {
    const logoFile = value?.logoFile[0] || null;
    const previewFile = value?.previewFile[0] || null;
    companyRef.current = value.companyId;

    if (isEditing) {
      dispatch(
        modifyProduct({
          ...value,
          prevCategories: Object.keys(selectedProduct.categories || {}),
          prevLogo: selectedProduct.logo,
          prevPreview: selectedProduct.preview,
          prevCompanyId: selectedProduct.companyId,
          images: [],
          logoFile,
          previewFile,
          isEditing,
          id,
        })
      );
      return;
    }

    dispatch(
      createProduct({
        ...value,
        logoFile,
        previewFile,
      })
    );
  };

  const defaultEmptyProduct = {
    companyId,
    name: '',
    phone: '',
    description: '',
    address: '',
    logo: '',
    preview: '',
    companyUrl: '',
    shoppingUrl: '',
    images: [],
    categories: [],
    email: '',
    validFrom: new Date(),
    validTo: new Date(),
    activated: false,
  };

  const product = isEditing
    ? {
        ...selectedProduct,
        validFrom:
          typeof selectedProduct.validFrom === 'string'
            ? new Date(selectedProduct.validFrom)
            : selectedProduct.validFrom,
        validTo:
          typeof selectedProduct.validTo === 'string'
            ? new Date(selectedProduct.validTo)
            : selectedProduct.validTo,
        categories: Object.keys(selectedProduct.categories || {}),
      }
    : defaultEmptyProduct;

  return (
    <>
      {redirect}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">
            {isEditing ? editProductMessage : newProductMessage}
          </h1>
        </div>
      </section>
      <section className="section is-main-section">
        {isEditing && !selectedProduct ? (
          <ClipLoader />
        ) : (
          <ProductForm
            isEditing={isEditing}
            loadingCategory={loadingCategory}
            categories={getCategoryTreeRecusively(categories)}
            product={product}
            onSubmitHandler={onSubmitHandler}
            schema={schema}
          />
        )}
      </section>
    </>
  );
};

export default Product;
