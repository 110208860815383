import React, { useEffect, useMemo } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import * as yup from 'yup';

import AuthorityForm from 'components/AuthorityForm';
import {
  createAuthority,
  fetchAuthorities,
  modifyAuthority
} from 'state/actions/authorities';
import paths from 'pages/Router/paths';
import { useFormatMessage } from 'hooks';
import { fetchUsers } from 'state/actions/users';

const schema = yup.object().shape({
  name: yup.string().required(),
  address: yup.string().required(),
  logo: yup.string().required(),
  cover: yup.string().required(),
  phone: yup.string().required(),
  link1: yup.string().notRequired(),
  link2: yup.string().notRequired(),
  link3: yup.string().notRequired(),
  workingHours: yup.string().notRequired(),
});

const Authority = () => {
  const { id } = useParams();

  const isEditing = useMemo(() => !!id, [id]);

  const { success, selectedAuthority, users, userLoading } = useSelector(
    (state) => ({
      success: state.authorities.success,
      users: state.users.data.map((user) => ({id: user.id, name: user.name})),
      userLoading: state.users.loading,
      selectedAuthority: state.authorities.data.find(
        (authority) => authority?.id === id
      ),
      error: state.authorities.error,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditing && !selectedAuthority) {
      dispatch(fetchAuthorities(id));
    }
    dispatch(fetchUsers());
  }, [isEditing, id, selectedAuthority, dispatch]);

  const redirect = success && <Redirect to={paths.AUTHORITIES} />;

  const editAuthorityMessage = useFormatMessage('Authorities.editAuthority');

  const newAuthorityMessage = useFormatMessage('Authorities.newAuthority');

  const onSubmitHandler = (value) => {
    const logoFile = value?.logoFile[0] || null;
    const coverFile = value?.coverFile[0] || null;
    if (isEditing) {
      dispatch(
        modifyAuthority({
          ...value,
          logoFile,
          coverFile,
          isEditing,
          id,
        })
      );
    } else {
      dispatch(createAuthority({ ...value, logoFile, coverFile }));
    }
  };

  return (
    <>
      {redirect}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">
            {isEditing ? editAuthorityMessage : newAuthorityMessage}
          </h1>
        </div>
      </section>
      <section className="section is-main-section">
        {isEditing && !selectedAuthority ? (
          <ClipLoader />
        ) : (
          <AuthorityForm
            isEditing={isEditing}
            users={users}
            userLoading={userLoading}
            authority={
              isEditing
                ? selectedAuthority
                : {
                    name: '',
                    address: '',
                    phone: '',
                    link1: '',
                    link2: '',
                    link3: '',
                    logo: '',
                    workingHours: '',
                    cover: '',
                    members: [],
                  }
            }
            onSubmitHandler={onSubmitHandler}
            schema={schema}
          />
        )}
      </section>
    </>
  );
};

export default Authority;
