import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';

import { deleteImages, firebaseError, uploadImages } from 'utils';
import firebase from 'firebase.js';
import {
  fetchDocument,
  documentRef,
  fetchCollection,
  normalizeDate,
} from '../api';

export const COMPANIES_FETCH_DATA_INIT = createAction(
  'COMPANIES_FETCH_DATA_INIT'
);
export const COMPANIES_FETCH_DATA_SUCCESS = createAction(
  'COMPANIES_FETCH_DATA_SUCCESS'
);
export const COMPANIES_FETCH_DATA_FAIL = createAction(
  'COMPANIES_FETCH_DATA_FAIL'
);

export const COMPANIES_DELETE_COMPANY_INIT = createAction(
  'COMPANIES_DELETE_COMPANY_INIT'
);
export const COMPANIES_DELETE_COMPANY_SUCCESS = createAction(
  'COMPANIES_DELETE_COMPANY_SUCCESS'
);
export const COMPANIES_DELETE_COMPANY_FAIL = createAction(
  'COMPANIES_DELETE_COMPANY_FAIL'
);

export const COMPANIES_CREATE_COMPANY_INIT = createAction(
  'COMPANIES_CREATE_COMPANY_INIT'
);
export const COMPANIES_CREATE_COMPANY_SUCCESS = createAction(
  'COMPANIES_CREATE_COMPANY_SUCCESS'
);
export const COMPANIES_CREATE_COMPANY_FAIL = createAction(
  'COMPANIES_CREATE_COMPANY_FAIL'
);

export const COMPANIES_MODIFY_COMPANY_INIT = createAction(
  'COMPANIES_MODIFY_COMPANY_INIT'
);
export const COMPANIES_MODIFY_COMPANY_SUCCESS = createAction(
  'COMPANIES_MODIFY_COMPANY_SUCCESS'
);
export const COMPANIES_MODIFY_COMPANY_FAIL = createAction(
  'COMPANIES_MODIFY_COMPANY_FAIL'
);
export const COMPANIES_CLEAN_UP = createAction('COMPANIES_CLEAN_UP');

export const fetchCompanies = (companyId) => async (dispatch, getState) => {
  dispatch(COMPANIES_FETCH_DATA_INIT());

  // edit company
  if (companyId) {
    let company;
    try {
      company = normalizeDate(await fetchDocument('companies', companyId), [
        'createdAt',
        'updatedAt',
        'validFrom',
        'validTo',
      ]);
    } catch (error) {
      toastr.error('', error);
      return dispatch(COMPANIES_FETCH_DATA_FAIL({ error }));
    }
    const companies = getState().companies.data.map((doc) =>
      normalizeDate(doc, ['createdAt', 'updatedAt', 'validFrom', 'validTo'])
    );
    companies.push(company);
    return dispatch(COMPANIES_FETCH_DATA_SUCCESS, { data: companies });
  }

  // fetch all companies
  let companies;
  try {
    companies = await fetchCollection('companies');
  } catch (error) {
    toastr.error('', error);
    return dispatch(COMPANIES_FETCH_DATA_FAIL({ error }));
  }

  return dispatch(
    COMPANIES_FETCH_DATA_SUCCESS({
      data: companies.map((doc) =>
        normalizeDate(doc, ['createdAt', 'updatedAt', 'validFrom', 'validTo'])
      ),
    })
  );
};

export const deleteCompany = (id) => async (dispatch, getState) => {
  dispatch(COMPANIES_DELETE_COMPANY_INIT());
  const { locale } = getState().preferences;

  try {
    await documentRef('companies', id).delete();
  } catch (error) {
    const errorMessage = firebaseError(error.code, locale);
    toastr.error('', errorMessage);
    return dispatch(
      COMPANIES_DELETE_COMPANY_FAIL({
        error: errorMessage,
      })
    );
  }

  toastr.success('', 'The company was deleted.');
  return dispatch(COMPANIES_DELETE_COMPANY_SUCCESS({ id }));
};

export const createCompany =
  ({
    name,
    contact = '',
    displayName,
    logoFile,
    email,
    address,
    products = [],
  }) =>
  async (dispatch, getState) => {
    dispatch(COMPANIES_CREATE_COMPANY_INIT());

    const { userData } = getState().auth;
    const { locale } = getState().preferences;

    const companyId = firebase.firestore().collection('companies').doc().id;

    const batch = firebase.firestore().batch();
    const now = new Date();
    const _products = {};

    // assign company to selected categories
    products.forEach((productId) => {
      _products[productId] = true;
      batch.set(
        documentRef('products', productId),
        {
          companyId,
          updatedAt: now,
          updatedBy: userData.id,
        },
        { merge: true }
      );
    });

    let companyData = {
      name,
      contact,
      email,
      displayName,
      address,
      products: _products,
      createdAt: now,
      createdBy: userData.id,
    };

    try {
      if (logoFile) {
        // upload logo
        const [logo] = await uploadImages({
          collection: 'companies',
          documentId: companyId,
          files: [logoFile],
        });
        companyData = { ...companyData, logo };
      }
      batch.set(documentRef('companies', companyId), companyData);
      await batch.commit();
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        COMPANIES_CREATE_COMPANY_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Company created successfully');
    return dispatch(
      COMPANIES_CREATE_COMPANY_SUCCESS({
        company: { ...companyData, id: companyId },
      })
    );
  };

export const modifyCompany =
  ({
    name,
    contact = '',
    displayName,
    email,
    address,
    logoFile,
    prevLogo,
    products = [],
    prevProducts = [],
    id,
  }) =>
  async (dispatch, getState) => {
    dispatch(COMPANIES_MODIFY_COMPANY_INIT());
    const { userData } = getState().auth;
    const { locale } = getState().preferences;

    const batch = firebase.firestore().batch();
    const now = new Date();
    const _products = {};

    // assign company to selected products
    products.forEach((productId) => {
      _products[productId] = true;
      batch.set(
        documentRef('products', productId),
        {
          companyId: id,
          updatedAt: now,
          updatedBy: userData.id,
        },
        { merge: true }
      );
    });

    const leavedCategories = prevProducts.filter(
      (productId) => !products.includes(productId)
    );
    // unassign company to deselected products
    leavedCategories.forEach((categoryId) => {
      const updateData = {
        companyId: firebase.firestore.FieldValue.delete(),
        updatedAt: now,
        updatedBy: userData.id,
      };
      batch.update(documentRef('products', categoryId), updateData);
    });

    let companyData = {
      name,
      contact,
      displayName,
      email,
      address,
      products: _products,
      updatedAt: new Date(),
      updatedBy: userData.id,
    };
    try {
      if (logoFile) {
        // delete prev logo
        if (typeof prevLogo === 'string') {
          await deleteImages({
            collection: 'companies',
            URLs: [prevLogo],
          });
        }
        // upload new logo
        const [logo] = await uploadImages({
          collection: 'companies',
          documentId: id,
          files: [logoFile],
        });
        companyData = { ...companyData, logo };
      } else if (typeof prevLogo === 'string') {
        companyData = { ...companyData, logo: prevLogo };
      }
      // modify company
      batch.update(documentRef('companies', id), companyData);
      await batch.commit();
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        COMPANIES_MODIFY_COMPANY_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Company updated successfully');
    const data = await fetchDocument('companies', id);
    const company = normalizeDate(data, [
      'createdAt',
      'updatedAt',
      'validFrom',
      'validTo',
    ]);

    return dispatch(COMPANIES_MODIFY_COMPANY_SUCCESS({ company, id }));
  };

export const adjustShoppingWindowContract =
  ({ id, validFrom, validTo }) =>
  async (dispatch) => {
    dispatch(COMPANIES_MODIFY_COMPANY_INIT());
    await documentRef('companies', id).set(
      {
        validFrom: validFrom || firebase.firestore.FieldValue.delete(),
        validTo: validTo || firebase.firestore.FieldValue.delete(),
      },
      { merge: true }
    );
    const data = await fetchDocument('companies', id);
    const company = normalizeDate(data, [
      'createdAt',
      'updatedAt',
      'validFrom',
      'validTo',
    ]);
    toastr.success('', 'Company updated successfully');
    return dispatch(COMPANIES_MODIFY_COMPANY_SUCCESS({ company, id }));
  };
