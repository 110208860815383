import { createReducer } from 'redux-act';

import {
  CATEGORIES_FETCH_DATA_INIT,
  CATEGORIES_FETCH_DATA_SUCCESS,
  CATEGORIES_FETCH_DATA_FAIL,
  CATEGORIES_DELETE_CATEGORY_INIT,
  CATEGORIES_DELETE_CATEGORY_SUCCESS,
  CATEGORIES_DELETE_CATEGORY_FAIL,
  CATEGORIES_CREATE_CATEGORY_INIT,
  CATEGORIES_CREATE_CATEGORY_SUCCESS,
  CATEGORIES_CREATE_CATEGORY_FAIL,
  CATEGORIES_MODIFY_CATEGORY_INIT,
  CATEGORIES_MODIFY_CATEGORY_SUCCESS,
  CATEGORIES_MODIFY_CATEGORY_FAIL,
  CATEGORIES_CLEAN_UP
} from 'state/actions/categories';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const categoriesReducer = createReducer(
  {
    [CATEGORIES_CLEAN_UP]: () => initialState,
    [CATEGORIES_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [CATEGORIES_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.data.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }),
      loading: false,
      error: null,
    }),
    [CATEGORIES_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [CATEGORIES_DELETE_CATEGORY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [CATEGORIES_DELETE_CATEGORY_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.filter((elem) => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [CATEGORIES_DELETE_CATEGORY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [CATEGORIES_CREATE_CATEGORY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [CATEGORIES_CREATE_CATEGORY_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.concat(payload.category),
      loading: false,
      error: null,
      success: true,
    }),
    [CATEGORIES_CREATE_CATEGORY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [CATEGORIES_MODIFY_CATEGORY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [CATEGORIES_MODIFY_CATEGORY_SUCCESS]: (state, payload) => ({
      ...state,
      data: !state.data
        ? []
        : state.data.map((elem) => {
            if (elem.id === payload.id) {
              return {
                id: payload.id,
                name: payload.category.name,
                code: payload.category.code,
                parentCategory: payload.category.parentCategory,
                createdAt: payload.category.createdAt,
                createdBy: payload.category.createdBy,
                updatedAt: payload.category.updatedAt,
                updatedBy: payload.category.updatedBy,
              };
            }
            return elem;
          }),
      loading: false,
      error: null,
      success: true,
    }),
    [CATEGORIES_MODIFY_CATEGORY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
  },
  initialState
);
