import React, { useEffect, useMemo } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import * as yup from 'yup';

import CategoryForm from 'components/CategoryForm';
import {
  createCategory,
  modifyCategory,
  fetchCategories,
} from 'state/actions/categories';
import { fetchProducts } from 'state/actions/products';
import paths from 'pages/Router/paths';
import { useFormatMessage } from 'hooks';

const schema = yup.object().shape({
  name: yup.string().required(),
  code: yup.string().required(),
  categories: yup.array(yup.string().required()).notRequired().default([]),
});

const Category = () => {
  const { id } = useParams();

  const isEditing = useMemo(() => !!id, [id]);

  const { success, selectedCategory, error } =
    useSelector(
      (state) => ({
        success: state.categories.success,
        selectedCategory: state.categories.data.find(
          (category) => category.id === id
        ),
        error: state.categories.error,
      }),
      shallowEqual
    );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditing && !selectedCategory) {
      dispatch(fetchCategories(id));
    }
    dispatch(fetchProducts());
  }, [isEditing, id, selectedCategory, dispatch]);

  const redirect = ((isEditing && error) || success) && (
    <Redirect to={paths.CATEGORIES} />
  );

  const editCategoryMessage = useFormatMessage('Categories.editCategory');

  const newCategoryMessage = useFormatMessage('Categories.newCategory');

  const onSubmitHandler = (value) => {
    if (isEditing) {
      dispatch(
        modifyCategory({
          ...value,
          prevParentCategory: selectedCategory.parentCategory,
          isEditing,
          id,
        })
      );
    } else {
      dispatch(createCategory(value));
    }
  };

  return (
    <>
      {redirect}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">
            {isEditing ? editCategoryMessage : newCategoryMessage}
          </h1>
        </div>
      </section>
      <section className="section is-main-section">
        {isEditing && !selectedCategory ? (
          <ClipLoader />
        ) : (
          <CategoryForm
            isEditing={isEditing}
            id={id}
            category={
              isEditing
                ? selectedCategory
                : {
                    name: '',
                    code: '',
                  }
            }
            onSubmitHandler={onSubmitHandler}
            schema={schema}
          />
        )}
      </section>
    </>
  );
};

export default Category;
