export default {
  ROOT: '/',
  LOGIN: '/login',

  USERS: '/users',
  ADD_USER: '/users/new',
  MODIFY_USER: '/users/:id',
  RESET_PASSWORD_USER: '/users/resetpassword/:id',

  COMPANIES: '/companies',
  ADD_COMPANY: '/companies/new',
  MODIFY_COMPANY: '/companies/:id',

  COMPANIES_PRODUCTS: '/companies/:companyId/products',
  ADD_PRODUCT: '/companies/:companyId/products/new',
  MODIFY_PRODUCT: '/companies/:companyId/products/:id',

  SHOPPING_WINDOW: '/shoppingwindow/:companyId',
  SHOPPING_WINDOW_DETAILS: '/shoppingwindow/:companyId/post/:id',

  CATEGORIES: '/categories',
  ADD_CATEGORY: '/categories/new',
  MODIFY_CATEGORY: '/categories/:id',
  
  CAMPAIGNS: '/campaigns',
  ADD_CAMPAIGN: '/campaigns/new',
  MODIFY_CAMPAIGN: '/campaigns/:id',
  
  AUTHORITIES: '/authorities',
  ADD_AUTHORITY: '/authorities/new',
  MODIFY_AUTHORITY: '/authorities/:id',
  
  AUTHORITY_POSTS: '/authorityPosts/:authorityId',

  MESSAGING: '/messaging',

  POSTS: '/posts',

  PROFILE: '/profile',
  RESET_PASSWORD: '/recover-password',
};
