import React, { useEffect, useMemo } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import * as yup from 'yup';

import UserForm from 'components/UserForm';
import { createUser, modifyUser, fetchUsers } from 'state/actions/users';
import paths from 'pages/Router/paths';
import { useFormatMessage } from 'hooks';
import { fetchCompanies } from 'state/actions/companies';
import { fetchAuthorities } from 'state/actions/authorities';

const schemaAdd = yup.object().shape({
  email: yup.string().email().required(),
  name: yup.string().required(),
  companyId: yup.string().notRequired(),
  password: yup.string().required(),
  role: yup.mixed().oneOf(['admin', 'shopping_window', 'authority']),
  location: yup.string().notRequired(),
  createdAt: yup.string().required(),
});

const schemaEdit = yup.object().shape({
  email: yup.string().email().required(),
  name: yup.string().required(),
  companyId: yup.string().notRequired(),
  role: yup.mixed().oneOf(['admin', 'shopping_window', 'authority']),
  location: yup.string().notRequired(),
  createdAt: yup.string().required(),
});

const User = () => {
  const { id } = useParams();

  const isEditing = useMemo(() => !!id, [id]);

  const { success, userData, authorities, companies, companyLoading, authorityLoading, error } = useSelector(
    (state) => ({
      success: state.users.success,
      companies: [{ id: 'NONE', name: '__NONE__' }, ...state.companies.data],
      authorities: [{ id: 'NONE', name: '__NONE__' }, ...state.authorities.data],
      companyLoading: state.companies.loading,
      authorityLoading: state.authorities.loading,
      userData: state.users.data.find((user) => user.id === id),
      error: state.users.error,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditing && !userData) {
      dispatch(fetchUsers(id));
    }
    Promise.all([
      dispatch(fetchCompanies()),
      dispatch(fetchAuthorities())
    ]);
  }, [isEditing, id, userData, dispatch]);

  const redirect = ((isEditing && error) || success) && (
    <Redirect to={paths.USERS} />
  );

  const editUserMessage = useFormatMessage('User.editUser');

  const newUserMessage = useFormatMessage('User.newUser');

  const onSubmitHandler = (value) => {
    const newUser = {
      ...value,
      isEditing,
      prevCompanyId: userData?.companyId || null,
      prevAuthorityId: userData?.authorityId || null,
      id,
    };

    if (isEditing) {
      dispatch(modifyUser(newUser));
    } else {
      dispatch(createUser(newUser));
    }
  };

  return (
    <>
      {redirect}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">
            {isEditing ? editUserMessage : newUserMessage}
          </h1>
        </div>
      </section>
      <section className="section is-main-section">
        {isEditing && !userData ? (
          <ClipLoader />
        ) : (
          <UserForm
            isEditing={isEditing}
            authorities={authorities}
            companies={companies}
            companyLoading={companyLoading}
            authorityLoading={authorityLoading}
            user={
              isEditing
                ? {
                  ...userData,
                  companyId: userData?.companyId ?? 'NONE',
                  authorityId: userData?.authorityId ?? 'NONE',
                }
                : {
                    name: '',
                    email: '',
                    password: '',
                    location: '',
                    role: 'shopping_window',
                    companyId: 'NONE',
                    authorityId: 'NONE',
                    createdAt: new Date().toDateString()
                  }
            }
            onSubmitHandler={onSubmitHandler}
            schema={isEditing ? schemaEdit : schemaAdd}
          />
        )}
      </section>
    </>
  );
};

export default User;
