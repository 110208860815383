import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import moment from 'moment';
import { Link, useParams, Redirect } from 'react-router-dom';
import classNames from 'classnames';
import ClipLoader from 'react-spinners/ClipLoader';
import { useFormatMessage } from 'hooks';
import Table from 'components/Table';
import {
  fetchPostsByCompanyId,
  deletePost,
  searchKeyword,
  searchPeriod,
} from 'state/actions/shoppingPosts';
import paths from 'pages/Router/paths';
import CalendarPicker from 'components/CalendarPicker';
import ConfirmationModal from 'components/ConfirmationModal';
import classes from './ShoppingWindow.module.scss';
import CompanySection from './CompanySection';

const ShoppingWindow = () => {
  const { companyId } = useParams();
  const {
    posts,
    error,
    loading,
    deleted,
    selectedCompany,
    keyword,
    startDate,
    endDate,
  } = useSelector(
    (state) => ({
      selectedCompany: state.companies.data.filter(
        (comp) => comp.id === companyId
      ).pop(),
      posts: state.shopPosts.data,
      error: state.shopPosts.error,
      loading: state.shopPosts.loading,
      deleted: state.shopPosts.deleted,
      keyword: state.shopPosts.keyword,
      startDate: state.shopPosts.startDate,
      endDate: state.shopPosts.endDate,
    }),
    shallowEqual
  );

  const [deleteModal, setDeleteModal] = useState({
    postId: null,
    isOpen: false,
  });

  const dispatch = useDispatch();
  const redirect = !companyId || !selectedCompany && <Redirect to={paths.COMPANIES} />;

  useEffect(() => {
    dispatch(fetchPostsByCompanyId(companyId));
  }, [dispatch, companyId]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal({
        postId: null,
        isOpen: false,
      });
    }
  }, [deleted, loading]);

  const onRemoveButtonClickHandler = (postId) => {
    setDeleteModal((prevState) => ({
      postId,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({
      postId: null,
      isOpen: false,
    });
  };

  const onDeleteCompanyHandler = () => {
    dispatch(deletePost(deleteModal.postId));
  };

  const columns = [
    {
      Header: useFormatMessage('Companies.no'),
      accessor: 'index',
      Cell: ({ row }) => row.index + 1,
    },
    {
      Header: useFormatMessage('ShoppingWindow.createdAt'),
      accessor: 'createdAt',
      Cell: ({ row }) => moment(row.original.createdAt).format('DD/MM/YYYY'),
    },
    {
      Header: useFormatMessage('ShoppingWindow.validPeriod'),
      accessor: 'valid',
      Cell: ({ row }) =>
        `${moment(row.original.startDate).format('DD/MM/YYYY')} - ${moment(
          row.original.startDate
        )
          .add(row.original.period, 'days')
          .format('DD/MM/YYYY')}`,
    },
    {
      Header: useFormatMessage('ShoppingWindow.postTitle'),
      accessor: 'name',
      Cell: ({ row }) => (
        <Link
          to={`/shoppingwindow/${row.original.companyId}/post/${row.original.id}`}
        >
          {row.original.title}
        </Link>
      ),
    },
    {
      Header: '',
      id: 'actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <div className="buttons is-right">
          <Link
            to={`/shoppingwindow/${row.original.companyId}/post/${row.original.id}`}
            className="button is-small is-primary"
          >
            <span className="icon is-small">
              <i className="mdi mdi-square-edit-outline" />
            </span>
          </Link>

          <button
            type="button"
            className="button is-small is-danger"
            onClick={() => onRemoveButtonClickHandler(row.original.id)}
          >
            <span className="icon is-small">
              <i className="mdi mdi-trash-can" />
            </span>
          </button>
        </div>
      ),
      disableSortBy: true,
    },
  ];

  let data = posts;
  if (keyword || (startDate && endDate)) {
    data = posts.filter((el) => {
      let isKeywordMaching = true;
      if (keyword) {
        isKeywordMaching = el.title
          .toLowerCase()
          .includes(keyword?.toLowerCase());
      }
      let isValidPeriodMatching = true;
      if (startDate && endDate && el.startDate && el.period) {
        const validTo = moment(el.startDate)
          .set('hours', 0)
          .set('minutes', 0)
          .set('seconds', 0)
          .add(el.period, 'days');
        isValidPeriodMatching =
          validTo.isSameOrAfter(startDate) && validTo.isSameOrBefore(endDate);
      }
      return isKeywordMaching && isValidPeriodMatching;
    });
  }

  const deleteMessage = useFormatMessage('Categories.delete');
  const confirmMessage = useFormatMessage('Categories.confirm');
  const permDeleteMessage = useFormatMessage('ShoppingWindow.permDelete');
  const cancelMessage = useFormatMessage('Categories.cancel');

  return (
    <>
      {redirect}
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          title={confirmMessage}
          body={permDeleteMessage}
          confirmButtonMessage={deleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteCompanyHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="title">
                  {useFormatMessage('ShoppingWindow.title')}
                </h1>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <h3 className="mx-2">
                  {useFormatMessage('ShoppingWindow.validPeriod')}
                </h3>
                <CalendarPicker
                  startDate={startDate ? new Date(startDate) : null}
                  endDate={endDate ? new Date(endDate) : null}
                  isRange
                  onChange={(result) =>
                    dispatch(searchPeriod(result.startDate, result.endDate))
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section is-main-section">
        <CompanySection companyId={companyId} />
        <div className="card has-table has-mobile-sort-spaced">
          <header className="card-header">
            <p className={classNames('card-header-title', classes.tableHeader)}>
              <span>{useFormatMessage('Categories.search')}</span>
              <input
                type="text"
                className="input"
                value={keyword}
                onChange={(e) => dispatch(searchKeyword(e.target.value))}
              />
            </p>
          </header>
          <div className="b-table">
            {loading ? (
              <ClipLoader />
            ) : (
              <Table keyword={keyword} columns={columns} data={data} />
            )}
            {error && 'Show error'}
          </div>
        </div>
      </section>
    </>
  );
};

export default ShoppingWindow;
