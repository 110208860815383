/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import classNames from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup';

import paths from 'pages/Router/paths';
import { useFormatDate, useFormatMessage } from 'hooks';
import DatePicker from 'components/DatePicker';
import ErrorMessage from 'components/ErrorMessage';
import Select from 'components/Select';

import './UserForm.scss';

const UserForm = ({
  isEditing,
  isProfile,
  user,
  companies,
  companyLoading,
  authorities,
  authorityLoading,
  onSubmitHandler,
  schema,
}) => {
  const { loading } = useSelector(
    (state) => ({
      loading: state.users.loading,
      success: state.users.success,
    }),
    shallowEqual
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm({
    defaultValues: user,
    resolver: yupResolver(schema),
    shouldFocusError: true
  });

  const invalidEmailMessage = useFormatMessage('UserForm.invalidEmail');
  const companyName = watch('companyId')
    ? companies?.filter((comp) => comp.id === watch('companyId'))
    : null;
  const authorityName = watch('authorityId')
    ? authorities?.filter((authority) => authority.id === watch('authorityId'))
    : null;
  const goBackMessage = useFormatMessage('UserForm.goBack');

  const emailMessage = useFormatMessage('UserForm.email');

  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account-edit default" />
                </span>
                {useFormatMessage('UserForm.userInfo')}
              </p>
            </header>
            <div className="card-content">
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                {isEditing ? (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">{emailMessage}</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <input
                            type="text"
                            readOnly="readOnly"
                            className="input is-static"
                            {...register('email')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">{emailMessage}</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              className={classNames(`input`, {
                                'is-danger': errors.email,
                              })}
                              {...register('email')}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {errors.email && (
                      <div className="field is-horizontal">
                        <div className="field-label is-normal" />
                        <div className="field-body">
                          <ErrorMessage text={invalidEmailMessage} />
                        </div>
                      </div>
                    )}
                  </>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('UserForm.name')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          {...register('name')}
                          id="name"
                          className={classNames('input', {
                            'is-danger': errors.name,
                          })}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {errors.name && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                {!isEditing && (
                  <>
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">
                          {useFormatMessage('UserForm.password')}
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              {...register('password')}
                              id="password"
                              className={classNames('input', {
                                'is-danger': errors.name,
                              })}
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {errors.password && (
                      <div className="field is-horizontal">
                        <div className="field-label is-normal" />
                        <div className="field-body">
                          <ErrorMessage />
                        </div>
                      </div>
                    )}
                  </>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('UserForm.location')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          {...register('location')}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('Companies.info')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="select">
                      <Select
                        name="companyId"
                        register={register}
                        options={companies}
                        loading={companyLoading}
                      />
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('Authorities.info')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="select">
                      <Select
                        name="authorityId"
                        register={register}
                        options={authorities}
                        loading={authorityLoading}
                      />
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('UserForm.role')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="select">
                      <Select
                        name="role"
                        label={useFormatMessage('UserForm.role')}
                        register={register}
                        options={[{id: 'admin', name: 'admin'}, {id: 'shopping_window', name: 'shopping_window'}, {id: 'authority', name: 'authority'}]}
                      />
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('UserForm.created')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <Controller
                        control={control}
                        name="createdAt"
                        render={({ field: { onChange, value, name } }) => (
                          <DatePicker
                            name={name}
                            onChange={onChange}
                            date={new Date(value)}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>

                <hr />
                <div className="field is-horizontal">
                  <div className="field-label" />
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        <div className="control">
                          <button
                            type="submit"
                            disabled={loading || companyLoading || authorityLoading}
                            className={`button is-primary ${(loading || companyLoading || authorityLoading) && 'is-loading'
                              }`}
                          >
                            <span>{useFormatMessage('UserForm.submit')}</span>
                          </button>
                        </div>
                        {!isProfile && (
                          <Link to={paths.USERS} className="button">
                            {goBackMessage}
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="tile is-parent preview">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account default" />
                </span>
                {useFormatMessage('UserForm.userPreview')}
              </p>
            </header>
            <div className="card-content">

              {isEditing && (
                <div className="field">
                  <label className="label">{useFormatMessage('Users.id')}</label>
                  <div className="control is-clearfix">
                    <input
                      data-testid="id"
                      type="text"
                      readOnly="readOnly"
                      className="input is-static"
                      value={watch('id')}
                    />
                  </div>
                </div>
              )}
              {!isEditing && (
                <div className="field">
                  <label className="label">{emailMessage}</label>
                  <div className="control is-clearfix">
                    <input
                      data-testid="email"
                      type="text"
                      readOnly="readOnly"
                      className="input is-static"
                      value={watch('email')}
                    />
                  </div>
                </div>
              )}

              <div className="field">
                <label className="label">
                  {useFormatMessage('UserForm.name')}
                </label>
                <div className="control is-clearfix">
                  <input
                    data-testid="name"
                    type="text"
                    readOnly="readOnly"
                    className="input is-static"
                    value={watch('name')}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">
                  {useFormatMessage('UserForm.location')}
                </label>
                <div className="control is-clearfix">
                  <input
                    data-testid="location"
                    type="text"
                    readOnly="readOnly"
                    className="input is-static"
                    value={watch('location')}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">
                  {useFormatMessage('Companies.info')}
                </label>
                <div className="control is-clearfix">
                  <input
                    data-testid="company"
                    type="text"
                    readOnly="readOnly"
                    className="input is-static"
                    value={companyName?.[0]?.name}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">
                  {useFormatMessage('Authorities.info')}
                </label>
                <div className="control is-clearfix">
                  <input
                    data-testid="company"
                    type="text"
                    readOnly="readOnly"
                    className="input is-static"
                    value={authorityName?.[0]?.name}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">
                  {useFormatMessage('UserForm.created')}
                </label>
                <div className="control is-clearfix" data-testid="date">
                  <p className="date">
                    {useFormatDate(watch('createdAt'), {
                      weekday: 'short',
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    })}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// UserForm.propTypes = {
//   user: PropTypes.shape({
//     id: PropTypes.string,
//     isAdmin: PropTypes.bool,
//     name: PropTypes.string.isRequired,
//     companyId: PropTypes.string,
//     password: PropTypes.string.isRequired,
//     location: PropTypes.string,
//     role: PropTypes.oneOf(['admin', 'shopping_window', 'authority']),
//     createdAt: PropTypes.string.isRequired,
//     email: PropTypes.string.isRequired,
//   }).isRequired,
//   onSubmitHandler: PropTypes.func.isRequired,
//   // eslint-disable-next-line react/forbid-prop-types
//   schema: PropTypes.object.isRequired,
//   isEditing: PropTypes.bool,
//   isProfile: PropTypes.bool,
// };

// UserForm.defaultProps = {
//   isEditing: false,
//   isProfile: false,
// };

export default UserForm;
