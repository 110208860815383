import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PropTypes from 'prop-types';
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-US';
import { shallowEqual, useSelector } from 'react-redux';

import './DatePicker.scss';

registerLocale('en', en);
registerLocale('de', de);

export const dateFormat = (locale) => {
  switch (locale) {
    case 'en':
      return 'MM-dd-yyyy';
    case 'de':
      return 'dd/MM/yyyy';
    default:
      return 'MM-dd-yyyy';
  }
};

const DatePickerStyled = ({
  date,
  startDate,
  endDate,
  onChange,
  selectsRange = false,
  ...rest
}) => {
  const onDateChangedHandler = (dates) => {
    if (selectsRange) {
      const [start, end] = dates;
      onChange({
        start: start ? start.toDateString() : null,
        end: end ? end.toDateString() : null,
      });
      return;
    }
    onChange(dates ? dates.toDateString() : new Date().toDateString());
  };

  const { locale } = useSelector(
    (state) => ({
      locale: state.preferences.locale,
    }),
    shallowEqual
  );

  return (
    <DatePicker
      locale={locale}
      dateFormat={dateFormat(locale)}
      selected={date || startDate}
      startDate={startDate}
      endDate={endDate}
      onChange={onDateChangedHandler}
      selectsRange={selectsRange}
      {...rest}
    />
  );
};

DatePickerStyled.propTypes = {
  date: PropTypes.instanceOf(Date),
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  selectsRange: PropTypes.bool,
};

export default DatePickerStyled;
