import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useFormatMessage } from 'hooks';
import bulmaCalendar from 'bulma-calendar';
import { useSelector, useDispatch } from 'react-redux';
import { adjustShoppingWindowContract } from 'state/actions/companies';
import ConfirmationModal from 'components/ConfirmationModal';

const CompanySection = ({ companyId }) => {
  const dispatch = useDispatch();
  const attachRef = useRef();
  const [modelData, setModelData] = useState({
    validFrom: null,
    validTo: null,
    isOpen: false,
  });
  const { company, loading, success } = useSelector((state) => ({
    company: state.companies.data.filter((comp) => comp.id === companyId).pop(),
    loading: state.companies.loading,
    success: state.companies.success,
  }));
  if (!company) {
    return null;
  }
  let companyInfo = `${useFormatMessage('Companies.name')}: `;
  if (company?.name) {
    companyInfo += company?.name;
  }
  if (company?.displayName) {
    companyInfo += `, ${useFormatMessage('Companies.displayName')}: ${
      company?.displayName
    }`;
  }

  const updateMessage = useFormatMessage('ShoppingWindow.update');
  const adjustContract = useFormatMessage('ShoppingWindow.adjustContract');
  const confirmMessage = useFormatMessage('Categories.confirm');
  const cancelMessage = useFormatMessage('Categories.cancel');

  const onCancel = useCallback(() => {
    setModelData((prev) => ({ ...prev, isOpen: false }));
    // attachRef.current.datePicker.startDate = company.validFrom ? new Date(company.validFrom) : null;
    // attachRef.current.datePicker.endDate = company.validTo ? new Date(company.validTo) : null;
    // attachRef.current.datePicker.refresh();
    // attachRef.current.value({
    //   startDate: company.validFrom ? new Date(company.validFrom) : null,
    //   endDate: company.validTo ? new Date(company.validTo) : null
    // });
    // attachRef.current.hide();
  }, [company]);

  useEffect(() => {
    if (!loading && success) {
      onCancel();
    }
  }, [success, loading]);

  const handleAdjustContract = (result) => {
    setModelData({
      validFrom: result.startDate,
      validTo: result.endDate,
      isOpen: true,
    });
  };

  useEffect(() => {
    if (!attachRef.current) {
      const [calendar] = bulmaCalendar.attach(`#contractValid`, {
        type: 'date',
        isRange: true,
        allowSameDayRange: true,
        displayMode: 'default',
        showTodayButton: true,
        labelFrom: 'From',
        labelTo: 'To',
        startDate: company.validFrom ? new Date(company.validFrom) : null,
        endDate: company.validTo ? new Date(company.validTo) : null,
      });
      attachRef.current = calendar;
    }
    const element = document.querySelector(`#contractValid`);
    if (element && element.bulmaCalendar) {
      element.bulmaCalendar.on('select', (datepicker) => {
        const [_startDate, _endDate] = datepicker.data
          .value()
          .split('-')
          .map((val) => new Date(val.trim()));
        handleAdjustContract({ startDate: _startDate, endDate: _endDate });
      });
      element.bulmaCalendar.on('clear', () => {
        handleAdjustContract({ startDate: undefined, endDate: undefined });
      });
    }
    return () => {
      if (element && element.bulmaCalendar) {
        element.bulmaCalendar.removeListeners('select');
        element.bulmaCalendar.removeListeners('clear');
      }
    };
  }, []);

  const updateCompanyContractValid = () => {
    dispatch(
      adjustShoppingWindowContract({
        id: companyId,
        validFrom: modelData?.validFrom,
        validTo: modelData?.validTo,
      })
    );
  };

  return (
    <>
      {modelData.isOpen && (
        <ConfirmationModal
          isActive={modelData.isOpen}
          isLoading={loading}
          title={confirmMessage}
          body={adjustContract}
          confirmButtonMessage={updateMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={updateCompanyContractValid}
          onCancel={onCancel}
        />
      )}
      <div className="columns">
        <div className="column is-two-fifths">
          <h3>{companyInfo}</h3>
          <div className="columns">
            <div className="column is-half-desktop is-one-tablet">
              <h3>{`${useFormatMessage('ShoppingWindow.contractValid')}`}</h3>
              <input id="contractValid" />
            </div>
            <div className="column" />
          </div>
        </div>
        <div className="column" />
      </div>
    </>
  );
};
export default CompanySection;
