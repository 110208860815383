import React, { useEffect, useMemo } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import * as yup from 'yup';

import CompanyForm from 'components/CompanyForm';
import {
  createCompany,
  modifyCompany,
  fetchCompanies,
} from 'state/actions/companies';
import { fetchProducts } from 'state/actions/products';
import paths from 'pages/Router/paths';
import { useFormatMessage } from 'hooks';

const schema = yup.object().shape({
  name: yup.string().required(),
  displayName: yup.string().required(),
  contact: yup.string().nullable(),
  email: yup.string().required(),
  address: yup.string().required(),
  products: yup.array(yup.string().required()).notRequired().default([]),
});

const Company = () => {
  const { id } = useParams();

  const isEditing = useMemo(() => !!id, [id]);

  const { success, products, loadingProduct, selectedCompany } = useSelector(
    (state) => ({
      success: state.companies.success,
      selectedCompany: state.companies.data.find(
        (company) => company.id === id
      ),
      loadingProduct: state.products.loading,
      products: state.products.data,
      error: state.companies.error,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditing && !selectedCompany) {
      dispatch(fetchCompanies(id));
    }
    dispatch(fetchProducts());
  }, [isEditing, id, selectedCompany, dispatch]);

  const redirect = success && <Redirect to={paths.COMPANIES} />;

  const editCompanyMessage = useFormatMessage('Companies.editCompany');

  const newCompanyMessage = useFormatMessage('Companies.newCompany');

  const onSubmitHandler = (value) => {
    const logoFile = value?.logoFile[0] || null;
    if (isEditing) {
      dispatch(
        modifyCompany({
          ...value,
          prevLogo: selectedCompany.logo,
          logoFile,
          prevProducts: Object.keys(selectedCompany.products || {}),
          isEditing,
          id,
        })
      );
    } else {
      dispatch(createCompany({ ...value, logoFile }));
    }
  };

  return (
    <>
      {redirect}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">
            {isEditing ? editCompanyMessage : newCompanyMessage}
          </h1>
        </div>
      </section>
      <section className="section is-main-section">
        {isEditing && !selectedCompany ? (
          <ClipLoader />
        ) : (
          <CompanyForm
            isEditing={isEditing}
            products={products}
            loadingProduct={loadingProduct}
            company={
              isEditing
                ? {
                    logoFile: '',
                    contact: '',
                    displayName: '',
                    ...selectedCompany,
                    products: Object.keys(selectedCompany.products || {}),
                    contractRanges: {
                      validFrom: selectedCompany.validFrom
                        ? new Date(selectedCompany.validFrom)
                        : null,
                      validTo: selectedCompany.validTo
                        ? new Date(selectedCompany.validTo)
                        : null,
                    },
                  }
                : {
                    name: '',
                    displayName: '',
                    email: '',
                    contract: '',
                    logoFile: '',
                    address: '',
                    products: [],
                    contractRanges: {
                      validFrom: null,
                      validTo: null,
                    },
                  }
            }
            onSubmitHandler={onSubmitHandler}
            schema={schema}
          />
        )}
      </section>
    </>
  );
};

export default Company;
