/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import firebase from 'firebase.js';
import ClipLoader from 'react-spinners/ClipLoader';
import { useFormatMessage } from 'hooks';
import ConfirmationModal from 'components/ConfirmationModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  deletePost,
  deleteImage,
  cancelDeleteImage,
} from 'state/actions/shoppingPosts';
import './PostForm.scss';

const PostForm = ({ post, loading: postLoading }) => {
  const [coverPhoto, setCoverPhoto] = useState();
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [deleteModal, setDeleteModal] = useState({
    postId: null,
    image: null,
    imageIndex: -1,
    isOpen: false,
  });
  const { deletedImage, deletingImage } = useSelector((state) => ({
    deleted: state.shopPosts.deleted,
    deletingImage: state.shopPosts.deletingImage,
    deletedImage: state.shopPosts.deletedImage,
  }));

  useEffect(() => {
    (async () => {
      const stasks = [
        firebase
          .storage()
          .ref(`shop_posts/${post.id}/cover/${post?.coverPhoto ?? ''}`)
          .getDownloadURL()
          .catch(() => {}),
      ];
      (post.images || []).forEach((img) => {
        if (typeof img === 'string' && img.length > 0) {
          stasks.push(
            firebase
              .storage()
              .ref(`shop_posts/${post.id}/images/${img}`)
              .getDownloadURL()
              .catch(() => {})
          );
        } else {
          stasks.push(undefined);
        }
      });
      const [cover, ...rest] = await Promise.all(stasks);
      if (cover) {
        setCoverPhoto(cover);
      }
      setImages(rest);
      setLoading(false);
    })();
  }, [post]);

  useEffect(() => {
    if (deletedImage) {
      setDeleteModal({
        postId: null,
        image: null,
        imageIndex: -1,
        isOpen: false,
      });
    }
  }, [deletedImage, loading]);

  const goBackMessage = useFormatMessage('Companies.goBack');
  const confirmMessage = useFormatMessage('Categories.confirm');
  const deleteMessage = useFormatMessage('Categories.delete');
  const cancelMessage = useFormatMessage('Categories.cancel');

  const onRemoveButtonClickHandler = (postId) => {
    setDeleteModal((prevState) => ({
      ...prevState,
      postId,
      isOpen: !prevState.isOpen,
    }));
  };

  const onRemoveImageButtonClickHandler = (postId, image, imageIndex = -1) => {
    setDeleteModal({
      postId,
      image,
      imageIndex,
      isOpen: true,
    });
  };

  const onCloseModalHandler = () => {
    setDeleteModal({
      postId: null,
      image: null,
      imageIndex: -1,
      isOpen: false,
    });
    if (deletingImage) {
      dispatch(cancelDeleteImage());
    }
  };

  const onDeleteHandler = () => {
    if (deleteModal.image) {
      dispatch(
        deleteImage(
          deleteModal.postId,
          deleteModal.image,
          deleteModal.imageIndex
        )
      );
    } else {
      dispatch(deletePost(deleteModal.postId));
    }
  };

  /* eslint-disable */
  return (
    <>
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={postLoading || deletingImage}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={useFormatMessage(
            `ShoppingWindow.${postLoading ? 'permDelete' : 'permDeleteImagbe'}`
          )}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <div>
        <div className="tile is-ancestor">
          <div className="tile is-parent">
            <div className="card tile is-child">
              <header className="card-header">
                <Link
                  to={`/shoppingWindow/${post.companyId}`}
                  className="button is-white"
                >
                  {goBackMessage}
                </Link>
              </header>
              <div className="card-content">
                {loading && <ClipLoader />}
                {!loading && (
                  <>
                    <div className="columns">
                      <div className="column is-one-fifth">
                        <figure className="image is-3by2">
                          <img
                            src={coverPhoto ?? ''}
                            style={{ backgroundColor: 'gray' }}
                            alt="Cover Image"
                          />
                          <div
                            style={{ position: 'absolute', top: 10, right: 10 }}
                          >
                            <button
                              className="button is-danger is-light"
                              onClick={() =>
                                onRemoveImageButtonClickHandler(
                                  post.id,
                                  post.coverPhoto,
                                  -1
                                )
                              }
                            >
                              {deletedImage ? (
                                <ClipLoader />
                              ) : (
                                useFormatMessage('Users.delete')
                              )}
                            </button>
                          </div>
                        </figure>
                      </div>
                      <div className="column auto">
                        <h3 className="title">{post.title}</h3>
                        <p className="subtitle">
                          {useFormatMessage('ShoppingWindow.validPeriod')}:{' '}
                          {moment(post.startDate).format('DD/MM/YYYY')} -{' '}
                          {moment(post.startDate)
                            .add(post.period, 'days')
                            .format('DD/MM/YYYY')}
                        </p>
                        <p>{post?.description || ''}</p>
                        <div className="mt-4">
                          <Link
                            to={`/shoppingWindow/${post.companyId}`}
                            className="button is-primary mr-3"
                          >
                            {useFormatMessage('Companies.goBack')}
                          </Link>
                          <button
                            className="button is-danger"
                            onClick={() => onRemoveButtonClickHandler(post.id)}
                          >
                            {useFormatMessage('Users.delete')}
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {!loading && (
                  <>
                    <hr />
                    <p className="title">
                      {useFormatMessage('ShoppingWindow.images')}
                    </p>
                    <div className="columns">
                      {images.map((img, index) => (
                        <div key={img} className="column is-narrow">
                          <figure
                            className="image is-square"
                            style={{ width: 200 }}
                          >
                            <img
                              src={img ?? ''}
                              style={{ backgroundColor: 'gray' }}
                            />
                            {typeof img === 'string' && (
                              <div
                                style={{
                                  position: 'absolute',
                                  top: 10,
                                  right: 10,
                                }}
                              >
                                <button
                                  className="button is-danger is-light"
                                  onClick={() =>
                                    onRemoveImageButtonClickHandler(
                                      post.id,
                                      post.images[index],
                                      index
                                    )
                                  }
                                >
                                  {deletedImage ? (
                                    <ClipLoader />
                                  ) : (
                                    useFormatMessage('Users.delete')
                                  )}
                                </button>
                              </div>
                            )}
                          </figure>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

PostForm.propTypes = {
  post: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    corverPhoto: PropTypes.string.isRequired,
    startDate: PropTypes.instanceOf(Date).isRequired,
    images: PropTypes.arrayOf(PropTypes.string.isRequired),
  }).isRequired,
};

export default PostForm;
