import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useFormatMessage } from 'hooks';
import * as yup from 'yup';

const schema = yup.object().shape({
  title: yup.string().min(10).required(),
  body: yup.string().required(),
  topic: yup.string().required(),
  image: yup.string().url(),
  articleId: yup.string().required(),
  performAtDate: yup.string().required(),
  performAtTime: yup.string().required(),
});
export function CampainModal({isLoading, onSubmit, onCancel, onDelete, formData}) {
  const editable = useMemo(() => formData.status !== 'completed', [formData.status]);
  const [form, setForm] = useState(formData);
  const modifiers = 'is-active';
  const loadingModifier = isLoading && 'is-loading';
  const isEdit = typeof formData.id === 'string';
  const [error, setError] = useState();

  const handleSubmitForm = useCallback(async () => {
    try {
      await schema.validate(form);
      onSubmit(form);
    } catch (e) {
      const msg = e instanceof Error ? e.message : `${e}`;
      setError(msg);
    }
  }, [form]);

  return (
    <div className={`modal ${modifiers}`}>
      <div
        className="modal-background"
        onClick={!isLoading ? onCancel : undefined}
      />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title is-size-4">
            {useFormatMessage("Messaging.form.title")}
          </p>
          {isEdit && editable && (
            <button
              type="button"
              className="button is-danger"
              onClick={() => onDelete(formData.id)}
              disabled={isLoading}
            >
              {useFormatMessage("Messaging.delete")}
            </button>
          )}
        </header>
        <section className="modal-card-body">
          {isEdit && !editable && (
            <p className="has-background-success p-2 field has-text-success-light">
              {useFormatMessage("Messaging.form.alreadySent")}
            </p>
          )}
          {typeof error === 'string' && (
            <p className="has-background-danger p-2 field has-text-danger-light">
              {useFormatMessage("Messaging.form.error")}
            </p>
          )}
          <p htmlFor="section" className="label">
            {useFormatMessage("Messaging.form.fields.topic")} <span className="has-text-danger">*</span>
          </p>
          <div className="field select">
            <select
              name="topic"
              disabled={!editable}
              value={form.topic}
              onChange={t => setForm(prev => ({...prev, topic: t.target.value}))}
            >
              <option value="news">News Highlights</option>
              <option value="agenda">Event Highlights</option>
            </select>
          </div>
          <div className="field">
            <label htmlFor="title" className="label">
              {useFormatMessage("Messaging.form.fields.title")} <span className="has-text-danger">*</span>
              <input
                className='input'
                value={form.title}
                onChange={t => setForm((prev) => ({...prev, title: t.target.value}))}
                name="title"
                required
                readOnly={!editable} />
            </label>
          </div>
          <div className="field">
            <label htmlFor="body" className="label">
              {useFormatMessage("Messaging.form.fields.body")} <span className="has-text-danger">*</span>
              <textarea
                className='textarea'
                value={form.body}
                onChange={t => setForm((prev) => ({...prev, body: t.target.value}))}
                name="body"
                rows={2}
                required
                readOnly={!editable}
              />
            </label>
          </div>
          <div className="field">
            <label htmlFor="image" className="label">
              {useFormatMessage("Messaging.form.fields.image")}
              <input
                className='input'
                name="image"
                value={form.image}
                onChange={t => setForm((prev) => ({...prev, image: t.target.value}))}
                placeholder="https://foo.bar.pizza-monster.png"
                readOnly={!editable}
              />
            </label>
          </div>
          <div className="field">
            <label htmlFor="performAt" className="label">
              {useFormatMessage("Messaging.form.fields.performAt")} <span className="has-text-danger">*</span>
                <div key="performAtDate" className="field">
                  <input
                    className='input'
                    name="performAtDate"
                    type="date"
                    min={moment().format('YYYY-MM-DD')}
                    value={form.performAtDate}
                    onChange={t => setForm((prev) => ({...prev, performAtDate: t.target.value}))}
                    readOnly={!editable}
                  />
                </div>
                <div key="performAtTime" className="field">
                  <input
                    className='input'
                    name="performAtTime"
                    type="time"
                    min={moment().format('HH:mm')}
                    value={form.performAtTime}
                    onChange={t => setForm((prev) => ({...prev, performAtTime: t.target.value}))}
                    readOnly={!editable}
                  />
                </div>
            </label>
          </div>
          <div className="field">
            <label htmlFor="articleId" className="label">
              {useFormatMessage("Messaging.form.fields.articleId")} <span className="has-text-danger">*</span>
              <p className="is-size-7 field">https://murten.unsereregion.ch/kultur/<span className="is-size-5">5347</span>-das-licht-festival-murten-2024-ist-geschichte-eine-grossartige.html</p>
              <input
                className='input'
                name="articleId"
                value={form.articleId}
                onChange={t => setForm((prev) => ({...prev, articleId: t.target.value}))}
                placeholder="5347"
                readOnly={!editable}
              />
            </label>
            <p className="has-text-warning-dark field is-size-7">
              {useFormatMessage("Messaging.form.fields.articleId.notice")}
            </p>
          </div>
        </section>
        <footer className="modal-card-foot">
          <button
            type="button"
            className={`button is-primary ${loadingModifier}`}
            onClick={handleSubmitForm}
            disabled={isLoading || !editable}
          >
            {useFormatMessage("Messaging.form.btn.submit")}
          </button>
          <button
            type="button"
            className="button"
            onClick={onCancel}
            disabled={isLoading}
          >
            {useFormatMessage("Messaging.form.btn.cancel")}
          </button>
        </footer>
      </div>
    </div>
  );
}

CampainModal.propTypes = {
  formData: PropTypes.shape({
    id: PropTypes.string,
    topic: PropTypes.oneOf(['news', 'agenda']).isRequired,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    image: PropTypes.string,
    performAtDate: PropTypes.string.isRequired,
    performAtTime: PropTypes.string.isRequired,
    articleId: PropTypes.string.isRequired,
  }),
  isLoading: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
};
